import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OrderWiseAccordin from "./OrderWiseAccordin";
import { useSelector } from "react-redux";
import { handleGetAllOrdersBySeller } from "../../axios";

const OrderWiseAccountSummary = () => {
  const [data, setData] = useState([]);
  const { token, user } = useSelector((state) => state.auth);
  const [Loading, setLoading] = useState(false);

  const getAllOrders = async () => {
    setLoading(true);
    try {
      const res = await handleGetAllOrdersBySeller(user?._id);

      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);
  return (
    <div style={{ marginTop: "80px" }}>
      <Box>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
        >
          Order Wise Account Summary
        </Typography>
        <Typography
          sx={{ border: "2px solid #0099cc", width: "60px", margin: "auto" }}
        ></Typography>
      </Box>
      <Box sx={{ m: 3 }}>
        <Button variant="contained" disableElevation>
          Export to Excel
        </Button>
      </Box>
      {!Loading ? (
        <Grid container spacing={2} sx={{ px: 3 }}>
          {data.length > 0 ? (
            <Grid item xs={12} lg={12}>
              <OrderWiseAccordin data={data} />
            </Grid>
          ) : (
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <Typography
                  sx={{
                    border: "1px solid #ddd",
                    minWidth: "600px",
                    p: 2,
                    textAlign: "center",
                    background: "#eee",
                    fontWeight: "bold",
                  }}
                >
                  No Result Found
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default OrderWiseAccountSummary;
