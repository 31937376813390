import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { SellerDocumentsUpdate } from "../axios";
const SellerDocuments = ({ theme, isMediumScreen }) => {
  const navigate = useNavigate();
  const [companyTradLicense, setCompanyTradLicense] = useState(null);
  const [VATCertificate, setVATCertificate] = useState(null);
  // const [BrandAuthorization, setBrandAuthorization] = useState(null);
  // const [companyTradLicenseData, setCompanyTradLicenseData] = useState(null);
  // const [VATCertificateData, setVATCertificateData] = useState(null);
  // const [BrandAuthorizationData, setBrandAuthorizationData] = useState(null);
  const [companyTradeLicenseError, setCompanyTradeLicenseError] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [vatCertificateError, setVatCertificateError] = useState("");
  const [previewImage1, setPreviewImage1] = useState("");

  const [loading, setLoading] = useState(false);

  const id = localStorage.getItem("sellerId");

  const handleSubmit = async (event) => {
    setLoading(true);
    try {
      if (!companyTradLicense || !VATCertificate) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: "Please Upload All the Documents",
          showConfirmButton: true,
        });
        return;
      }
      const companyTradLicense1 = new FormData();
      companyTradLicense1.append("file", companyTradLicense);
      companyTradLicense1.append("upload_preset", "insta-clone");
      companyTradLicense1.append("cloud_name", "bunny1705");

      const logoRes = await fetch(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        {
          method: "POST",
          body: companyTradLicense1,
        }
      );

      const companyTradLicenseJson = await logoRes.json();
      const companyTradLicenseUrl = companyTradLicenseJson.url;

      const VATCertificate1 = new FormData();
      VATCertificate1.append("file", VATCertificate);
      VATCertificate1.append("upload_preset", "insta-clone");
      VATCertificate1.append("cloud_name", "bunny1705");

      const logoResVATCertificate = await fetch(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        {
          method: "POST",
          body: VATCertificate1,
        }
      );

      const VATCertificateJson = await logoResVATCertificate.json();
      const VATCertificateURL = VATCertificateJson.url;

      const res = await SellerDocumentsUpdate(
        id,
        companyTradLicenseUrl,
        VATCertificateURL,
        true
      );
      if (res.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res.data.message,
          showConfirmButton: true,
        });
        navigate("/addBrand", { replace: true });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCompanyTradeLicenseChange = (e) => {
    const file = e.target.files[0];

    // Validate the file type
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
    ];
    if (file && allowedTypes.includes(file.type)) {
      setCompanyTradLicense(file);
      setCompanyTradeLicenseError("");
      setPreviewImage(URL.createObjectURL(file));
    } else {
      setCompanyTradLicense(null);
      setCompanyTradeLicenseError(
        "Invalid file type. Only PDF, JPEG, and PNG files are allowed."
      );
      setPreviewImage("");
    }
  };

  const handleVatCertificateChange = (e) => {
    const file = e.target.files[0];

    // Validate the file type
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
    ];
    if (file && allowedTypes.includes(file.type)) {
      setVATCertificate(file);
      setVatCertificateError("");
      setPreviewImage1(URL.createObjectURL(file));
    } else {
      setVATCertificate(null);
      setVatCertificateError(
        "Invalid file type. Only PDF, JPEG, and PNG files are allowed."
      );
      setPreviewImage1("");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: !isMediumScreen ? "90vh" : "100vh",
          px: 2,
        }}
      >
        <Grid item xs={12} lg={4.5} xl={4.5}>
          <div>
            <Card
              sx={{ p: 2, border: "1px solid #ddd", mt: 10, mb: 3 }}
              elevation={0}
            >
              <Box sx={{ mb: 3 }}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Seller Documents
                </Typography>
                <Typography
                  sx={{
                    border: "2px solid #0099cc",
                    width: "60px",
                    margin: "auto",
                  }}
                ></Typography>
              </Box>
              <Box>
                <TextField
                  type="file"
                  label="Company Trade License Certificate"
                  fullWidth
                  inputProps={{ accept: ".pdf, .jpeg, .png, .jpg" }}
                  onChange={handleCompanyTradeLicenseChange}
                  sx={{ mt: 0.5, mb: 3 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {companyTradeLicenseError && (
                  <Typography color="error">
                    {companyTradeLicenseError}
                  </Typography>
                )}
                {previewImage && (
                  <Box sx={{ m: 1 }}>
                    <img
                      src={previewImage}
                      alt="Preview"
                      style={{ maxWidth: "100%", maxHeight: 100 }}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <TextField
                  type="file"
                  label="VAT Certificate"
                  fullWidth
                  inputProps={{ accept: ".pdf, .jpeg, .png, .jpg" }}
                  onChange={handleVatCertificateChange}
                  sx={{ mt: 0.5, mb: 3 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {vatCertificateError && (
                  <Typography color="error">{vatCertificateError}</Typography>
                )}
                {previewImage1 && (
                  <Box sx={{ m: 1 }}>
                    <img
                      src={previewImage1}
                      alt="Preview"
                      style={{ maxWidth: "100%", maxHeight: 100 }}
                    />
                  </Box>
                )}
              </Box>

              <Typography sx={{ fontSize: "13px", mb: 1, color: "red" }}>
                Note : Please Upload .pdf, .jpeg, or .png files
              </Typography>
              <Button
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                sx={{ mt: 1.5 }}
                disabled={loading}
                disableElevation
              >
                {loading ? <CircularProgress size={24} /> : "SUBMIT"}
              </Button>
            </Card>
          </div>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default SellerDocuments;
