import { Box, Typography } from "@mui/material";
import React from "react";

const HomePageStatic = () => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
          Welcome to SkillShop Seller HomePage
        </Typography>
      </Box>
    </div>
  );
};

export default HomePageStatic;
