import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  ManageProfileAddBrand,
  getSellerById,
  handleGetAllBrand,
  handleGetAllCategories,
} from "../../axios";
import { useState } from "react";
import { useEffect } from "react";
import AssociatedBrands from "./ManageAssciatedBrands";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const ManageBrandTab = ({ data1, Loading }) => {
  const [data, setData] = useState([]);
  const [brandName, SetbrandName] = useState();
  const [brandNameError, setBrandNameError] = useState();
  const [BrandDocument, setBrandDocument] = useState(null);
  const [brandCertificate, setbrandCertificate] = useState("");
  const [brandCertificateError, setBrandCertificateError] = useState("");
  const [chooseCategory, setselectCategories] = useState([]);
  const [chooseCategoryError, setChooseCategoryError] = useState("");
  const [associations, setassociations] = useState([]);
  const [brandDocumentError, setBrandDocumentError] = useState("");
  const [SellerData, setSellerData] = useState({});
  const [Loading1, setLoading1] = useState(false);
  const [Loading2, setLoading2] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const { user, token } = useSelector((state) => state.auth);
  const handleAllAttributes = async () => {
    setLoading1(true);
    try {
      const res = await handleGetAllBrand();
      setData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  const handleGetAllCategories1 = async () => {
    setLoading2(true);
    try {
      const res = await handleGetAllCategories();
      if (res) {
        setassociations(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading2(false);
    }
  };

  const handleOptionChange = (e) => {
    const selectedBrandName = e.target.value;

    SetbrandName(selectedBrandName);

    // Validate the selected brand name
    if (selectedBrandName) {
      setBrandNameError("");
    } else {
      setBrandNameError("Please select a brand");
    }
  };

  const handleBrandChange = (event, newValue) => {
    if (newValue) {
      SetbrandName(newValue.name);
      handleOptionChange({ target: { value: newValue.name } });
    } else {
      SetbrandName("");
    }
  };

  const handleCategories = (e) => {
    const selectedCategories = e.target.value;
    setselectCategories(selectedCategories);

    // Validate the selected categories
    if (selectedCategories.length > 0) {
      setChooseCategoryError("");
    } else {
      setChooseCategoryError("Please select at least one category");
    }
  };

  const sortedData = React.useMemo(() => {
    return [...data].sort((a, b) => a.name.localeCompare(b.name));
  }, [data]);

  const handleDocumentType = (e) => {
    const selectedCertificate = e.target.value;
    setbrandCertificate(selectedCertificate);

    // Validate the selected certificate
    if (selectedCertificate) {
      setBrandCertificateError("");
    } else {
      setBrandCertificateError("Please select a document type");
    }
  };

  const handleBrandDocumentChange = (e) => {
    const file = e.target.files[0];
    const maxSize = 1 * 1024 * 1024;

    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (
        (extension === "pdf" ||
          extension === "png" ||
          extension === "jpeg" ||
          extension === "jpg") &&
        file.size <= maxSize
      ) {
        setBrandDocument(file);
        setBrandDocumentError("");
        setPreviewImage(URL.createObjectURL(file));
      } else {
        setBrandDocumentError("Invalid file or exceeds size limit");
        setBrandDocument(null);
        setPreviewImage("");
      }
    } else {
      setBrandDocument(null);
      setPreviewImage("");
      setBrandDocumentError("Brand Dcoument is required");
    }
  };

  const handleAddBrand = async () => {
    try {
      if (
        !brandName ||
        !brandCertificate ||
        !BrandDocument ||
        !chooseCategory
      ) {
        setBrandNameError("Brand Name is Required");
        setBrandCertificateError("Brand certificate is required");
        setBrandDocumentError("Brand document is required");
        setChooseCategoryError("category is required");
        return;
      }
      const formData = new FormData();

      formData.append("BrandName", brandName);
      formData.append("BrandCertificate", brandCertificate);
      formData.append("BrandDocument", BrandDocument);
      formData.append("chooseCategory", [chooseCategory]);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await ManageProfileAddBrand(user._id, formData, config);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Brand created successfully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "Brand creation failed",
        showConfirmButton: true,
      });
    }
  };

  useEffect(() => {
    handleAllAttributes();
    handleGetAllCategories1();
  }, []);
  return (
    <>
      {!Loading && !Loading1 && !Loading2 ? (
        <div>
          <Box sx={{ border: "1px solid #ddd", mb: 5 }}>
            <Box sx={{ p: 2 }}>
              <Typography sx={{ fontWeight: "bold" }}>Add A Brand</Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Brand:
                </Typography>
                <Box>
                  <Autocomplete
                    value={
                      data.find((option) => option.name === brandName) || null
                    }
                    onChange={handleBrandChange}
                    options={sortedData}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Brand"
                        variant="outlined"
                        sx={{ width: "350px" }}
                        // error={brandNameError}
                        // helperText={brandNameError}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Document Type:
                </Typography>
                <Box>
                  <FormControl fullWidth error={brandCertificateError}>
                    <InputLabel>Select Brand Document Type</InputLabel>
                    <Select
                      variant="outlined"
                      select
                      value={brandCertificate}
                      onChange={handleDocumentType}
                      sx={{ width: "350px" }}
                      input={
                        <OutlinedInput label="Select Brand Document Type" />
                      }
                    >
                      <MenuItem value="InvoiceCopy">Invoice Copy</MenuItem>
                      <MenuItem value="CertificateCopy">
                        Certificate Copy
                      </MenuItem>
                    </Select>
                    <FormHelperText>{brandCertificateError}</FormHelperText>
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Brand Document:
                </Typography>
                <Box>
                  <TextField
                    type="file"
                    placeholder="Pincode"
                    sx={{ width: "350px" }}
                    label={
                      brandCertificate
                        ? `Upload ${brandCertificate} Document`
                        : "Upload Brand Document"
                    }
                    inputProps={{ accept: ".pdf, .jpeg, .png, .jpg" }}
                    onChange={handleBrandDocumentChange}
                    error={brandDocumentError}
                    helperText={brandDocumentError}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // value={data.POBox}
                    size="medium"
                  />

                  {previewImage && (
                    <Box sx={{ m: 1 }}>
                      <img
                        src={previewImage}
                        alt="Preview"
                        style={{ maxWidth: "100%", maxHeight: 100 }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Category:
                </Typography>
                <Box>
                  <FormControl
                    sx={{ width: "100%" }}
                    error={chooseCategoryError}
                  >
                    <Select
                      multiple
                      displayEmpty
                      value={chooseCategory}
                      onChange={handleCategories}
                      input={<OutlinedInput />}
                      sx={{ width: "350px" }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Categories</em>;
                        }

                        return selected.join(", ");
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em>Select Categories</em>
                      </MenuItem>
                      {associations.map((item) => {
                        if (item.brand === brandName) {
                          return item?.categories?.map((item1) => (
                            <MenuItem key={item1} value={item1}>
                              <Checkbox
                                checked={chooseCategory.indexOf(item1) > -1}
                              />
                              <ListItemText primary={item1} />
                            </MenuItem>
                          ));
                        }
                        return null;
                      })}
                    </Select>
                    <FormHelperText>{chooseCategoryError}</FormHelperText>
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                ></Typography>
                <Box>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#0099cc", width: "200px" }}
                    disableElevation
                    onClick={handleAddBrand}
                  >
                    SUBMIT
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bolder",
                textAlign: "center",
                mt: 3,
              }}
            >
              Associated Brands
            </Typography>
            <Typography
              style={{
                margin: "auto",
                border: "2px solid #0099cc",
                width: "60px",
              }}
            ></Typography>
          </Box>
          <Box sx={{ mt: 3 }}>
            <AssociatedBrands SellerData={data1} />
          </Box>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "60vh",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ManageBrandTab;
