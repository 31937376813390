import { Box, Card, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  getLogoAndDetails,
  getLogoAndDetailsById,
  getMarketPlaceProfile,
  getMarketPlaceProfileById,
  getSellerById,
} from "../axios";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import ReactDOMServer from "react-dom/server";

const ThankYou = () => {
  return (
    <div>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Card
          sx={{ maxWidth: "450px", p: 3 }}
          style={{
            border: "1px solid #dddddd",
          }}
          elevation={0}
        >
          <Typography sx={{ textAlign: "center", fontSize: "20px" }}>
            Thank you for registering.
          </Typography>
          <Typography sx={{ textAlign: "center", fontSize: "16px" }}>
            If you entered a valid email check your email to verify
          </Typography>
        </Card>
      </Box>
    </div>
  );
};

export default ThankYou;
