import { Box, Typography } from "@mui/material";
import React from "react";
import ProductTabs from "./ProductTabs";

const AddSingleListing = () => {
  return (
    <div style={{ marginTop: "80px" }}>
      <Box>
        <Typography
          sx={{
            fontSize: "23px",
            fontWeight: "bold",
            textAlign: "center",
            mt: 3,
          }}
        >
          Add Single Listing
        </Typography>
        <Typography
          style={{
            border: "2px solid #4792C6",
            width: "60px",
            margin: "auto",
            marginTop: "5px",
          }}
        ></Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <ProductTabs />
      </Box>
    </div>
  );
};

export default AddSingleListing;
