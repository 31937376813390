import React, { useState } from "react";
import PendingOrderTable from "./PendingOrderTable";
import { handleGetAllOrdersBySeller } from "../../axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const PendingOrders = () => {
  const [data, setData] = useState([]);
  const { token, user } = useSelector((state) => state.auth);
  const [Loading, setLoading] = useState(false);

  const getAllOrders = async () => {
    setLoading(true);
    try {
      const res = await handleGetAllOrdersBySeller(user?._id);

      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);
  return (
    <div style={{ marginTop: "80px" }}>
      <PendingOrderTable data={data} setData={setData} Loading={Loading} />
    </div>
  );
};

export default PendingOrders;
