import { useEffect } from "react";
import { Box, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import ManageProfileTab from "./ManageProfileTab";
import ManageBrandTab from "./ManageBrandTab";
import ChangePasswordTab from "./ChangePasswordTab";
import AcitveTab from "./AcitveTab";
import ManageHolidayTab from "./ManageHolidayTab";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ManageProfileTabs = ({ data, Loading, setRefresh, setData }) => {
  const isLargeScreen = useMediaQuery("(min-width: 1280px)");
  const isMediumScreen = useMediaQuery("(min-width: 960px)");
  const navigate = useNavigate();
  const location = useLocation();

  const storedValue = localStorage.getItem("selectedTab");
  const initialTabValue = storedValue ? parseInt(storedValue, 10) : 0;
  const [value, setValue] = useState(initialTabValue);

  useEffect(() => {
    localStorage.setItem("selectedTab", value.toString());
  }, [value]);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/manageSeller-profile") {
      setValue(0);
    }
    if (path === "/manageSeller-brand") {
      setValue(1);
    } else if (path === "/manageSeller-changepassword") {
      setValue(2);
    } else if (path === "/manageSeller-activeStore") {
      setValue(3);
    } else if (path === "/manageSeller-manageHoliday") {
      setValue(4);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate("/manageSeller-profile");
    } else if (newValue === 1) {
      navigate("/manageSeller-brand");
    } else if (newValue === 2) {
      navigate("/manageSeller-changepassword");
    } else if (newValue === 3) {
      navigate("/manageSeller-activeStore");
    } else if (newValue === 4) {
      navigate("/manageSeller-manageHoliday");
    }
  };

  return (
    <div>
      <Box sx={{ overflowX: isLargeScreen ? "hidden" : "auto" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant={isLargeScreen ? "fullWidth" : "scrollable"}
          scrollButtons={isMediumScreen ? "on" : "on"}
          textColor="#fff"
          TabIndicatorProps={{ style: { display: "none" } }}
          sx={{
            "& .MuiTab-root": {
              border: "1px solid #dddddd",
              marginLeft: "5px",
              whiteSpace: "nowrap",
              minWidth: isMediumScreen ? "120px" : "auto",
              backgroundColor: "#f1f1f1",
              "&.Mui-selected": {
                backgroundColor: "#262626",
                color: "white",
                border: "1px solid #dddddd",
              },
            },
            ml: 3,
            mr: 3,
          }}
        >
          <Tab label="MANAGE PROFILE" disableRipple />
          <Tab label="MANAGE BRAND" disableRipple />
          <Tab label="CHANGE PASSWORD" disableRipple />
          <Tab label="ACTIVE/INACTIVE STORE" disableRipple />
          <Tab label="MANAGE HOLIDAY" disableRipple />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ManageProfileTab data={data} Loading={Loading} setData={setData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ManageBrandTab data1={data} Loading={Loading} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ChangePasswordTab
          data={data}
          Loading={Loading}
          setRefresh={setRefresh}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AcitveTab data={data} Loading={Loading} setRefresh={setRefresh} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ManageHolidayTab />
      </TabPanel>
    </div>
  );
};

export default ManageProfileTabs;
