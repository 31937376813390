import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";

import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { getSingleProductById } from "../axios";

function UploadProductImages() {
  const UploadedIds = JSON.parse(localStorage.getItem("uploadedIds"));
  const [colorImagesNmes, setColorImagesNames] = useState({});

  const getSingleListingData = async () => {
    try {
      const dataPromises = UploadedIds.map(async (item) => {
        const res = await getSingleProductById(item);
        return res.data;
      });

      const allData = await Promise.all(dataPromises);
      console.log(allData);
      //

      const colorImageNames = UploadedIds.reduce((result, item, index) => {
        const data = allData[index];
        const skuCode = data.SKUCode;

        result[skuCode] = {};

        Object.entries(data.ColorImageNames).forEach(([color, imageNames]) => {
          if (!result[skuCode][color]) {
            result[skuCode][color] = [];
          }
          result[skuCode][color] = result[skuCode][color].concat(imageNames);
        });

        return result;
      }, {});

      console.log(colorImageNames);
      setColorImagesNames(colorImageNames);

      // Do something with the obtained colorImageNames object
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleListingData();
  }, []);

  console.log(colorImagesNmes);

  return (
    <>
      <Box sx={{ marginTop: "80px" }}>
        <Box>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
          >
            Upload Images
          </Typography>
          <Typography
            sx={{ border: "2px solid #0099cc", width: "60px", margin: "auto" }}
          ></Typography>
        </Box>
        {Object.entries(colorImagesNmes).map(([skuCode, colorImages]) => (
          <Box key={skuCode} sx={{ mt: 3, ml: 3 }}>
            <Typography>
              SKUCode: <span style={{ color: "#0099cc" }}>{skuCode}</span>
            </Typography>
            {Object.entries(colorImages).map(([color, imageNames]) => (
              <Box key={color} sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{ width: "300px" }}
                  style={{ backgroundColor: "#000" }}
                >
                  Upload images for {color}
                </Button>
                <Box>
                  <Box
                    sx={{
                      border: "1px solid #ddd",
                      mt: 3,
                      mr: 3,
                      p: 2,
                      height: "200px",
                    }}
                  ></Box>
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </>
  );
}

export default UploadProductImages;
