import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import PendingOrderDashboardTable from "./PendingOrderDashboardTable";

const PendingOrderListDashboard = ({ MyOrders }) => {
  const Pending = MyOrders.filter((item) => item.orderStatus === "pending");
  return (
    <div>
      <Grid container spacing={2} sx={{ px: 2 }}>
        <Grid item xs={12} lg={12} xl={12}>
          <Box>
            <Typography
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
            >
              Pending Order List
            </Typography>
            <Typography
              sx={{
                border: "2px solid #0099cc",
                width: "60px",
                margin: "auto",
              }}
            ></Typography>
          </Box>
          <Typography sx={{ mt: 3 }}>
            Total Pending Orders: {Pending.length}
          </Typography>
          <Box sx={{ mt: 1 }}>
            <PendingOrderDashboardTable Pending={Pending} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default PendingOrderListDashboard;
