import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import navigations from "./navigations";
import NavbarTop from "./NavbarTop";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import navigations1 from "./navigations1";
import { useNavigate } from "react-router-dom/dist";
import {
  QuestionByBuyer,
  getAllStaticPages,
  handleGetAllSellerNavigations,
} from "../axios";
import { useState } from "react";
import { useEffect } from "react";
import { ThemeProvider, useMediaQuery, useTheme } from "@mui/material";

export default function Navbar({ SellerNav }) {
  const [anchorEls, setAnchorEls] = React.useState(
    new Array(navigations.length).fill(null)
  );
  const [data, setData] = useState([]);
  const [Questions, setQuestions] = useState([]);
  const [activeItem, setActiveItem] = useState(0);

  const handleMenuOpen = (index, event) => {
    let newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleMenuClose = (index) => {
    let newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  const isMenuOpen = (index) => {
    return Boolean(anchorEls[index]);
  };

  const { token, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleGetAllStaticPages = async () => {
    try {
      const res = await getAllStaticPages();
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllQuestionByBuyer = async () => {
    try {
      const res = await QuestionByBuyer(user._id);
      if (res) {
        setQuestions(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    handleGetAllStaticPages();
  }, []);
  useEffect(() => {
    handleGetAllQuestionByBuyer();
  }, [data]);

  return (
    <ThemeProvider theme={theme}>
      {!isMediumScreen ? (
        <Box sx={{ position: "fixed", zIndex: 999, width: "100%" }}>
          <Box
            style={{
              background: "#fff",
              border: "1px solid #dddddd",
            }}
            elevation={0}
          >
            {token ? (
              <>
                <Toolbar>
                  <Button
                    sx={{ color: "black" }}
                    onClick={() => navigate("/default/home")}
                  >
                    Dashboard
                  </Button>
                  {navigations.map((navigation, index) => (
                    <>
                      <Button
                        sx={{ color: "black", ml: 1, position: "relative" }}
                        // onMouseEnter={handleMenuOpen}
                        // onMouseLeave={handleMenuClose}
                        onClick={(event) => handleMenuOpen(index, event)}
                        endIcon={
                          isMenuOpen(index) ? (
                            <ExpandLessIcon sx={{ ml: -1 }} />
                          ) : (
                            <ExpandMoreIcon sx={{ ml: -1 }} />
                          )
                        }
                      >
                        {navigation.name}
                        {navigation.name === "CUSTOMER'S QUESTIONS" && (
                          <Box
                            style={{
                              position: "absolute",
                              top: -5,
                              right: 10,
                              backgroundColor: "#ffe500",
                              color: "black",
                              borderRadius: "50%",
                              width: "20px",
                              height: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              {Questions?.length}
                            </Typography>
                          </Box>
                        )}
                      </Button>
                      <Menu
                        anchorEl={anchorEls[index]}
                        open={isMenuOpen(index)}
                        onClose={() => handleMenuClose(index)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        {navigation?.children?.length > 0 &&
                          navigation.children.map((item) => (
                            <MenuItem onClick={() => handleMenuClose(index)}>
                              <Link
                                to={item.to}
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.name}
                              </Link>
                            </MenuItem>
                          ))}
                      </Menu>
                    </>
                  ))}
                </Toolbar>
              </>
            ) : (
              <Toolbar
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <>
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                    to="/content/home"
                    onClick={() => setActiveItem(0)}
                  >
                    HOME
                  </Link>
                  {SellerNav.map((item, i) => {
                    if (item.NavigationFor === "Seller Header" && item.active) {
                      return (
                        <Link
                          style={{
                            color: "black",
                            textDecoration: "none",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                          to={`/content/${item.PageUrl}`}
                          onClick={() => setActiveItem(i + 1)}
                        >
                          {item.Link.toUpperCase()}
                        </Link>
                      );
                    }
                  })}
                  <Link
                    style={{
                      color:
                        activeItem === data.length + 1 ? "#0099cc" : "black",
                      textDecoration: "none",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                    to="/contact"
                    onClick={() => setActiveItem(data.length + 1)}
                  >
                    CONTACT US
                  </Link>
                  <Link
                    style={{
                      color:
                        activeItem === data.length + 2 ? "#0099cc" : "black",
                      textDecoration: "none",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                    to="/payout"
                    onClick={() => setActiveItem(data.length + 2)}
                  >
                    COMMISSION PAYOUT
                  </Link>
                </>
              </Toolbar>
            )}
          </Box>
        </Box>
      ) : (
        ""
      )}
    </ThemeProvider>
  );
}
