import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import ManageTabs from "./ManageTabs";

const ManageOrder = ({ setData, setRefresh }) => {
  const location = useLocation();

  //   const delivaryCharges = 79;

  const OrderData = location.state?.orderData;

  return (
    <div style={{ marginTop: "80px" }}>
      <Grid container spacing={2} sx={{ px: 3 }}>
        <Grid item xs={12} lg={6}>
          <Box
            sx={{
              border: "1px solid #ddd",
              position: "relative",
              p: 2,
              // ml: 3,
              mt: 2,
              borderRadius: "5px",
              height: "300px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                position: "absolute",
                top: "-17px",
                left: "60px",
                backgroundColor: "#000",
              }}
              size="small"
              disableElevation
            >
              Order Details
            </Button>
            <Box sx={{ mt: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  Order Id:
                </Typography>
                <Typography>{OrderData?.[0]?.orderId}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  Order Date:
                </Typography>
                <Typography>{OrderData?.[0]?.orderDate}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  Mode of Payment:
                </Typography>
                <Typography>{OrderData?.[0]?.orderPaymentMethod}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  Payment Status :
                </Typography>
                <Typography>{OrderData?.[0]?.orderPaymentStatus}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  Payment Date :
                </Typography>
                <Typography>{OrderData?.[0]?.orderPaymentDate}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            sx={{
              border: "1px solid #ddd",
              position: "relative",
              p: 2,
              mt: 2,
              borderRadius: "5px",
              height: "300px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                position: "absolute",
                top: "-17px",
                left: "60px",
                backgroundColor: "#000",
              }}
              size="small"
              disableElevation
            >
              User Details
            </Button>
            <Box sx={{ mt: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  FullName:
                </Typography>
                <Typography>{OrderData?.[0]?.fullName}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Box
            sx={{
              border: "1px solid #ddd",
              position: "relative",
              p: 2,

              mt: 3,
              borderRadius: "5px",
              height: "400px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                position: "absolute",
                top: "-17px",
                left: "60px",
                backgroundColor: "#000",
              }}
              size="small"
              disableElevation
            >
              Shipping Details
            </Button>
            <Box sx={{ mt: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  FullName:
                </Typography>
                <Typography>{OrderData?.[0]?.fullName}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  Address:
                </Typography>
                <Typography>{OrderData?.[0]?.orderDate}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  City:
                </Typography>
                <Typography>{OrderData?.[0]?.orderCity}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  State :
                </Typography>
                <Typography>{OrderData?.[0]?.orderState}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  Country :
                </Typography>
                <Typography>{OrderData?.[0]?.orderCountry}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  PinCode :
                </Typography>
                <Typography>{OrderData?.[0]?.orderPincode}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  Company Name :
                </Typography>
                <Typography>{OrderData?.[0]?.companyName}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography sx={{ color: "gray", minWidth: "200px" }}>
                  GSTIN :
                </Typography>
                <Typography>{OrderData?.[0]?.GSTIN}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ px: 3, mb: 2 }}>
          <TableContainer
            component={Paper}
            sx={{ width: "100%", mt: 2 }}
            elevation={0}
          >
            <Table sx={{ border: "1px solid #ddd" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                  >
                    Image
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                  >
                    Product Details
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                  >
                    OfferPrice/Unit(exc.TAX)
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                  >
                    Tax(%)
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                  >
                    Tax Amount/Unit
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    Total Price (INCL TAX)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {OrderData?.[0]?.orderItems.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                        <Box
                          sx={{
                            width: "80px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={row?.productId?.MainImage}
                            alt=""
                            width="100%"
                            height="100%"
                          />
                        </Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "gray",
                              display: "flex",
                              minWidth: "150px",
                              mt: 1,
                            }}
                          >
                            Name:
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            {row?.productId?.Title.slice(0, 20)}....
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "gray",
                              minWidth: "150px",
                              mt: 1,
                              display: "flex",
                            }}
                          >
                            SKU Code:
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            {row?.productId?.SkuCode.slice(0, 15)}...
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "gray",
                              minWidth: "150px",
                              mt: 1,
                              display: "flex",
                            }}
                          >
                            Brand:
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            {row?.productId?.BrandName}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "gray",
                              minWidth: "150px",
                              mt: 1,
                              display: "flex",
                            }}
                          >
                            Color:
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            {row?.selectedColor}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "gray",
                              minWidth: "150px",
                              mt: 1,
                              display: "flex",
                            }}
                          >
                            Size:
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            {row?.selectedSize}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        ₹{" "}
                        {row?.productId?.mrp?.[0]?.[
                          `${row.selectedColor}-${row.selectedSize}`
                        ]?.offerPrice -
                          row?.productId?.mrp?.[0]?.[
                            `${row.selectedColor}-${row.selectedSize}`
                          ]?.offerPrice *
                            (18 / 100)}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        18%
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        {(row?.productId?.mrp?.[0]?.[
                          `${row.selectedColor}-${row.selectedSize}`
                        ]?.offerPrice *
                          18) /
                          100}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        {row?.quantity}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ borderRight: "1px solid #ddd" }}
                      >
                        ₹{" "}
                        {parseFloat(
                          row?.productId?.mrp?.[0]?.[
                            `${row.selectedColor}-${row.selectedSize}`
                          ]?.offerPrice
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid xs={12} lg={12} sx={{ px: 3, mt: 2 }}>
          <ManageTabs
            OrderData={OrderData}
            setData={setData}
            setRefresh={setRefresh}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ManageOrder;
