import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { TbFileSettings } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { handleGetAllOrdersBySeller } from "../../axios";
import { useSelector } from "react-redux";

const columns = [
  { id: "code", label: "Order No", minWidth: 120, align: "center" },
  {
    id: "Order Type",
    label: "Order Type",
    minWidth: 60,
    align: "center",
  },
  {
    id: "size",
    label: "Buyer",
    minWidth: 100,
    align: "center",
  },
  {
    label: "Order Date (DD/MM/YYYY)",
    minWidth: 120,
    align: "center",
  },
  {
    label: "Order Confirm Date (MM/DD/YYYY)",
    minWidth: 80,
    align: "center",
  },
  {
    label: "Amount",
    minWidth: 60,
    align: "center",
  },
  {
    label: "Mode of Payment",
    minWidth: 80,
    align: "center",
  },
  {
    label: "Payment Status",
    minWidth: 80,
    align: "center",
  },
  {
    label: "Order Status",
    minWidth: 80,
    align: "center",
  },

  {
    label: "Action",
    minWidth: 60,
    align: "center",
  },
];

export default function SellerOrderListAll() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const { token, user } = useSelector((state) => state.auth);
  const [Loading, setLoading] = useState(false);

  const getAllOrders = async () => {
    setLoading(true);
    try {
      const res = await handleGetAllOrdersBySeller(user?._id);

      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleExportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.table_to_sheet(
      document.getElementById("order-table")
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
    XLSX.writeFile(workbook, "orders.xlsx");
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 3,
          mr: 3,
          mb: 3,
          marginTop: "80px",
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
          Seller Orders List
        </Typography>
        <Box>
          <Button variant="contained" onClick={handleExportToExcel}>
            Export to Excel
          </Button>
        </Box>
      </Box>
      <>
        {Loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {data.length > 0 ? (
              <Paper
                sx={{
                  width: "96.5%",
                  overflow: "hidden",
                  ml: 3,
                  border: "1px solid #ddd",
                  mb: 5,
                }}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              background: "#eee",
                              fontWeight: "bold",
                              borderRight: "1px solid #ddd",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              <TableCell
                                align="center"
                                sx={{ borderRight: "1px solid #ddd" }}
                              >
                                {row.orderId}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ borderRight: "1px solid #ddd" }}
                              >
                                ----
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ borderRight: "1px solid #ddd" }}
                              >
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",

                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        display: "flex",
                                        minWidth: "100px",
                                      }}
                                    >
                                      Name:
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {row?.fullName}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",

                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        display: "flex",
                                        minWidth: "100px",
                                      }}
                                    >
                                      Mobile:
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {row?.orderPhone}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",

                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        display: "flex",
                                        minWidth: "100px",
                                      }}
                                    >
                                      CompanyName:
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {row?.companyName}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",

                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        display: "flex",
                                        minWidth: "100px",
                                      }}
                                    >
                                      GSTIN:
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {row?.GSTIN}
                                    </Typography>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ borderRight: "1px solid #ddd" }}
                              >
                                {row.orderDate}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ borderRight: "1px solid #ddd" }}
                              >
                                {row.orderConfirmDate}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ borderRight: "1px solid #ddd" }}
                              >
                                ₹ {row.totalPrice}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ borderRight: "1px solid #ddd" }}
                              >
                                {row.orderPaymentMethod}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ borderRight: "1px solid #ddd" }}
                              >
                                {row.orderPaymentStatus === "Paid"
                                  ? "Paid"
                                  : "UnPaid"}
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ borderRight: "1px solid #ddd" }}
                              >
                                {row?.orderStatus}
                              </TableCell>

                              <TableCell align="center">
                                <Tooltip title="manage Order">
                                  <IconButton
                                    onClick={() =>
                                      navigate(`/manage-order/${row._id}`, {
                                        state: { orderData: [row] },
                                      })
                                    }
                                  >
                                    <TbFileSettings />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[100, 200, 500, 1000]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <Typography
                  sx={{
                    border: "1px solid #ddd",
                    minWidth: "600px",
                    p: 2,
                    textAlign: "center",
                    background: "#eee",
                    fontWeight: "bold",
                  }}
                >
                  No Result Found
                </Typography>
              </Box>
            )}
          </>
        )}
      </>
    </>
  );
}
