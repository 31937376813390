import {
  Autocomplete,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { CKEditor } from "ckeditor4-react";
import React, { useEffect } from "react";
import { useState } from "react";
import { getAllPolicy } from "../../axios";
import Swal from "sweetalert2";

const BasicInfo = ({
  checked,
  setChecked,
  productType,
  selectedId,
  setSelectedId,
  handleTitleChange,
  Title,
  handleChange,
  PreBook = "No",
  replacement = "No",
  refund = "No",
  Loading1,
}) => {
  const [newUniqueUrl, setUniqueUrl] = useState();
  const [mainImage, setMainimage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [returnPolcy, setRetrunPolicy] = useState([]);
  const [policyDescription, setPolicyDescription] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileName = file.name.toLowerCase();
      const fileSize = file.size / 1024 / 1024;
      const allowedExtensions = [".jpg", ".jpeg"];
      const maxSizeInMB = 1;

      // Validate file extension
      const fileExtension = fileName.substring(fileName.lastIndexOf("."));
      if (!allowedExtensions.includes(fileExtension)) {
        alert("Only .jpg and .jpeg files are allowed.");
        e.target.value = null;
        return;
      }

      // Validate file size
      if (fileSize > maxSizeInMB) {
        alert(`File size should be less than ${maxSizeInMB}MB.`);
        e.target.value = null;
        return;
      }

      setChecked({ ...checked, MainImage: file });

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const ITEM_HEIGHT = 68;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleEditorChange = (event, name) => {
    const parseddata = event.editor.getData();
    setChecked({ ...checked, name: parseddata });

    // console.log("Table:", formattedHtml);
  };

  useEffect(() => {
    if (Title) {
      const urlTitle = Title.replace(/\s+/g, "-");
      const uniqueId = Math.floor(Math.random() * 1000000)
        .toString()
        .padStart(6, "0");
      const newUniqueUrl = `${urlTitle}-${uniqueId}`;
      setChecked({ ...checked, UniqueUrl: newUniqueUrl });
    }
  }, [Title]);

  const handleGetAllPolicies = async () => {
    try {
      const res = await getAllPolicy();
      if (res) {
        setRetrunPolicy(res);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGetAllPolicies();
  }, []);

  useEffect(() => {
    returnPolcy.map((item) => {
      if (item.Heading === checked.returnPolicy) {
        setPolicyDescription(item.Description);
      }
    });
  }, [checked.returnPolicy, returnPolcy]);

  const options = [
    "SkillShop will pickUp and ship(SkillShop Fullfillment)",
    "I will ship items myself(self Fullfillment)",
  ];

  return (
    <Box>
      <Card sx={{ p: 2, border: "1px solid #dddddd" }} elevation={0}>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              Product Type :{" "}
            </Typography>
            <Autocomplete
              sx={{ width: 350 }}
              value={checked.ProductType}
              onChange={(e, newValue) => {
                const newData = productType.find(
                  (item) =>
                    item.ProductType === newValue && item.active === true
                );
                if (newData) {
                  setSelectedId(newData._id);
                  setChecked({ ...checked, ProductType: newValue });
                }
              }}
              options={productType
                .filter((item) => item.active === true)
                .map((item) => item.ProductType)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Product Type"
                  InputLabelProps={{
                    sx: { marginLeft: 0 },
                  }}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mt: 2,
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              PreBook :{" "}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={PreBook}
                  control={<Radio />}
                  checked={PreBook == "Yes"}
                  onChange={(e) => setChecked({ ...checked, PreBook: "Yes" })}
                  label="Yes"
                />
                <FormControlLabel
                  value={PreBook}
                  control={<Radio />}
                  checked={PreBook == "No"}
                  onChange={(e) => setChecked({ ...checked, PreBook: "No" })}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              Product Title :{" "}
            </Typography>
            <TextField
              placeholder="Product Title"
              sx={{ width: "350px" }}
              value={Title}
              onChange={(e) => {
                setChecked({
                  ...checked,
                  Title: e.target.value,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              HSN Code :{" "}
            </Typography>
            <TextField
              placeholder="Enter HSN Code"
              sx={{ width: "350px" }}
              value={checked.HSNCode}
              onChange={(e) =>
                setChecked({ ...checked, HSNCode: e.target.value })
              }
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              Tax(%) :{" "}
            </Typography>
            <TextField
              placeholder="Enter Tax (%)"
              sx={{ width: "350px" }}
              value={checked.Tax}
              onChange={(e) => setChecked({ ...checked, Tax: e.target.value })}
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              Description :{" "}
            </Typography>
            <TextField
              multiline
              rows={4}
              placeholder="Description"
              value={checked.Description}
              onChange={(e) =>
                setChecked({ ...checked, Description: e.target.value })
              }
              sx={{ width: "350px" }}
              size="medium"
            />
            {/* <CKEditor onChange={(e) => handleEditorChange(e, Description)} /> */}
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              SKU Code :{" "}
            </Typography>
            <TextField
              multiline
              placeholder="Enter SKU Code"
              value={checked.SkuCode}
              onChange={(e) =>
                setChecked({ ...checked, SkuCode: e.target.value })
              }
              sx={{ width: "350px" }}
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              Unique Url :{" "}
            </Typography>
            <TextField
              multiline
              placeholder="Unique URL"
              value={checked.UniqueUrl}
              sx={{ width: "350px" }}
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Main Image <br />
              Image Dimension should be 40X75
              <br /> Image Size Should be less then 1 MB
              <br />{" "}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                type="file"
                placeholder="Unique URL"
                sx={{ width: "350px" }}
                inputProps={{ accept: " .jpeg .jpg" }}
                onChange={handleFileChange}
              />
              {previewImage && (
                <Box sx={{ height: "100px", width: "100px", m: 1 }}>
                  <img
                    src={previewImage}
                    alt="Preview"
                    style={{ width: "100%" }}
                  />
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Product Details : <br />
              Upload only PDF file <br /> PDF Size Should be less then 1 MB
            </Typography>
            <TextField
              type="file"
              placeholder="Product Details"
              sx={{ width: "350px" }}
              inputProps={{ accept: "application/pdf" }}
              onChange={(e) => {
                const file = e.target.files[0];
                const maxSize = 1 * 1024 * 1024; // 1MB

                if (file) {
                  const extension = file.name.split(".").pop().toLowerCase();
                  if (extension === "pdf" && file.size <= maxSize) {
                    setChecked({
                      ...checked,
                      PrdoductDetailsDoc: file,
                    });
                  } else {
                    Swal.fire({
                      position: "center",
                      icon: "error",
                      text: "Invalid file or exceeds size limit",
                      showConfirmButton: true,
                    });
                  }
                } else {
                  return null;
                }
              }}
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Search Title :{" "}
            </Typography>
            <TextField
              placeholder="Search Title"
              value={checked.searchTitle}
              onChange={(e) =>
                setChecked({ ...checked, searchTitle: e.target.value })
              }
              sx={{ width: "350px" }}
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Search Keywords <br />
              (Separated By Comma) :{" "}
            </Typography>
            <TextField
              multiline
              placeholder="Search Keywords (Separated By Comma)"
              value={checked.searchKeywords}
              onChange={(e) => {
                const data = e.target.value.split(",");
                setChecked({ ...checked, searchKeywords: data });
              }}
              sx={{ width: "350px" }}
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Search Description :{" "}
            </Typography>
            <TextField
              multiline
              placeholder="Search Description"
              value={checked.searchDescription}
              onChange={(e) =>
                setChecked({
                  ...checked,
                  searchDescription: e.target.value,
                })
              }
              sx={{ width: "350px" }}
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Shipment Fulfilled by Vedson :{" "}
            </Typography>
            <Autocomplete
              displayEmpty
              value={checked.shipment}
              onChange={(event, newValue) => {
                setChecked({ ...checked, shipment: newValue });
              }}
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select"
                  multiline
                  sx={{ width: 350 }}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Return Policy:{" "}
            </Typography>
            <Autocomplete
              displayEmpty
              multiline
              value={checked.returnPolicy}
              onChange={(event, newValue) => {
                if (newValue) {
                  setChecked({ ...checked, returnPolicy: newValue });
                }
              }}
              options={returnPolcy.map((item) => item.Heading)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Return Policy"
                  InputLabelProps={{
                    sx: { marginLeft: 0 },
                  }}
                  multiline
                  sx={{ width: 350 }}
                />
              )}
            />
          </Box>
          {checked.returnPolicy == "" ? (
            ""
          ) : (
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  minWidth: "300px",
                }}
              >
                Return Policy Description{" "}
              </Typography>
              <Typography
                sx={{
                  width: "600px",
                }}
              >
                {policyDescription}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Exchange & Return conditions{" "}
            </Typography>
            <TextField
              multiline
              placeholder="Return Policy Description"
              sx={{ width: "350px" }}
              value={checked.returnPolicyDescription}
              onChange={(e) =>
                setChecked({
                  ...checked,
                  returnPolicyDescription: e.target.value,
                })
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mt: 2,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Replacement :{" "}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value={replacement}
                  control={<Radio />}
                  checked={replacement == "Yes"}
                  onChange={(e) =>
                    setChecked({ ...checked, replacement: "Yes" })
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value={replacement}
                  control={<Radio />}
                  checked={replacement == "No"}
                  onChange={(e) =>
                    setChecked({ ...checked, replacement: "No" })
                  }
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mt: 2,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Refund :{" "}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value={refund}
                  control={<Radio />}
                  checked={refund == "Yes"}
                  onChange={(e) => setChecked({ ...checked, refund: "Yes" })}
                  label="Yes"
                />
                <FormControlLabel
                  value={refund}
                  control={<Radio />}
                  checked={refund == "No"}
                  onChange={(e) => setChecked({ ...checked, refund: "No" })}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mt: 2,
              mb: 5,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            ></Typography>
            <Button
              variant="contained"
              onClick={() => handleChange(0, 3)}
              sx={{ width: "200px" }}
            >
              NEXT
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default BasicInfo;
