import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function AccordingTable({ row }) {
  let totalCredit = 0;
  let totalDebit = 0;
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ border: "1px solid #ddd" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: "#eee" }}>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                width: "100px",
                border: "1px solid #ddd",
                fontWeight: "bold",
              }}
            >
              Date(MM/DD/YYYY)
            </TableCell>
            <TableCell
              align="left"
              sx={{
                width: "800px",
                border: "1px solid #ddd",
                fontWeight: "bold",
              }}
            >
              Description
            </TableCell>
            <TableCell
              align="left"
              sx={{
                width: "100px",
                border: "1px solid #ddd",
                fontWeight: "bold",
              }}
            >
              Credit
            </TableCell>
            <TableCell
              align="left"
              sx={{
                width: "100px",
                border: "1px solid #ddd",
                fontWeight: "bold",
              }}
            >
              Debit
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row.orderItems.map((item, index) => {
            totalCredit +=
              item.productId.mrp?.[0]?.[
                `${item.selectedColor}-${item.selectedSize}`
              ]?.offerPrice -
              (item.productId.mrp?.[0]?.[
                `${item.selectedColor}-${item.selectedSize}`
              ]?.offerPrice *
                18) /
                100;
            return (
              <>
                <TableRow key={index}>
                  <TableCell>{row.orderPaymentDate}</TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    Taxable Amount Of Order <strong>{row.orderId}</strong>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    ₹{" "}
                    {item.productId.mrp?.[0]?.[
                      `${item.selectedColor}-${item.selectedSize}`
                    ]?.offerPrice -
                      (item.productId.mrp?.[0]?.[
                        `${item.selectedColor}-${item.selectedSize}`
                      ]?.offerPrice *
                        18) /
                        100}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    ₹ 0.00
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.orderPaymentDate}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    Order CGST <strong>{row.orderId}</strong>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    ₹{" "}
                    {(item.productId.mrp?.[0]?.[
                      `${item.selectedColor}-${item.selectedSize}`
                    ]?.offerPrice *
                      18) /
                      100}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    ₹ 0.00
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    {row.orderPaymentDate}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    Order SGST <strong>{row.orderId}</strong>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    ₹{" "}
                    {(item.productId.mrp?.[0]?.[
                      `${item.selectedColor}-${item.selectedSize}`
                    ]?.offerPrice *
                      18) /
                      100}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ddd" }}>
                    ₹ 0.00
                  </TableCell>
                </TableRow>
              </>
            );
          })}
          <TableRow>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                fontWeight: "bold",
                textAlign: "end",
              }}
              colSpan={2}
            >
              Sub Total:
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", fontWeight: "bold" }}>
              ₹ {totalCredit.toFixed(2)}
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", fontWeight: "bold" }}>
              ₹ {totalDebit.toFixed(2)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                fontWeight: "bold",
                textAlign: "end",
              }}
              colSpan={2}
            >
              Reserve Fund:
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", fontWeight: "bold" }}>
              ₹ 0.00
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", fontWeight: "bold" }}>
              ₹ 0.00
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                fontWeight: "bold",
                textAlign: "end",
              }}
              colSpan={2}
            >
              Net Payble To Seller:
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", fontWeight: "bold" }}>
              ₹ 0.00
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", fontWeight: "bold" }}>
              ₹ 0.00
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                fontWeight: "bold",
                textAlign: "end",
              }}
              colSpan={2}
            >
              Net Receivable From Seller:
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", fontWeight: "bold" }}>
              ₹ 0.00
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", fontWeight: "bold" }}>
              ₹ 0.00
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                border: "1px solid #ddd",
                fontWeight: "bold",
                textAlign: "end",
              }}
              colSpan={2}
            >
              Total:
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", fontWeight: "bold" }}>
              ₹ 0.00
            </TableCell>
            <TableCell sx={{ border: "1px solid #ddd", fontWeight: "bold" }}>
              ₹ 0.00
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
