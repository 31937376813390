import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { SellerRegister } from "../axios";

const Register = ({ theme, isMediumScreen }) => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setCPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [termCheckedError, setTermCheckedError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRegister = async () => {
    setLoading(true);
    try {
      if (!fullName) {
        setFullNameError(true);
      }
      if (!email) {
        setEmailError(true);
      }
      if (!password) {
        setPasswordError(true);
      }
      if (!confirmPassword) {
        setConfirmPasswordError(true);
      }
      if (!mobileNumber) {
        setMobileNumberError(true);
      }
      if (!termsChecked) {
        setTermCheckedError(true);
      }

      if (
        fullNameError ||
        emailError ||
        passwordError ||
        confirmPasswordError ||
        mobileNumberError
      ) {
        return; // Stop registration process if any field is empty
      }

      const res = await SellerRegister(
        fullName,
        email,
        password,
        confirmPassword,
        mobileNumber
      );

      console.log(res);

      if (res.status === 200) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("SellerUserId", res.data._id);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "You have been registered successfully. Please check your email and verify your Account",
          showConfirmButton: true,
        });
        navigate("/thank-you1", { replace: true });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setFullName(value);
    setFullNameError(value.trim() === "");
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(!validateEmail(value));
  };

  const handleTermsChange = (e) => {
    if (!e.target.checked) {
      setTermCheckedError(true);
    }
    setTermsChecked(e.target.checked);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordError(value.length < 8);
    if (confirmPassword !== "" && value !== confirmPassword) {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setCPassword(value);
    setConfirmPasswordError(value.length < 8 || value !== password);
  };

  const handleMobileNumberChange = (e) => {
    const inputValue = e.target.value;
    const numericRegex = /^[0-9]*$/; // Regular expression to match numeric characters

    // Check if the input value matches the numeric regex
    if (numericRegex.test(inputValue)) {
      setMobileNumber(inputValue);
      setMobileNumberError(false);
      if (inputValue.length !== 10) {
        setMobileNumberError(true);
      }
    } else {
      setMobileNumberError(true);
    }
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{ px: 2 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          minHeight: "100vh",
        }}
      >
        <Grid item xs={12} sm={4.5} md={4.5} lg={4.5} xl={4.5}>
          <Card
            sx={{ p: 2 }}
            style={{
              border: "1px solid #dddddd",
            }}
            elevation={0}
          >
            <Box sx={{ mb: 3 }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Register Here
              </Typography>
              <Typography
                sx={{
                  border: "2px solid #0099cc",
                  width: "60px",
                  margin: "auto",
                }}
              ></Typography>
            </Box>
            <Box>
              <TextField
                variant="outlined"
                type="text"
                label="FullName"
                placeholder="FullName"
                fullWidth
                value={fullName}
                onChange={handleFullNameChange}
                error={fullNameError}
                helperText={fullNameError ? "Please enter your full name" : ""}
                sx={{ mt: 1.5, mb: 1.5 }}
              />
              <TextField
                variant="outlined"
                type="email"
                label="Email Address"
                placeholder="Email Address"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={
                  emailError ? "Please enter a valid email address" : ""
                }
                sx={{ mb: 1.5 }}
              />
              <TextField
                variant="outlined"
                label="Password"
                type="password"
                placeholder="Password"
                fullWidth
                value={password}
                onChange={handlePasswordChange}
                error={passwordError}
                helperText={
                  passwordError
                    ? "Password should be at least 8 characters"
                    : ""
                }
                sx={{ mb: 1.5 }}
              />
              <TextField
                variant="outlined"
                label="Confirm Password"
                type="password"
                placeholder="Confirm Password"
                fullWidth
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={confirmPasswordError}
                helperText={
                  confirmPasswordError ? "Passwords do not match" : ""
                }
                sx={{ mb: 1.5 }}
              />
              <TextField
                variant="outlined"
                label="Mobile Number"
                placeholder="Mobile Number"
                fullWidth
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                error={mobileNumberError}
                helperText={
                  mobileNumberError
                    ? "Please enter a 10 digit valid mobile number"
                    : ""
                }
                sx={{ mb: 1.5 }}
                inputProps={{
                  maxLength: 10,
                }}
              />
            </Box>
            <Box
              style={{ display: "flex", alignItems: "center" }}
              sx={{ mb: 1.5 }}
            >
              <Checkbox
                checked={termsChecked}
                onChange={handleTermsChange}
                color="error" // Set the color of the checkbox to red
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  color: !termsChecked ? "inherit" : "inherit",
                  fontWeight: "bold",
                }}
              >
                I accept the{" "}
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => navigate("/content/terms-use")}
                >
                  Terms & Conditions
                </span>
                {!termsChecked && termCheckedError && (
                  <span style={{ color: "red", marginLeft: "5px" }}>
                    {" "}
                    (Please accept the terms and conditions)
                  </span>
                )}
              </Typography>
            </Box>

            <Button
              variant="contained"
              sx={{ color: "white" }}
              fullWidth
              disableElevation
              onClick={handleRegister}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "REGISTER"}
            </Button>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Register;
