import {
  Button,
  Card,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { SellerInformationUpdate, getSellerById } from "../axios";
import { useEffect } from "react";
import countries from "./CountryMaster.json";
import { postcodeValidator } from "postcode-validator";
import { ClassNames } from "@emotion/react";

const SellerInformation = () => {
  const { id } = useParams();
  const [personFullName, setpersonFullName] = useState("");
  const [personTitle, setpersonTitle] = useState("");
  const [personMobileNumber, setpersonMobileNumber] = useState("");
  const [personEmailAddress, setpersonEmailAddress] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [companyDisplayName, setcompanyDisplayName] = useState("");
  const [companyAddress, setcompanyAddress] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const [POBox, setPOBox] = useState("");
  const [certificationAwards, setcertificationAwards] = useState("");
  const [tradeLicenseNumber, settradeLicenseNumber] = useState("");
  const [VAT, setVAT] = useState("");
  const [warehouseShippingPersonFullName, setwarehouseShippingPersonFullName] =
    useState("");
  const [warehouseAddress, setwarehouseAddress] = useState("");
  const [warehouselandmark, setwarehouselandmark] = useState("");
  const [warehouseCity, setwarehouseCity] = useState("");
  const [warehouseState, setwarehouseState] = useState("");
  const [wareshouseCountry, setwareshouseCountry] = useState("");
  const [warehousePersonTitle, setwarehousePersonTitle] = useState("");
  const [warehousePersonPhoneNumber, setwarehousePersonPhoneNumber] =
    useState("");
  const [warehousePersonEmail, setwarehousePersonEmail] = useState("");
  const [sellerBankAccountName, setsellerBankAccountName] = useState("");
  const [sellerAccountNumber, setsellerAccountNumber] = useState("");
  const [sellerBranch, setsellerBranch] = useState("");
  const [sellerIfscCode, setsellerIfscCode] = useState("");
  const [sellerIBAN, setsellerIBAN] = useState("");
  const [sellerSWIFTCode, setsellerSWIFTCode] = useState("");
  const [sellerBankName, setsellerBankName] = useState("");
  const [sellerFinanceContactPersonName, setsellerFinanceContactPersonName] =
    useState("");
  const [SellerFinanceContactEmailID, setSellerFinanceContactEmailID] =
    useState("");

  const [personTitleError, setpersonTitleError] = useState("");
  const [companyNameError, setcompanyNameError] = useState("");
  const [companyDisplayNameError, setcompanyDisplayNameError] = useState("");
  const [companyAddressError, setcompanyAddressError] = useState("");
  const [tradeLicesnseError, setTradeLicenseError] = useState("");
  const [VatError, setVatNumberError] = useState("");
  const [WareHousePersonNameError, setWareHousePersonNameError] = useState("");
  const [wareHouseAddressError, setwareHouseAddressError] = useState("");
  const [LandMarkError, setLandMarkError] = useState("");
  const [cityError, setcityError] = useState("");
  const [StateError, setStateError] = useState("");
  const [CountryError, setCountryError] = useState("");
  const [warehouseConatctpersonNameError, setwarehouseConatctpersonNameError] =
    useState("");
  const [warehouseConatctPhoneNumber, setwarehouseConatctPhoneNumber] =
    useState("");
  const [
    WarehouseconatctpersonEmialError,
    setWarehouseconatctpersonEmialError,
  ] = useState("");
  const [SellerAccountNAmeError, setSellerAccountNAmeError] = useState("");
  const [SellerAccountNoError, setSellerAccountNoError] = useState("");
  const [SellerIBANError, setSellerIBANError] = useState("");
  const [SellerSwiftCodeError, setSellerSwiftCodeError] = useState("");
  const [SellerBankNameError, setSellerBankNameError] = useState("");
  const [SellerFiancePersonNameError, setSellerFiancePersonNameError] =
    useState("");
  const [sellerFinancePersonEmailError, setsellerFinancePersonEmailError] =
    useState("");
  const [address, setAddress] = useState({});
  const [error, setError] = useState();
  const [BankBranchError, setBankBranchError] = useState("");
  const [BankIfscError, setBankIfscError] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [code, setcode] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);

  const [data, setData] = useState({});

  const navigate = useNavigate();
  localStorage.setItem("sellerId", id);

  const handleGetSellerById = async () => {
    setLoading(true);
    try {
      const res = await getSellerById(id);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSellerById();
  }, [id]);

  useEffect(() => {
    if (data.sellerInformationUpdated === true) {
      navigate("/sellerDocuments");
    }
    if (data.sellerDocumentsUpdated === true) {
      navigate("/addBrand");
    }
    if (data.sellerBrandDocumentsUpdated === true) {
      navigate("/");
    }
  }, [id, data]);

  const handleSellerInformation = async () => {
    setLoading1(true);
    try {
      if (!personTitle) {
        setpersonTitleError("Person Title is Required");
      }
      if (!companyName) {
        setcompanyNameError("Comapny Name is Required");
      }
      if (!companyDisplayName) {
        setcompanyDisplayNameError("Company Display Name is Required");
      }
      if (!companyAddress) {
        setcompanyAddressError("Company Address is Required");
      }
      if (!tradeLicenseNumber) {
        setTradeLicenseError("Trade License Number is Required");
      }
      if (!VAT) {
        setVatNumberError("VAT is Required");
      }
      if (!warehouseShippingPersonFullName) {
        setWareHousePersonNameError(
          "Warehouse Shipping Person Name is Required"
        );
      }
      if (!warehouseAddress) {
        setwareHouseAddressError("Warehouse Address is Required");
      }
      if (!warehouselandmark) {
        setLandMarkError("Warehouse LandMark is Required");
      }
      if (!warehouseCity) {
        setcityError("Warehouse City is Required");
      }
      if (!warehouseState) {
        setStateError("Warehouse State is Required");
      }
      if (!wareshouseCountry) {
        setCountryError("Warehouse Country is Required");
      }
      if (!warehousePersonTitle) {
        setwarehouseConatctpersonNameError(
          "Warehouse Person Title is Required"
        );
      }
      if (!warehousePersonPhoneNumber) {
        setwarehouseConatctPhoneNumber("Mobile Number is Required");
      }
      if (!warehousePersonEmail) {
        setWarehouseconatctpersonEmialError("Email is Required");
      }

      if (!sellerBankAccountName) {
        setSellerAccountNAmeError("Bank Account Name is Required");
      }
      if (!sellerAccountNumber) {
        setSellerAccountNoError("Account No is Required");
      }

      if (!sellerIBAN) {
        setSellerIBANError("IBAN is Required");
      }
      if (!sellerSWIFTCode) {
        setSellerSwiftCodeError("Swift Code is Required");
      }
      if (!sellerBankName) {
        setSellerBankNameError("Seller Bank Name is Required");
      }
      if (!sellerFinanceContactPersonName) {
        setSellerFiancePersonNameError(
          "Seller Finance Contact Person Name is Required"
        );
      }
      if (!sellerBranch) {
        setBankBranchError("Branch  is Required");
      }
      if (!sellerIfscCode) {
        setBankIfscError("IFSC Code  is Required");
      }
      if (!SellerFinanceContactEmailID) {
        setsellerFinancePersonEmailError(
          "Seller Finance Contact Person Email ID is Required"
        );
        if (!POBox) {
          setError("Pincode/Postcode/Zipcode is Required");
        }
        return;
      }
      const res = await SellerInformationUpdate(
        id,
        data.fullName,
        personTitle,
        data.mobileNumber,
        data.email,
        companyName,
        companyDisplayName,
        companyAddress,
        address.city,
        address.state,
        selectedCountry,
        POBox,
        certificationAwards,
        tradeLicenseNumber,
        VAT,
        warehouseShippingPersonFullName,
        warehouseAddress,
        warehouselandmark,
        warehouseCity,
        warehouseState,
        wareshouseCountry,
        warehousePersonTitle,
        warehousePersonPhoneNumber,
        warehousePersonEmail,
        sellerBankAccountName,
        sellerAccountNumber,
        sellerBranch,
        sellerIfscCode,
        sellerIBAN,
        sellerSWIFTCode,
        sellerBankName,
        sellerFinanceContactPersonName,
        SellerFinanceContactEmailID,
        true
      );
      if (res.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res.data,
          showConfirmButton: true,
          timer: 1500,
        });
        navigate("/sellerDocuments", { replace: true });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "All fields are required",
        showConfirmButton: true,
      });
    } finally {
      setLoading1(false);
    }
  };

  const handlePersonTitle = (e) => {
    if (e.target.value.length == 0) {
      setpersonTitleError("Contact Person Title is required");
    } else {
      setpersonTitleError("");
    }
    setpersonTitle(e.target.value);
  };
  const handleCompanyName = (e) => {
    if (e.target.value.length == 0) {
      setcompanyNameError("Company Name is required");
    } else {
      setcompanyNameError("");
    }
    setcompanyName(e.target.value);
  };
  const handleDisplayName = (e) => {
    if (e.target.value.length == 0) {
      setcompanyDisplayNameError("Company Display Name is required");
    } else {
      setcompanyDisplayNameError("");
    }
    setcompanyDisplayName(e.target.value);
  };
  const handleWarehousePersonName = (e) => {
    if (e.target.value.length == 0) {
      setWareHousePersonNameError("Warehouse Person Name is required");
    } else {
      setWareHousePersonNameError("");
    }
    setwarehouseShippingPersonFullName(e.target.value);
  };
  const handleWarehouseAddress = (e) => {
    if (e.target.value.length == 0) {
      setwareHouseAddressError("Warehouse Address is required");
    } else {
      setwareHouseAddressError("");
    }
    setwarehouseAddress(e.target.value);
  };
  const handleWarehouseLandMark = (e) => {
    if (e.target.value.length == 0) {
      setLandMarkError("Warehouse LandMark is required");
    } else {
      setLandMarkError("");
    }
    setwarehouselandmark(e.target.value);
  };
  const handleWarehouseCity = (e) => {
    if (e.target.value.length == 0) {
      setcityError("Warehouse City is required");
    } else {
      setcityError("");
    }
    setwarehouseCity(e.target.value);
  };
  const handleWarehouseState = (e) => {
    if (e.target.value.length == 0) {
      setStateError("Warehouse State is required");
    } else {
      setStateError("");
    }
    setwarehouseState(e.target.value);
  };
  const handleWarehouseCountry = (e) => {
    if (e.target.value.length == 0) {
      setCountryError("Warehouse Country is required");
    } else {
      setCountryError("");
    }
    setwareshouseCountry(e.target.value);
  };
  const handleBankIFSC = (e) => {
    if (e.target.value.length == 0) {
      setBankIfscError("IFSC Code is required");
    } else {
      setBankIfscError("");
    }
    setsellerIfscCode(e.target.value);
  };
  const handleBankBranch = (e) => {
    if (e.target.value.length == 0) {
      setBankBranchError("Branch is required");
    } else {
      setBankBranchError("");
    }
    setsellerBranch(e.target.value);
  };
  const handleWarehouseContactPersonName = (e) => {
    if (e.target.value.length == 0) {
      setwarehouseConatctpersonNameError(
        "Warehouse Contact Person Name is required"
      );
    } else {
      setwarehouseConatctpersonNameError("");
    }
    setwarehousePersonTitle(e.target.value);
  };
  const handleWarehouseContactphoneNumber = (e) => {
    const pattern = /^\d{10}$/; // Assuming the mobile number format is a 10-digit number
    if (pattern.test(e.target.value)) {
      setwarehouseConatctPhoneNumber("");
    } else if (e.target.length === 0) {
      setwarehouseConatctPhoneNumber(" Phone Number is required");
    } else {
      setwarehouseConatctPhoneNumber("Enter a valid 10 digit Phone Number");
    }
    setwarehousePersonPhoneNumber(e.target.value);
  };

  const handleWarehouseContactPersonEmail = (e) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression pattern for basic email validation
    if (pattern.test(e.target.value)) {
      setWarehouseconatctpersonEmialError("");
    } else if (e.target.value.length === 0) {
      setWarehouseconatctpersonEmialError("Email is Required");
    } else {
      setWarehouseconatctpersonEmialError("Invalid Email Address");
    }
    setwarehousePersonEmail(e.target.value);
  };
  const handleCompanyAddress = (e) => {
    if (e.target.value.length == 0) {
      setcompanyAddressError("Company Address is required");
    } else {
      setcompanyAddressError("");
    }
    setcompanyAddress(e.target.value);
  };

  const handleTradeLicenseChange = (e) => {
    const value = e.target.value;
    settradeLicenseNumber(value);

    // Validate the trade license number
    const pattern = /^\d{5}$/; // Assuming the trade license number is a 5-digit number
    if (pattern.test(value)) {
      setTradeLicenseError("");
    } else {
      setTradeLicenseError("Trade License Number should be a 5-digit number");
    }
  };

  const handleSellerAccountName = (e) => {
    if (e.target.value.length == 0) {
      setSellerAccountNAmeError("Account Name is required(Benificiary Name)");
    } else {
      setSellerAccountNAmeError("");
    }
    setsellerBankAccountName(e.target.value);
  };
  const handleSellerAccountNo = (e) => {
    if (e.target.value.length == 0) {
      setSellerAccountNoError("Account No is required");
    } else {
      setSellerAccountNoError("");
    }
    setsellerAccountNumber(e.target.value);
  };
  const handleSellerSwiftCode = (e) => {
    if (e.target.value.length == 0) {
      setSellerSwiftCodeError("Swift Code is required");
    } else {
      setSellerSwiftCodeError("");
    }
    setsellerSWIFTCode(e.target.value);
  };
  const handleSellerIBAN = (e) => {
    if (e.target.value.length == 0) {
      setSellerIBANError("IBAN is required");
    } else {
      setSellerIBANError("");
    }
    setsellerIBAN(e.target.value);
  };
  const handleSellerBankName = (e) => {
    if (e.target.value.length == 0) {
      setSellerBankNameError("Bank Name is required");
    } else {
      setSellerBankNameError("");
    }
    setsellerBankName(e.target.value);
  };
  const handleSellerFinancePersonName = (e) => {
    if (e.target.value.length == 0) {
      setSellerFiancePersonNameError("Finance Contact PersonName is required");
    } else {
      setSellerFiancePersonNameError("");
    }
    setsellerFinanceContactPersonName(e.target.value);
  };
  const handleSellerFinancePersonEmail = (e) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression pattern for basic email validation
    if (pattern.test(e.target.value)) {
      setsellerFinancePersonEmailError("");
    } else if (e.target.value.length === 0) {
      setsellerFinancePersonEmailError("Email is Required");
    } else {
      setsellerFinancePersonEmailError("Invalid Email Address");
    }
    setSellerFinanceContactEmailID(e.target.value);
  };

  const handleVatNumberChange = (e) => {
    const value = e.target.value;
    setVAT(value);

    // Validate the VAT number
    const pattern = /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{3}$/; // Assuming the VAT number format is XX99XX999
    if (pattern.test(value)) {
      setVatNumberError("");
    } else {
      setVatNumberError("Invalid VAT Number");
    }
  };

  const handleInputChange = (event) => {
    const postalCode = event.target.value;

    // If the country is India, validate PIN code
    if (selectedCountry === "India") {
      const isValidPincode = /^[1-9][0-9]{5}$/.test(postalCode);
      if (isValidPincode) {
        axios
          .get(`https://api.postalpincode.in/pincode/${postalCode}`)
          .then((response) => {
            const postOffice = response.data[0].PostOffice[0];
            const city = postOffice.Block;
            const state = postOffice.State;
            setAddress({ city, state });
            setError(null);
          })
          .catch((error) => {
            console.error(error);
            setAddress(null);
          });
        setPOBox(postalCode);
      } else {
        setAddress(null);
        setPOBox(postalCode);
        setError("Invalid PIN code");
      }
    } else {
      // For non-India countries, validate postal code using postcode-validator library
      const isValidPostalCode = postcodeValidator(postalCode, code);
      if (isValidPostalCode) {
        axios
          .get(
            `https://secure.geonames.org/postalCodeSearchJSON?postalcode=${postalCode}&country=${code}&maxRows=1&username=venkat_1705`
          )
          .then((response) => {
            const city = response.data.postalCodes[0].placeName;
            const state = response.data.postalCodes[0].adminName1;
            setAddress({ city, state });
            setError(null);
          })
          .catch((error) => {
            console.error(error);
            setAddress(null);
          });
        setPOBox(postalCode);
      } else {
        setAddress(null);
        setPOBox(postalCode);
        setError("Invalid postal/Zipcode code");
      }
    }
  };

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;

    setSelectedCountry(selectedCountry);

    // Determine the country code for the selected country
    const selectedCode = countries.find(
      (c) => c.name === selectedCountry
    )?.code;
    setcode(selectedCode);

    // Determine whether the current pin/zip code is valid for the selected country
    let isValid = false;
    if (selectedCountry === "India") {
      isValid = /^[1-9][0-9]{5}$/.test(POBox);
    } else {
      isValid = postcodeValidator(POBox, selectedCode);
    }

    // Update the error state based on whether the pin/zip code is valid
    if (isValid) {
      setError(null);
    } else {
      setError(
        `Invalid postal/zipcode code for ${selectedCountry}. Please enter a valid code.`
      );
    }
  };

  return (
    <Grid
      container
      sx={{
        px: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item xs={12} lg={4.5} xl={4.5}>
        <>
          {!Loading ? (
            <div style={{ marginTop: "80px" }}>
              <Card
                sx={{ maxWidth: "450px", p: 2 }}
                style={{
                  margin: "auto",
                  border: "1px solid #ddd",
                  marginTop: "30px",
                }}
                elevation={0}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  Profile Requester Information
                </Typography>

                <Box>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Contact Person Full Name"
                    placeholder="Contact Person Full Name"
                    fullWidth
                    value={data.fullName}
                    InputProps={{
                      readOnly: true,
                    }}
                    onChange={(e) => setpersonFullName(data.fullName)}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Contact Person Title"
                    placeholder="Contact Person Title"
                    fullWidth
                    value={personTitle}
                    onChange={handlePersonTitle}
                    error={personTitleError}
                    helperText={personTitleError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Contact Person Phone Number"
                    placeholder="Contact Person Phone Number"
                    fullWidth
                    value={data.mobileNumber}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Contact Person Email Address"
                    placeholder="Contact Person Email Address"
                    fullWidth
                    value={data.email}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                </Box>
              </Card>
              <Card
                sx={{ maxWidth: "450px", p: 2 }}
                style={{
                  margin: "auto",
                  marginTop: "30px",
                  border: "1px solid #ddd",
                }}
                elevation={0}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  Company Information
                </Typography>

                <Box>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Company Name (as in Trade License) "
                    placeholder="Company Name (as in Trade License) "
                    fullWidth
                    value={companyName}
                    onChange={handleCompanyName}
                    error={companyNameError}
                    helperText={companyNameError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Company Display Name "
                    placeholder="Company Display Name "
                    fullWidth
                    value={companyDisplayName}
                    onChange={handleDisplayName}
                    error={companyDisplayNameError}
                    helperText={companyDisplayNameError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Company Address"
                    placeholder="Company Address"
                    fullWidth
                    value={companyAddress}
                    onChange={handleCompanyAddress}
                    error={companyAddressError}
                    helperText={companyAddressError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />

                  <Select
                    sx={{ mt: 1.5, mb: 1.5, width: "100%" }}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    error={error}
                    helperText={error}
                  >
                    {countries.map((item) => (
                      <MenuItem value={item.name}>{item.name}</MenuItem>
                    ))}
                  </Select>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Pincode/Postcode/Zipcode"
                    placeholder="Pincode/Postcode/Zipcode"
                    fullWidth
                    value={POBox}
                    onChange={handleInputChange}
                    error={error}
                    helperText={error}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="City"
                    placeholder="City"
                    fullWidth
                    value={address?.city ?? ""}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="State/County"
                    placeholder="State/County"
                    fullWidth
                    value={address?.state ?? ""}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />

                  <TextField
                    variant="outlined"
                    type="text"
                    label="Certifications or Awards "
                    placeholder="Certifications or Awards "
                    fullWidth
                    value={certificationAwards}
                    onChange={(e) => setcertificationAwards(e.target.value)}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Trade License Number "
                    placeholder="Trade License Number "
                    fullWidth
                    value={tradeLicenseNumber}
                    onChange={handleTradeLicenseChange}
                    error={tradeLicesnseError}
                    helperText={tradeLicesnseError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="VAT(TRN) "
                    placeholder="ex : XX99XX999"
                    fullWidth
                    value={VAT}
                    onChange={handleVatNumberChange}
                    error={VatError}
                    helperText={VatError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                </Box>
              </Card>
              <Card
                sx={{ maxWidth: "450px", p: 2 }}
                style={{
                  margin: "auto",
                  marginTop: "30px",
                  border: "1px solid #ddd",
                }}
                elevation={0}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  Warehouse (Seller’s Shipping) Information
                </Typography>

                <Box>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Contact Person Full Name "
                    placeholder="Contact Person Full Name "
                    fullWidth
                    value={warehouseShippingPersonFullName}
                    onChange={handleWarehousePersonName}
                    error={WareHousePersonNameError}
                    helperText={WareHousePersonNameError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Warehouse Address "
                    placeholder="Warehouse Address "
                    fullWidth
                    value={warehouseAddress}
                    onChange={handleWarehouseAddress}
                    error={wareHouseAddressError}
                    helperText={wareHouseAddressError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Landmark"
                    placeholder="Landmark"
                    fullWidth
                    value={warehouselandmark}
                    onChange={handleWarehouseLandMark}
                    error={LandMarkError}
                    helperText={LandMarkError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />

                  <TextField
                    variant="outlined"
                    type="text"
                    label="City"
                    placeholder="City"
                    fullWidth
                    value={warehouseCity}
                    onChange={handleWarehouseCity}
                    error={cityError}
                    helperText={cityError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="State"
                    placeholder="State"
                    fullWidth
                    value={warehouseState}
                    onChange={handleWarehouseState}
                    error={StateError}
                    helperText={StateError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Country"
                    placeholder="Country"
                    fullWidth
                    value={wareshouseCountry}
                    onChange={handleWarehouseCountry}
                    error={CountryError}
                    helperText={CountryError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Contact Person Title"
                    placeholder="Contact Person Title"
                    fullWidth
                    value={warehousePersonTitle}
                    onChange={handleWarehouseContactPersonName}
                    error={warehouseConatctpersonNameError}
                    helperText={warehouseConatctpersonNameError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Contact Person Phone Number "
                    placeholder="Contact Person Phone Number "
                    fullWidth
                    value={warehousePersonPhoneNumber}
                    onChange={handleWarehouseContactphoneNumber}
                    error={warehouseConatctPhoneNumber}
                    helperText={warehouseConatctPhoneNumber}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Contact Person Email Address  "
                    placeholder="Contact Person Email Address  "
                    fullWidth
                    value={warehousePersonEmail}
                    onChange={handleWarehouseContactPersonEmail}
                    error={WarehouseconatctpersonEmialError}
                    helperText={WarehouseconatctpersonEmialError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                </Box>
              </Card>
              <Card
                sx={{ maxWidth: "450px", p: 2 }}
                style={{
                  margin: "auto",
                  marginTop: "30px",
                  border: "1px solid #ddd",
                }}
                elevation={0}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  Bank Account (Seller’s) Details
                </Typography>

                <Box>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Bank Account Name"
                    placeholder="Bank Account Name"
                    fullWidth
                    value={sellerBankAccountName}
                    onChange={handleSellerAccountName}
                    error={SellerAccountNAmeError}
                    helperText={SellerAccountNAmeError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Bank Account Number "
                    placeholder="Bank Account Number "
                    fullWidth
                    value={sellerAccountNumber}
                    onChange={handleSellerAccountNo}
                    error={SellerAccountNoError}
                    helperText={SellerAccountNoError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Bank Branch  "
                    placeholder="Bank Branch  "
                    fullWidth
                    value={sellerBranch}
                    onChange={handleBankBranch}
                    error={BankBranchError}
                    helperText={BankBranchError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="IFSC Code"
                    placeholder="IFSC Code"
                    fullWidth
                    value={sellerIfscCode}
                    onChange={handleBankIFSC}
                    error={BankIfscError}
                    helperText={BankIfscError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="IBAN"
                    placeholder="IBAN"
                    fullWidth
                    value={sellerIBAN}
                    onChange={handleSellerIBAN}
                    error={SellerIBANError}
                    helperText={SellerIBANError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />

                  <TextField
                    variant="outlined"
                    type="text"
                    label="SWIFT Code (BIC)"
                    placeholder="SWIFT Code (BIC)"
                    fullWidth
                    value={sellerSWIFTCode}
                    onChange={handleSellerSwiftCode}
                    error={SellerSwiftCodeError}
                    helperText={SellerSwiftCodeError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Bank Name"
                    placeholder="Bank Name"
                    fullWidth
                    value={sellerBankName}
                    onChange={handleSellerBankName}
                    error={SellerBankNameError}
                    helperText={SellerBankNameError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Finance Contact Person Name "
                    placeholder="Finance Contact Person Name "
                    fullWidth
                    value={sellerFinanceContactPersonName}
                    onChange={handleSellerFinancePersonName}
                    error={SellerFiancePersonNameError}
                    helperText={SellerFiancePersonNameError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Finance Contact Email ID(for Notifications)"
                    placeholder="Finance Contact Email ID(for Notifications)"
                    fullWidth
                    value={SellerFinanceContactEmailID}
                    onChange={handleSellerFinancePersonEmail}
                    error={sellerFinancePersonEmailError}
                    helperText={sellerFinancePersonEmailError}
                    sx={{ mt: 1.5, mb: 1.5 }}
                  />
                </Box>
              </Card>

              <Box
                style={{ display: "flex", justifyContent: "center" }}
                sx={{ mt: 2, mb: 3 }}
              >
                <Button
                  variant="contained"
                  sx={{ width: "480px" }}
                  disableElevation
                  onClick={handleSellerInformation}
                  disabled={Loading1}
                >
                  {Loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
                </Button>
              </Box>
            </div>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      </Grid>
    </Grid>
  );
};

export default SellerInformation;
