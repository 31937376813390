import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";

const DashboardPieChart1 = ({ AllProducts, MyOrders }) => {
  const activeProducts = AllProducts.filter((item) => item.active === true);
  const activeProductTitles = activeProducts.map(
    (activeItem) => activeItem.Title
  );

  const BestSellingProducts = MyOrders.filter((order) =>
    order.orderItems.some((item) =>
      activeProductTitles.includes(item?.productId?.Title)
    )
  );

  const chartData1 = {
    series: activeProducts.map((item) =>
      item.productViewCount.length > 0 ? item.productViewCount.length : 0
    ),
    options: {
      chart: {
        width: "100%", // Increase the width
        height: "100%",
        type: "pie",
      },
      labels: activeProducts.map((item) => item.Title.slice(0, 20) + `....`),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 320,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const bestSellingItemCount = activeProducts.map((activeItem) => {
    const count = MyOrders.reduce((total, order) => {
      return (
        total +
        order.orderItems.filter(
          (item) => item?.productId?.Title === activeItem.Title
        ).length
      );
    }, 0);
    return count;
  });

  const chartData = {
    series: bestSellingItemCount,
    options: {
      chart: {
        width: "100%", // Increase the width
        height: "100%",
        type: "pie",
      },
      labels: activeProductTitles,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 320,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const filteredProducts = AllProducts.filter((item) => item.active === true);
  return (
    <div>
      <Grid container spacing={2} sx={{ px: 2 }} height={700}>
        <Grid item xs={12} lg={6} md={12} xl={6}>
          <Box
            sx={{
              border: "1px solid #ddd",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography sx={{ fontSize: "24px", p: 2, fontWeight: "bold" }}>
              Best Selling Items
            </Typography>
            {bestSellingItemCount > 0 ? (
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="pie"
                width={700}
              />
            ) : (
              <Typography>No Data Found</Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} md={12} xl={6} alignItems="center">
          <Box
            sx={{
              border: "1px solid #ddd",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                p: 2,
                fontWeight: "bold",
              }}
            >
              Most Viewed Items
            </Typography>
            {activeProducts.length > 0 ? (
              <Chart
                options={chartData1.options}
                series={chartData1.series}
                type="pie"
                width={700}
              />
            ) : (
              <Typography>No Data Found</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardPieChart1;
