import {
  Card,
  TextField,
  Typography,
  MenuItem,
  Button,
  Select,
  OutlinedInput,
  InputLabel,
  CircularProgress,
  FormControl,
  Checkbox,
  ListItemText,
  Autocomplete,
  Grid,
  Divider,
  FormHelperText,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  SellerAddBrandUpdate,
  getAllBrands,
  getSellerById,
  handleGetAllCategories,
  handleSubmitBrandData,
} from "../axios";
import SellerBrandTable from "./SellerBrandTable";

const Brands = [
  { value: "Select Brand", label: "Select Brand" },
  { value: "Samsung", label: "Samsung" },
  { value: "Apple", label: "Apple" },
  { value: "Sony", label: "Sony" },
];
const DocumentTypes = [
  { value: "InvoiceCopy", label: "Invoice Copy" },
  { value: "CertificateCopy", label: "Certificate Copy" },
];
const categories = [
  { value: "Select Categories", label: "Select Categories" },
  { value: "Samsung", label: "Samsung" },
  { value: "Apple", label: "Apple" },
  { value: "Sony", label: "Sony" },
];

const SellerAddBrand = () => {
  const navigate = useNavigate();
  const [brandName, SetbrandName] = React.useState();
  const [chooseCategory, setselectCategories] = React.useState([]);
  const [brandCertificate, setbrandCertificate] = useState("");
  const [BrandDocument, setBrandDocument] = useState(null);
  const [associations, setassociations] = React.useState([]);
  const [data, setData] = useState([]);
  const [brandNameError, setBrandNameError] = useState("");
  const [brandCertificateError, setBrandCertificateError] = useState("");
  const [brandDocumentError, setBrandDocumentError] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [chooseCategoryError, setChooseCategoryError] = useState("");
  const [Loading, setLoading] = useState(false);
  const [SellerData, setSellerData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [Loading1, setLoading1] = useState(false);

  const fileInputRef = useRef(null);

  const handleBrands = async () => {
    try {
      const res = await getAllBrands();

      if (res.status === 200) {
        // console.log(res);
        setData(res.data);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    }
  };

  const handleGetAllCategoryAssociations = async () => {
    try {
      const res = await handleGetAllCategories();
      if (res) {
        // console.log(res);
        setassociations(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetSellerById = async () => {
    try {
      const res = await getSellerById(id);
      if (res) {
        setSellerData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetSellerById();
  }, [refresh]);

  const handleDocumentType = (e) => {
    const selectedCertificate = e.target.value;
    setbrandCertificate(selectedCertificate);

    // Validate the selected certificate
    if (selectedCertificate) {
      setBrandCertificateError("");
    } else {
      setBrandCertificateError("Please select a document type");
    }
  };

  const handleBrandDocumentChange = (e) => {
    const file = e.target.files[0];

    // Validate the file type
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
    ];
    if (file && allowedTypes.includes(file.type)) {
      setBrandDocument(file);
      setBrandDocumentError("");
      setPreviewImage(URL.createObjectURL(file));
    } else {
      setBrandDocument(null);
      setBrandDocumentError(
        "Invalid file type. Only PDF, JPEG, and PNG files are allowed."
      );
      setPreviewImage("");
    }
  };
  const handleCategories = (e) => {
    const selectedCategories = e.target.value;
    setselectCategories(selectedCategories);

    // Validate the selected categories
    if (selectedCategories.length > 0) {
      setChooseCategoryError("");
    } else {
      setChooseCategoryError("Please select at least one category");
    }
  };

  const handleOptionChange = (e) => {
    const selectedBrandName = e.target.value;

    SetbrandName(selectedBrandName);

    // Validate the selected brand name
    if (selectedBrandName) {
      setBrandNameError("");
    } else {
      setBrandNameError("Please select a brand");
    }
  };

  const handleBrandChange = (event, newValue) => {
    if (newValue) {
      SetbrandName(newValue.name);
      handleOptionChange({ target: { value: newValue.name } });
    } else {
      SetbrandName("");
    }
  };

  const id = localStorage.getItem("sellerId");

  const handleAddSellerBrand = async () => {
    setLoading(true);
    try {
      if (
        !brandName ||
        !brandCertificate ||
        !chooseCategory ||
        !BrandDocument
      ) {
        setBrandNameError("Brand is required");
        setBrandCertificateError("Brand certificate is required");
        setChooseCategoryError("Categories is required");
        setBrandDocumentError("Brand document is required");
        Swal.fire({
          position: "center",
          icon: "error",
          text: "All Feilds are required",
          showConfirmButton: true,
        });
        return;
      }
      const logoData = new FormData();
      logoData.append("file", BrandDocument);
      logoData.append("upload_preset", "insta-clone");
      logoData.append("cloud_name", "bunny1705");

      const logoRes = await fetch(
        "https://api.cloudinary.com/v1_1/bunny1705/image/upload",
        {
          method: "POST",
          body: logoData,
        }
      );

      const logoDataJson = await logoRes.json();
      const logoUrl = logoDataJson.url;

      const res = await SellerAddBrandUpdate(
        id,
        brandName,
        brandCertificate,
        logoUrl,
        chooseCategory
      );
      if (res.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res.data.message,
          showConfirmButton: true,
        });
        setRefresh((refresh) => !refresh);
        SetbrandName("");
        setbrandCertificate();
        setBrandDocument(null);
        setselectCategories([]);
        fileInputRef.current.value = "";
        setPreviewImage("");

        // navigate("/thank-you", { replace: true });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
      SetbrandName("");
      setbrandCertificate("");
      setPreviewImage("");
      setBrandDocument(null);
      setselectCategories([]);
    } finally {
      setLoading(false);
    }
  };

  const sortedData = React.useMemo(() => {
    return [...data].sort((a, b) => a.name.localeCompare(b.name));
  }, [data]);

  const handleSubmitBrandDatas = async () => {
    setLoading1(true);
    try {
      const res = await handleSubmitBrandData(id);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "You have been registered successfully and your Account is under admin review. You should receive a confirmation email in the next 24-48 hours.",
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // Redirect to the next page
            navigate("/content/about-us");
          }
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    Promise.all([handleBrands(), handleGetAllCategoryAssociations()]);
  }, []);

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          px: 2,
        }}
      >
        <Grid item xs={12} lg={4.5} xl={4.5}>
          <div>
            <Card
              sx={{ maxWidth: "450px", p: 2 }}
              style={{
                margin: "auto",
                marginTop: "80px",
                border: "1px solid #dddddd",
              }}
              elevation={0}
            >
              <Box sx={{ mb: 3 }}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Seller Add Brand
                </Typography>
                <Typography
                  sx={{
                    border: "2px solid #0099cc",
                    width: "60px",
                    margin: "auto",
                  }}
                ></Typography>
              </Box>
              <Box>
                <Box sx={{ mb: 2 }}>
                  <Autocomplete
                    value={
                      data.find((option) => option.name === brandName) || null
                    }
                    onChange={handleBrandChange}
                    options={sortedData}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Brand"
                        variant="outlined"
                        error={brandNameError}
                        helperText={brandNameError}
                      />
                    )}
                  />
                  {/* {brandNameError && (
                    <Typography color="error">{brandNameError}</Typography>
                  )} */}
                </Box>
              </Box>
              <Box>
                <FormControl fullWidth error={brandCertificateError}>
                  <InputLabel>Select Brand Document Type</InputLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    select
                    value={brandCertificate}
                    onChange={handleDocumentType}
                    input={<OutlinedInput label="Select Brand Document Type" />}
                  >
                    <MenuItem value="InvoiceCopy">Invoice Copy</MenuItem>
                    <MenuItem value="CertificateCopy">
                      Certificate Copy
                    </MenuItem>
                  </Select>
                  <FormHelperText sx={{ mb: 2 }}>
                    {brandCertificateError}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <TextField
                  variant="outlined"
                  ref={fileInputRef}
                  label={
                    brandCertificate
                      ? `Upload ${brandCertificate} Document`
                      : "Upload Brand Document"
                  }
                  type="file"
                  inputProps={{ accept: ".pdf, .jpeg, .png, .jpg" }}
                  onChange={handleBrandDocumentChange}
                  fullWidth
                  sx={{ mt: 0.5, mb: 2 }}
                  error={brandDocumentError}
                  helperText={brandDocumentError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {previewImage && (
                  <Box sx={{ m: 1 }}>
                    <img
                      src={previewImage}
                      alt="Preview"
                      style={{ maxWidth: "100%", maxHeight: 100 }}
                    />
                  </Box>
                )}
              </Box>
              <Box>
                <FormControl
                  sx={{ width: "100%", mb: 2 }}
                  error={chooseCategoryError}
                >
                  <Select
                    multiple
                    displayEmpty
                    value={chooseCategory}
                    onChange={handleCategories}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Select Categories</em>;
                      }

                      return selected.join(", ");
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      <em>Select Categories</em>
                    </MenuItem>
                    {associations.map((item) => {
                      if (item.brand === brandName) {
                        return item?.categories?.map((item1) => (
                          <MenuItem key={item1} value={item1}>
                            <Checkbox
                              checked={chooseCategory.indexOf(item1) > -1}
                            />
                            <ListItemText primary={item1} />
                          </MenuItem>
                        ));
                      }
                      return null;
                    })}
                  </Select>
                  <FormHelperText>{chooseCategoryError}</FormHelperText>
                </FormControl>
              </Box>
              <Typography sx={{ fontSize: "14px", mt: 2, color: "red", mb: 1 }}>
                Note : Upload .jpeg, .png, .jpg, .pdf{" "}
              </Typography>
              <Button
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleAddSellerBrand}
                disabled={Loading}
                disableElevation
              >
                {Loading ? <CircularProgress size={24} /> : "Add Brand"}
              </Button>
            </Card>
          </div>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 6, mb: 3 }} />
      <Box sx={{ mb: 3 }}>
        <SellerBrandTable
          SellerData={SellerData}
          setSellerData={setSellerData}
          setRefresh={setRefresh}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Button
          variant="contained"
          style={{ width: "200px", color: "#fff" }}
          disabled={SellerData?.SellerBrand?.length <= 0 || Loading1}
          onClick={handleSubmitBrandDatas}
        >
          {Loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
        </Button>
      </Box>
    </>
  );
};

export default SellerAddBrand;
