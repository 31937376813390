import { Box, Card, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  getLogoAndDetails,
  getLogoAndDetailsById,
  getMarketPlaceProfile,
  getMarketPlaceProfileById,
  getSellerById,
} from "../axios";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import ReactDOMServer from "react-dom/server";

const ThankYou = () => {
  return (
    <div style={{ marginTop: "150px" }}>
      <Box sx={{ mb: 2 }}>
        <Card
          sx={{ maxWidth: "450px", p: 3 }}
          style={{
            margin: "auto",
            marginTop: "40px",
            border: "1px solid #dddddd",
          }}
          elevation={0}
        >
          <Typography sx={{ textAlign: "center", fontSize: "20px" }}>
            Thank you for registering.
          </Typography>
          <Typography sx={{ textAlign: "center", fontSize: "16px" }}>
            Your Account is Under Review By Admin You will be notified when your
            account Activated
          </Typography>
        </Card>
      </Box>
    </div>
  );
};

export default ThankYou;
