import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { handleForgotPassword } from "../axios";
import Swal from "sweetalert2";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [Loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleForgotPasswordForSeller = async () => {
    setLoading(true);
    try {
      if (!email) {
        setEmailError(true);
        return;
      }
      const res = await handleForgotPassword(email);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "We have sent a reset-password link to your email.",
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: "User not found",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "User not found",
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(e.target.value);
    setEmailError(!emailPattern.test(e.target.value));
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <Grid item xs={12} lg={4.5} md={4.5} xl={4.5}>
        <Card
          sx={{ border: "1px solid #dddddd", p: 2, width: "100%" }}
          elevation={0}
        >
          <Typography sx={{ fontSize: "22px", fontWeight: "bold", mb: 0.7 }}>
            Forgot your password?
          </Typography>
          <Typography
            sx={{ fontSize: "15px" }}
            variant="subtitle2"
            gutterBottom
          >
            Please use your registration email address
          </Typography>
          <Box>
            <TextField
              label="Email"
              placeholder="Enter Email Address"
              value={email}
              onChange={handleEmailChange}
              error={emailError}
              helperText={emailError ? "Invalid email address" : ""}
              fullWidth
              sx={{ mt: 2, mb: 2 }}
            />
          </Box>
          <Button
            variant="contained"
            fullWidth
            onClick={handleForgotPasswordForSeller}
            disabled={Loading}
            disableElevation
          >
            {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
          </Button>
          <Typography
            sx={{ fontSize: "14px", textAlign: "end", mt: 2 }}
            variant="subtitle2"
            gutterBottom
          >
            <Link to="/" style={{ textDecoration: "none", color: "#0099cc" }}>
              Try to log in again.
            </Link>
          </Typography>
        </Card>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            textAlign: "center",
            mt: 2,
          }}
          variant="subtitle2"
          gutterBottom
        >
          Copyright © 2023, skillshop.in, All Rights Reserved
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
