import React, { useState } from "react";
import {
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { login } from "../Redux/actions/AuthActions";
import { SellerLogin } from "../axios";

const Login = ({ theme, isMediumScreen }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSellerLogin = async () => {
    setLoading(true);
    try {
      if (!email || !password) {
        setEmailError(!email);
        setPasswordError(!password);
        setLoading(false);
        return;
      }

      const res = await SellerLogin(email, password);

      if (res.status === 200) {
        dispatch(login(res.data.token, res.data));

        Swal.fire({
          position: "center",
          icon: "success",
          text: "LoggedIn successfully",
          showConfirmButton: true,
        });
        navigate("/default/home");
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(e.target.value);
    setEmailError(!emailPattern.test(e.target.value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(e.target.value === "");
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          minHeight: "100vh",
        }}
      >
        <Grid item xs={12} sm={8} md={6} lg={4.7}>
          <Card
            sx={{ p: 2, border: "1px solid #ddd", ml: 2, mr: 2 }}
            elevation={0}
          >
            <Box sx={{ mb: 3 }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Seller Login
              </Typography>
              <Typography
                sx={{
                  border: "2px solid #0099cc",
                  width: "60px",
                  margin: "auto",
                }}
              ></Typography>
            </Box>
            <Box>
              <TextField
                variant="outlined"
                type="email"
                label="Email"
                placeholder="Email"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                error={emailError}
                helperText={emailError ? "Invalid email address" : ""}
                sx={{ mb: 2 }}
              />
              <TextField
                variant="outlined"
                type="password"
                label="Password"
                placeholder="Password"
                fullWidth
                value={password}
                onChange={handlePasswordChange}
                error={passwordError}
                helperText={passwordError ? "Password is required" : ""}
                sx={{ mb: 2 }}
              />
            </Box>

            <Button
              variant="contained"
              fullWidth
              onClick={handleSellerLogin}
              disabled={loading}
              disableElevation
            >
              {loading ? <CircularProgress size={24} /> : "LOGIN"}
            </Button>
            <Box
              mt={3}
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              gap="10px"
              sx={{ mb: 3 }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: "600" }}
                gutterBottom
              >
                New Seller to SkillShop?{" "}
                <Link
                  to="/register"
                  style={{ textDecoration: "none", color: "#0099cc" }}
                >
                  Create Account
                </Link>
              </Typography>
              <Link
                to="/forgot-password"
                style={{ textDecoration: "none", color: "#0099cc" }}
              >
                Forgot Password
              </Link>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Login;
