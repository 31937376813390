import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { ChangePasswordForSeller } from "../../axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const ChangePasswordTab = ({ data, Loading, setRefresh }) => {
  const [OldPassword, setOldPassword] = useState();
  const [OldPasswordError, setOldPasswordError] = useState();
  const [NewPassword, setNewPassword] = useState();
  const [NewPasswordError, setNewPasswordError] = useState();
  const [ConfirmNewPassword, setConfirmNewPassword] = useState();
  const [ConfirmNewPasswordError, setConfirmNewPasswordError] = useState();
  const [Loading1, setLoading1] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const handleChangePassword = async () => {
    if (
      !NewPassword ||
      !ConfirmNewPassword ||
      !OldPassword ||
      NewPassword !== ConfirmNewPassword
    ) {
      NewPasswordError("New password is required");
      ConfirmNewPasswordError("Confirm password is required");
      OldPasswordError("Old password is required");
      return;
    }
    setLoading1(true);
    try {
      const res = await ChangePasswordForSeller(
        user._id,
        NewPassword,
        ConfirmNewPassword
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res.message,
          showConfirmButton: true,
        });
        setRefresh((refresh) => !refresh);
        setNewPassword("");
        setConfirmNewPassword("");
        setOldPassword("");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response.data,
        showConfirmButton: true,
      });
    } finally {
      setLoading1(false);
    }
  };

  const handleOdPassword = (e) => {
    const value = e.target.value;

    if (value.length === 0) {
      setOldPasswordError("Please Enter Old Password");
    } else if (value !== data.password) {
      setOldPasswordError("Please Enter Valid Old Password");
    } else {
      setOldPasswordError("");
    }

    setOldPassword(value);
  };
  const handleNewPassword = (e) => {
    const value = e.target.value;

    if (value.length === 0) {
      setNewPasswordError("Please Enter New Password");
    } else if (value === data.password) {
      setNewPasswordError("New password cannot be same as old password");
    } else if (value.length < 8) {
      setNewPasswordError("Password must be 8 charecters");
    } else {
      setNewPasswordError("");
    }

    setNewPassword(value);
  };
  const handleNewConfirmPassword = (e) => {
    const value = e.target.value;

    if (value.length === 0) {
      setConfirmNewPasswordError("Please Enter Confirm Password");
    } else if (value !== NewPassword) {
      setConfirmNewPasswordError(
        "Confirm password and New password didn't match"
      );
    } else {
      setConfirmNewPasswordError("");
    }

    setConfirmNewPassword(value);
  };
  return (
    <div>
      {!Loading ? (
        <Box sx={{ border: "1px solid #ddd", mb: 5 }}>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ fontWeight: "bold" }}>Change Password</Typography>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Email:
              </Typography>
              <TextField
                placeholder="Shipping Address"
                sx={{ width: "350px" }}
                value={data.email}
                disabled
                size="medium"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Old Password:
              </Typography>
              <TextField
                placeholder="Old Password"
                sx={{ width: "350px" }}
                type="password"
                value={OldPassword}
                onChange={handleOdPassword}
                error={OldPasswordError}
                helperText={OldPasswordError}
                // value={data.warehouselandmark}
                size="medium"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                New Password:
              </Typography>
              <TextField
                placeholder="New Password"
                type="password"
                sx={{ width: "350px" }}
                value={NewPassword}
                onChange={handleNewPassword}
                error={NewPasswordError}
                helperText={NewPasswordError}
                // value={data.POBox}
                size="medium"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Confirm Password:
              </Typography>
              <TextField
                placeholder="Confirm Password"
                type="password"
                sx={{ width: "350px" }}
                value={ConfirmNewPassword}
                onChange={handleNewConfirmPassword}
                error={ConfirmNewPasswordError}
                helperText={ConfirmNewPasswordError}
                // value={data.warehouseCity}

                size="medium"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              ></Typography>
              <Button
                variant="contained"
                sx={{ background: "#0099cc", width: "200px" }}
                disableElevation
                onClick={handleChangePassword}
                disabled={Loading1}
              >
                {Loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "60vh",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ChangePasswordTab;
