import { Box, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const AboutUs = ({ data }) => {
  const [paragraphs, setParagraphs] = useState([]);

  useEffect(() => {
    const parser = new DOMParser();
    const html = parser.parseFromString(data.Description, "text/html");
    const paragraphElements = html.getElementsByTagName("p");

    const paragraphArray = Array.from(paragraphElements).map((element) =>
      element.textContent.trim()
    );

    setParagraphs(paragraphArray);
  }, [data.Description]);
  return (
    <div style={{ marginTop: "80px" }}>
      <Box>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
        >
          {data.Heading}
        </Typography>
        <Typography
          sx={{ border: "2px solid #0099cc", width: "60px", margin: "auto" }}
        ></Typography>
      </Box>
      <Box sx={{ ml: 3, mr: 3, mt: 5 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          {paragraphs.map((paragraph, index) => (
            <Typography key={index} variant="subtitle1" gutterBottom>
              {paragraph}
            </Typography>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default AboutUs;
