import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { CKEditor } from "ckeditor4-react";
import React, { useEffect, useState } from "react";
import {
  getAllBrandAttributes,
  getAllColorAttributes,
  getAllCustomAttributes,
  getAllMainAttributes,
  getAllSizeAttributes,
  getAllSizeCharts,
  getProductTypeValues,
} from "../../../axios";

const Attribute = ({
  selectedId,
  handleChange,
  checked,
  setChecked,
  Color = "Colors",
  Size = "Sizes",
  Fit,
  singleProductData,
  setSingleProductData,
}) => {
  const [data, setData] = useState({});
  const [CustomAttributedata, setCustomAttributeData] = useState([]);
  const [MainAttributedata, setMainAttributeData] = useState([]);
  const [customAttributes, setCustomAttributes] = useState([]);
  const [MainAttributes, setMainAttributes] = useState([]);
  const [colorAttributes, setColorAttributes] = useState([]);
  const [SizeAttributes, setSizeAttributes] = useState([]);
  const [SizeCharts, setSizeCharts] = useState([]);
  const [BrandAttributes, setBrandAttributes] = useState([]);
  const [Loading1, setLoading1] = useState(false);
  const [Loading2, setLoading2] = useState(false);
  const [Loading3, setLoading3] = useState(false);
  const [Loading4, setLoading4] = useState(false);
  const [Loading5, setLoading5] = useState(false);
  const [Loading6, setLoading6] = useState(false);
  const [Loading7, setLoading7] = useState(false);
  const [Loading8, setLoading8] = useState(false);
  const [editorData, setEditorData] = useState("");

  //   console.log(BrandAttributes);

  const isMediumScreen = useMediaQuery("(min-width: 960px)");

  const handleGetAllProductTypesCategory = async () => {
    setLoading1(true);
    try {
      const res = await getProductTypeValues(selectedId);

      if (res) {
        setData(res.data);
        setCustomAttributeData(res.data.CustomAttributes);
        setMainAttributeData(res.data.MainAttributes);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };
  const handleGetAllCustomAttribues = async () => {
    setLoading2(true);
    try {
      const res = await getAllCustomAttributes();

      if (res) {
        setCustomAttributes(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading2(false);
    }
  };

  // get handle All Main Attributes

  const handleGetAllMainAttribues = async () => {
    setLoading3(true);
    try {
      const res = await getAllMainAttributes();

      if (res.status === 200) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        setMainAttributes(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading3(false);
    }
  };
  const handleGetAllBrandAttribues = async () => {
    setLoading4(true);
    try {
      const res = getAllBrandAttributes();

      if (res.status === 200) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
        setBrandAttributes(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading4(false);
    }
  };
  const handleGetAllColorAttribues = async () => {
    setLoading5(true);
    try {
      const res = await getAllColorAttributes();

      if (res.status === 200) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
        setColorAttributes(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading5(false);
    }
  };
  const handleGetAllSizeAttribues = async () => {
    setLoading6(true);
    try {
      const res = await getAllSizeAttributes();

      if (res.status === 200) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
        setSizeAttributes(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading6(false);
    }
  };
  const handleGetAllSizeCharts = async () => {
    setLoading7(true);
    try {
      const res = await getAllSizeCharts();

      if (res.status === 200) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
        setSizeCharts(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading7(false);
    }
  };

  const handleCheckedChange = (e) => {
    const isChecked = e.target.checked;
    const categoryName = e.target.name;
    setSingleProductData((prevState) => {
      const newData = { ...prevState.data };
      if (isChecked) {
        newData[Color] = [...prevState.data[Color], categoryName];
      } else {
        newData[Color] = prevState.data[Color].filter(
          (size) => size !== categoryName
        );
      }

      return {
        ...prevState,
        data: newData,
      };
    });
  };
  const handleCheckedSizeChange = (e) => {
    const isChecked = e.target.checked;
    const categoryName = e.target.name;

    setSingleProductData((prevState) => {
      const newData = { ...prevState.data };

      if (isChecked) {
        newData[Size] = [...prevState.data[Size], categoryName];
      } else {
        newData[Size] = prevState.data[Size].filter(
          (size) => size !== categoryName
        );
      }

      return {
        ...prevState,
        data: newData,
      };
    });
  };

  //   console.log(newData);

  const productData = [];
  customAttributes.forEach((item, i) => {
    const dataItem = CustomAttributedata.find((data, i) => data === item.name);
    if (dataItem) {
      const res = customAttributes.find((item) => item.name === dataItem);
      productData.push(res);
    }
  });
  const productMainData = [];
  MainAttributes.forEach((item, i) => {
    const dataItem = MainAttributedata.find(
      (data, i) => data === item.AttributeName
    );
    if (dataItem) {
      const res = MainAttributes.find(
        (item) => item.AttributeName === dataItem
      );
      productMainData.push(res);
    }
  });

  const ITEM_HEIGHT = 68;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleEditorChange = (event, name) => {
    const parsedData = event.editor.getData();
    setEditorData(parsedData);

    setSingleProductData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: parsedData,
      },
    }));
  };

  useEffect(() => {
    setSingleProductData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        name: editorData,
      },
    }));
  }, [editorData]);

  console.log(singleProductData);

  //   console.log(productMainData);

  useEffect(() => {
    Promise.all([
      handleGetAllProductTypesCategory(),
      handleGetAllCustomAttribues(),
      handleGetAllMainAttribues(),
      handleGetAllBrandAttribues(),
      handleGetAllColorAttribues(),
      handleGetAllSizeAttribues(),
      handleGetAllSizeCharts(),
    ]);
  }, []);
  return (
    <div>
      <Box>
        <Card sx={{ border: "1px solid #dddddd", p: 2 }} elevation={0}>
          {!Loading1 &&
          !Loading2 &&
          !Loading3 &&
          !Loading4 &&
          !Loading5 &&
          !Loading6 &&
          !Loading7 &&
          !Loading8 ? (
            <>
              <Box>
                {productData.map((item) => {
                  if (item.AttributType == "DropdownList") {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "5px",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item.name} :{" "}
                        </Typography>
                        <Select
                          displayEmpty
                          sx={{ width: "300px" }}
                          MenuProps={MenuProps}
                          value={singleProductData?.data[item.name] || ""}
                          name={item.name}
                          onChange={(e) =>
                            setSingleProductData({
                              ...singleProductData,
                              [item.name]: e.target.value,
                            })
                          }
                        >
                          <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
                          {item.initialValue.map((data) => (
                            <MenuItem value={data}>{data}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    );
                  }
                  if (item.AttributType == "TextArea") {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "5px",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item.name} :{" "}
                        </Typography>
                        {/* <textarea
                      multiline
                      style={{
                        width: "390px",
                        height: "120px",
                        resize: "vertical",
                        border: "2px solid #ddd",
                        borderRadius: "5px",
                      }}
                      value={singleProductData?.data[item.name] || ""}
                      size="small"
                      onChange={(e) =>
                        setSingleProductData((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            [item.name]: e.target.value,
                          },
                        }))
                      }
                      placeholder={item.name}
                    /> */}
                        <Box>
                          <CKEditor
                            initData={singleProductData?.data?.[item.name]}
                            onChange={(event) =>
                              handleEditorChange(event, item.name)
                            }
                          />
                          <Typography sx={{ color: "red" }}>
                            Note*:To add multiple Offers Write them as different
                            paragraphs
                          </Typography>
                        </Box>
                      </Box>
                    );
                  }
                  if (item.AttributType == "TextBox") {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "5px",
                          alignItems: "center",

                          mb: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item.name} :{" "}
                        </Typography>
                        <TextField
                          multiline
                          sx={{ width: "300px" }}
                          value={singleProductData?.data[item.name]}
                          onChange={(e) =>
                            setSingleProductData((prevState) => ({
                              ...prevState,
                              data: {
                                ...prevState.data,
                                [item.name]: e.target.value,
                              },
                            }))
                          }
                          placeholder={item.name}
                        />
                      </Box>
                    );
                  }
                })}
              </Box>
              <Box sx={{ mt: 2 }}>
                {MainAttributedata.map((item, i) => {
                  if (item === "Brand") {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item} :{" "}
                        </Typography>
                        <Select
                          displayEmpty
                          MenuProps={MenuProps}
                          sx={{ width: "300px" }}
                          value={singleProductData?.BrandName || ""}
                          onChange={(e) =>
                            setSingleProductData({
                              ...singleProductData,
                              BrandName: e.target.value,
                            })
                          }
                        >
                          <InputLabel sx={{ ml: 2 }}>Select </InputLabel>
                          {BrandAttributes.map((item) => (
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    );
                  }
                  if (item === "Color") {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          // justifyContent: "space-between",
                          alignItems: "flex-start",
                          flexWrap: !isMediumScreen && "wrap",
                          gap: "5px",
                          mt: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item}:
                        </Typography>
                        <Grid container spacing={2}>
                          {colorAttributes.map((item) => (
                            <Grid
                              item
                              key={item.name}
                              xs={6}
                              sm={4}
                              md={4}
                              lg={3}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  // minWidth: "205px",
                                }}
                              >
                                <Checkbox
                                  name={item.name}
                                  checked={singleProductData?.data[
                                    Color
                                  ]?.includes(item.name)}
                                  onChange={handleCheckedChange}
                                />

                                <Typography sx={{ fontSize: "14px" }}>
                                  {item.name}
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    );
                  }
                  if (item === "Size") {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          // justifyContent: "space-between",
                          alignItems: "flex-start",
                          flexWrap: !isMediumScreen && "wrap",
                          gap: "5px",
                          mt: 4,
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item}:
                        </Typography>
                        <Box>
                          <Grid container spacing={2}>
                            {SizeAttributes.map((item) => (
                              <Grid item xs={12} lg={3} sm={4} md={4}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    // minWidth: "215px",
                                  }}
                                >
                                  <Checkbox
                                    name={item.name}
                                    checked={singleProductData?.data[
                                      Size
                                    ]?.includes(item.name)}
                                    onChange={handleCheckedSizeChange}
                                  />
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {item.name}
                                  </Typography>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    );
                  }
                  if (item === "Size Chart") {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "5px",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item} :{" "}
                        </Typography>
                        <Select
                          displayEmpty
                          sx={{ width: "300px" }}
                          value={singleProductData?.data[item] || ""}
                          name={item.name}
                          onChange={(e) =>
                            setSingleProductData((prevState) => ({
                              ...prevState,
                              data: {
                                ...prevState.data,
                                [item]: e.target.value,
                              },
                            }))
                          }
                        >
                          <InputLabel sx={{ ml: 2 }}>Select </InputLabel>
                          {SizeCharts.map((item) => (
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    );
                  }
                })}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "5px",
                    mt: 3,
                    mb: 5,
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "300px",
                    }}
                  ></Typography>
                  <Button
                    variant="contained"
                    onClick={() => handleChange(0, 4)}
                    sx={{ width: "200px" }}
                  >
                    NEXT
                  </Button>
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40vh",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Card>
      </Box>
    </div>
  );
};

export default Attribute;
