import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Typography, useMediaQuery } from "@mui/material";
import { getAllStaticPages } from "../axios";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { MdPolicy } from "react-icons/md";
import { RiSettings3Fill } from "react-icons/ri";
import HomeIcon from "@mui/icons-material/Home";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import PaymentsIcon from "@mui/icons-material/Payments";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import navigations from "./navigations";
import { BsDot } from "react-icons/bs";
import logo from "../images/logo.png";
import { Close } from "@mui/icons-material";

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = useState(false);
  const [data, setData] = useState([]);
  const [openItems, setOpenItems] = useState([]);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const handleGetAllStaticPages = async () => {
    try {
      const res = await getAllStaticPages();
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllStaticPages();
  }, []);

  const getIconForItem = (itemId) => {
    switch (itemId) {
      case "2":
        return <InfoIcon size={24} />;
      case "3":
        return <LiveHelpIcon size={24} />;
      case "4":
        return <RiSettings3Fill size={24} />;
      case "5":
        return <MdPolicy size={24} />;
      default:
        return null;
    }
  };

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { token, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleItemClick = (index) => {
    setOpenItems((prevOpenItems) => {
      const updatedOpenItems = [...prevOpenItems];
      updatedOpenItems[index] = !updatedOpenItems[index];
      return updatedOpenItems;
    });
  };

  return (
    <div>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon sx={{ color: "white", mt: -1, ml: -2 }} />
      </IconButton>

      <SwipeableDrawer
        anchor="left"
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "150px",
              p: 2,
            }}
          >
            <img src={logo} alt="logo" width="100%" height="100%" />
          </Box>
          <Box sx={{ p: 2 }}>
            <IconButton onClick={toggleDrawer(false)}>
              <Close sx={{ color: "#000" }} />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{ width: 300 }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          {!token ? (
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate(`/content/home`)}>
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText secondary="HOME" />
                </ListItemButton>
              </ListItem>
              {data.map((item) => {
                if (
                  item.active === true &&
                  (item.SortingId === "2" ||
                    item.SortingId === "3" ||
                    item.SortingId === "4" ||
                    item.SortingId === "5")
                ) {
                  return (
                    <ListItem key={item.id} disablePadding>
                      <ListItemButton
                        onClick={() => navigate(`/content/${item.Link}`)}
                      >
                        <ListItemIcon>
                          {getIconForItem(item.SortingId)}
                        </ListItemIcon>
                        <ListItemText secondary={item.Heading} />
                      </ListItemButton>
                    </ListItem>
                  );
                }
              })}
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate(`/content/contact`)}>
                  <ListItemIcon>
                    <ContactPageIcon />
                  </ListItemIcon>
                  <ListItemText secondary="CONTACT US" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate(`/content/payout`)}>
                  <ListItemIcon>
                    <PaymentsIcon />
                  </ListItemIcon>
                  <ListItemText
                    secondary="COMMISSION PAYOUT"
                    style={{ fontSize: "14px" }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          ) : (
            <List>
              {navigations.map((navigation, index) => (
                <React.Fragment key={navigation.name}>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => handleItemClick(index)}>
                      <ListItemIcon>{navigation.icon}</ListItemIcon>
                      <ListItemText
                        secondary={navigation.name}
                        sx={{ color: "#000", ml: -2 }}
                      />
                      {openItems[index] ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </ListItemButton>
                  </ListItem>
                  {navigation.children && openItems[index] && (
                    <List>
                      {navigation.children.map((child) => (
                        <ListItem key={child.name} disablePadding>
                          <ListItemButton onClick={() => navigate(child.to)}>
                            <ListItemIcon>
                              <BsDot style={{ color: "#000" }} />
                            </ListItemIcon>
                            <Typography variant="subtitle2" sx={{ ml: -4 }}>
                              {child.name}
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  )}
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          )}
        </Box>
      </SwipeableDrawer>
    </div>
  );
}
