import {
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { Category } from "@mui/icons-material";

const CodAndShipping = ({
  checked,
  setChecked,
  CodAvailable = "No",
  ShippingWeight,
  Fragile = "No",
  singleProductData,
  setSingleProductData,
}) => {
  const { token } = useSelector((data) => data.auth);
  const { id } = useParams();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);

  // console.log(singleProductData);

  // console.log("colors", singleProductData.data.Colors);
  const handleSingleListing = async () => {
    setLoading(true);
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/update-single-product/${id}`,
        {
          CodAvailable: singleProductData.CodAvailable,
          Fragile: singleProductData.Fragile,
          PreBook: singleProductData.PreBook,
          replacement: singleProductData.replacement,
          refund: singleProductData.refund,
          Title: singleProductData.Title,
          SKUCode: singleProductData.SkuCode,
          BrandName: singleProductData.BrandName,
          UniqueUrl: singleProductData.UniqueUrl,
          Category: singleProductData.Category,
          mainCategory: singleProductData.mainCategory,
          subCategory: singleProductData.subCategory,
          ProductType: singleProductData.ProductType,
          HSNCode: singleProductData.HSNCode,
          Tax: singleProductData.Tax,
          Description: singleProductData.Description,
          SkuCode: singleProductData.SkuCode,
          searchTitle: singleProductData.searchTitle,
          searchKeywords: singleProductData.searchKeywords,
          searchDescription: singleProductData.searchDescription,
          shipment: singleProductData.shipment,
          returnPolicy: singleProductData.returnPolicy,
          returnPolicyDescription: singleProductData.returnPolicyDescription,
          MemoryStorage: singleProductData.MemoryStorage,
          ShippingWeight: singleProductData.ShippingWeight,
          Colors: singleProductData.data.Colors,
          data: singleProductData.data,
        }
      );
      if (res) {
        console.log(res);
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated SuccessFully",
          showConfirmButton: true,
        });
        navigate("/my-product-list");
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: "error Occured",
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Card sx={{ p: 2, border: "1px solid #dddddd" }} elevation={0}>
        <Box>
          <Box
            sx={{
              display: "flex",
              //   justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              COD Available :{" "}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                // defaultValue="No"
              >
                <FormControlLabel
                  value={singleProductData.CodAvailable}
                  control={<Radio />}
                  checked={singleProductData.CodAvailable == "Yes"}
                  label="Yes"
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      CodAvailable: "Yes",
                    })
                  }
                />
                <FormControlLabel
                  value={singleProductData.CodAvailable}
                  control={<Radio />}
                  checked={singleProductData.CodAvailable == "No"}
                  label="No"
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      CodAvailable: "No",
                    })
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "5px",
              mt: 2,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              Shipping Weight (gms) :{" "}
            </Typography>
            <TextField
              placeholder="Shipping Weight (gms) : "
              sx={{ width: "300px" }}
              value={singleProductData.ShippingWeight}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  ShippingWeight: e.target.value,
                })
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              //   justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "5px",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              Fragile :{" "}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value={singleProductData.Fragile}
                  control={<Radio />}
                  checked={singleProductData.Fragile == "Yes"}
                  label="Yes"
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      Fragile: "Yes",
                    })
                  }
                />
                <FormControlLabel
                  value={singleProductData.Fragile}
                  control={<Radio />}
                  checked={singleProductData.Fragile == "No"}
                  label="No"
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      Fragile: "No",
                    })
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mt: 3,
              mb: 5,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            ></Typography>
            <Button
              variant="contained"
              onClick={() => handleSingleListing()}
              sx={{ width: "200px" }}
              disabled={Loading}
            >
              {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
            </Button>
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default CodAndShipping;
