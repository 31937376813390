import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import contact from "../../images/contact1.png";
import PlaceIcon from "@mui/icons-material/Place";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const handleLinkClick = (event) => {
    event.preventDefault();
    window.open("mailto:sell@skllshop.in");
  };
  const handleLinkClick1 = (event) => {
    event.preventDefault();
    window.open("mailto:sellerSupport@skillshop.in");
  };
  const handleLinkClick2 = (event) => {
    event.preventDefault();
    window.open("mailto:marketing@skillshop.in");
  };
  const handleLinkClick3 = (event) => {
    event.preventDefault();
    window.open("mailto:advertise@skillshop.in");
  };

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ marginTop: !isMediumScreen ? "60px" : "80px" }}>
        {!isMediumScreen ? (
          <Box sx={{ position: "relative" }}>
            <img src={contact} alt="contact" width="100%" height="100%" />
          </Box>
        ) : (
          <Box>
            <Typography
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
            >
              Contact Us
            </Typography>
            <Typography
              sx={{
                border: "2px solid #0099cc",
                width: "60px",
                margin: "auto",
              }}
            ></Typography>
          </Box>
        )}
        <Grid
          container
          spacing={0}
          justifyContent="center"
          sx={{
            position: "sticky",
            px: 2,
          }}
        >
          <Grid item xs={12} lg={10}>
            <Card
              sx={{
                mb: 5,
                mt: !isMediumScreen ? -9 : 5,
                border: "1px solid #ddd",
              }}
            >
              <Grid container spacing={0}>
                <Grid item xs={12} md={6} lg={8}>
                  <Box padding="24px">
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      Contact Us
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginTop: "16px" }}>
                      We would love to hear from you and help you. Here’s how
                      you can reach us…
                    </Typography>

                    <Grid item xs={12} lg={12} sx={{ mt: 5 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexWrap: "wrap",
                          gap: "50px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            minWidth: "300px",

                            gap: "16px",
                          }}
                        >
                          <PlaceIcon sx={{ fontSize: "45px", color: "#ddd" }} />
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              Location
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              Banglore,India
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "16px",
                          }}
                        >
                          <AccessTimeIcon
                            sx={{ fontSize: "45px", color: "#ddd" }}
                          />
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              Timing
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              Mon to Fri: 10:00 AM - 06:00 PM
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              Sat: 10:00 AM - 02:00 PM
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={12} sx={{ mt: 5 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexWrap: "wrap",
                          gap: "50px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            minWidth: "300px",
                            gap: "16px",
                          }}
                        >
                          <PhoneIcon sx={{ fontSize: "45px", color: "#ddd" }} />
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              Phone
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>
                              +91 1234567891
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "16px",
                          }}
                        >
                          <EmailIcon sx={{ fontSize: "45px", color: "#ddd" }} />
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              Email
                            </Typography>
                            <Box>
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                sx={{ marginBottom: "-4px" }}
                              >
                                Register as a seller:
                              </Typography>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#0099cc",
                                }}
                                onClick={handleLinkClick}
                              >
                                sell@skillshop.in
                              </Link>
                            </Box>
                            <Box sx={{ marginTop: "8px" }}>
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                sx={{ marginBottom: "-4px" }}
                              >
                                Existing seller:
                              </Typography>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#0099cc",
                                }}
                                onClick={handleLinkClick1}
                              >
                                sellersupport@skillshop.in
                              </Link>
                            </Box>
                            <Box sx={{ marginTop: "8px" }}>
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                sx={{ marginBottom: "-4px" }}
                              >
                                Share content/images for promotions:
                              </Typography>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#0099cc",
                                }}
                                onClick={handleLinkClick2}
                              >
                                marketing@skillshop.in
                              </Link>
                            </Box>
                            <Box sx={{ marginTop: "8px" }}>
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                sx={{ marginBottom: "-4px" }}
                              >
                                Advertise on Vedson:
                              </Typography>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#0099cc",
                                }}
                                onClick={handleLinkClick3}
                              >
                                advertise@skillshop.in
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Box padding="24px" bgcolor="#f9f9f9">
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      Get in Touch
                    </Typography>
                    <Box sx={{ marginTop: "24px" }}>
                      <TextField
                        placeholder="Enter Your Name"
                        label="Enter your Name"
                        fullWidth
                        sx={{ marginBottom: "16px" }}
                      />
                      <TextField
                        placeholder="Enter Your Company Name"
                        label="Enter your Company Name"
                        fullWidth
                        sx={{ marginBottom: "16px" }}
                      />
                      <TextField
                        placeholder="Enter Your Email"
                        label="Enter your Email"
                        fullWidth
                        sx={{ marginBottom: "16px" }}
                      />
                      <TextField
                        placeholder="Enter Your Mobile Number"
                        label="Enter your Mobile Number"
                        fullWidth
                        sx={{ marginBottom: "16px" }}
                      />
                      <TextField
                        multiline
                        placeholder="Leave Your Message..."
                        label="Leave Your Message..."
                        fullWidth
                        sx={{ marginBottom: "16px" }}
                      />
                      <Button variant="contained" fullWidth size="medium">
                        SUBMIT
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default ContactUs;
