import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { CKEditor } from "ckeditor4-react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllPolicy } from "../../../axios";
import { useState } from "react";
import { useEffect } from "react";

const BasicInfo = ({
  checked,
  setChecked,
  productType,
  selectedId,
  setSelectedId,
  handleTitleChange,
  Title,
  handleChange,
  PreBook = "No",
  replacement = "No",
  refund = "No",
  singleProductData,
  setSingleProductData,
  loading1,
}) => {
  //   function generateUniqueUrl(title) {
  //     const lowercaseTitle = title.toLowerCase();
  //     const urlTitle = lowercaseTitle.replace(/\s+/g, "-");
  //     const uniqueId = Math.floor(Math.random() * 1000000);
  //     return `${urlTitle}-${uniqueId}`;
  //   }

  const navigate = useNavigate();
  const [returnPolcy, setRetrunPolicy] = useState([]);
  const [policyDescription, setPolicyDescription] = useState("");

  const ITEM_HEIGHT = 68;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleEditorChange = (event) => {
    const parseddata = event.editor.getData();
    setSingleProductData({
      ...singleProductData,
      Description: parseddata,
    });

    // console.log("Table:", formattedHtml);
  };

  const handleGetAllPolicies = async () => {
    try {
      const res = await getAllPolicy();
      if (res) {
        setRetrunPolicy(res);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGetAllPolicies();
  }, []);

  useEffect(() => {
    returnPolcy.map((item) => {
      if (item.Heading === singleProductData.returnPolicy) {
        setPolicyDescription(item.Description);
      }
    });
  }, [singleProductData.returnPolicy, returnPolcy]);

  return (
    <Box>
      <Card sx={{ p: 2, border: "1px solid #dddddd" }} elevation={0}>
        {!loading1 ? (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Product Type :{" "}
              </Typography>
              <Select
                displayEmpty
                MenuProps={MenuProps}
                sx={{ width: "300px" }}
                value={singleProductData.ProductType || ""}
                onChange={(e) => {
                  const newData = productType.find(
                    (item) => item.ProductType === e.target.value
                  );
                  // console.log("new", newData);
                  setSelectedId(newData._id);
                  setSingleProductData({
                    ...singleProductData,
                    ProductType: e.target.value,
                  });
                }}
              >
                <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
                {productType.map((item) => (
                  <MenuItem value={item.ProductType}>
                    {item.ProductType}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                PreBook :{" "}
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value={singleProductData.PreBook}
                    control={<Radio />}
                    checked={singleProductData.PreBook == "Yes"}
                    onChange={(e) =>
                      setSingleProductData({
                        ...singleProductData,
                        PreBook: "Yes",
                      })
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value={singleProductData.PreBook}
                    control={<Radio />}
                    checked={singleProductData.PreBook == "No"}
                    onChange={(e) =>
                      setSingleProductData({
                        ...singleProductData,
                        PreBook: "No",
                      })
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Title :{" "}
              </Typography>
              <TextField
                placeholder="Title"
                multiline
                sx={{ width: "300px" }}
                value={singleProductData.Title}
                onChange={(e) =>
                  setSingleProductData({
                    ...singleProductData,
                    Title: e.target.value,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                HSN Code :{" "}
              </Typography>
              <TextField
                placeholder="HSN Code"
                sx={{ width: "300px" }}
                value={singleProductData.HSNCode}
                onChange={(e) =>
                  setSingleProductData({
                    ...singleProductData,
                    HSNCode: e.target.value,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Tax(%) :{" "}
              </Typography>
              <TextField
                placeholder="Tax(%)"
                sx={{ width: "300px" }}
                value={singleProductData.Tax}
                onChange={(e) =>
                  setSingleProductData({
                    ...singleProductData,
                    Tax: e.target.value,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Description :{" "}
              </Typography>
              {/* <TextField
              multiline
              placeholder="Description"
              value={singleProductData.Description}
              onChange={(e) =>
                setSingleProductData({
                  ...singleProductData,
                  Description: e.target.value,
                })
              }
              sx={{ width: "300px" }}
            /> */}
              <CKEditor
                initData={singleProductData?.Description}
                onChange={handleEditorChange}
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                SKU Code :{" "}
              </Typography>
              <TextField
                multiline
                placeholder="SKU Code"
                value={singleProductData.SKUCode}
                onChange={(e) =>
                  setSingleProductData({
                    ...singleProductData,
                    SkuCode: e.target.value,
                  })
                }
                sx={{ width: "300px" }}
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Unique Url :{" "}
              </Typography>
              <TextField
                multiline
                placeholder="Unique URL"
                value={singleProductData.UniqueUrl}
                onChange={(e) =>
                  setSingleProductData({
                    ...singleProductData,
                    UniqueUrl: e.target.value,
                  })
                }
                sx={{ width: "300px" }}
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "flex-start",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Main Image (only jpg,jpeg files allowed) :<br /> Image Size
                Should be less then 1 MB
                <br /> Image Dimension should be 40X75
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  type="file"
                  sx={{ width: "300px", mb: 2 }}
                  inputProps={{ accept: " .jpeg, .jpg" }}
                />
                <Box sx={{ width: "80px" }}>
                  <img
                    src={singleProductData?.MainImage}
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "flex-start",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Product Details : <br />
                Upload only PDF file <br /> PDF Size Should be less then 1 MB
              </Typography>
              <Box>
                <TextField
                  type="file"
                  inputProps={{ accept: " .pdf" }}
                  placeholder="Product Details"
                  sx={{ width: "300px" }}
                />

                {singleProductData?.PrdoductDetailsDoc !== "" && (
                  <Box>
                    <Box sx={{ width: "40px", mt: 2 }}>
                      <img
                        src="https://skillshop-demo.s3.ap-south-1.amazonaws.com/pngfind.com-pdf-icon-png-200654.png"
                        alt=""
                        width="100%"
                        height="100%"
                      />
                    </Box>
                    <a
                      href={singleProductData?.PrdoductDetailsDoc}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View File
                    </a>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Search Title :{" "}
              </Typography>
              <TextField
                placeholder="Search Title"
                multiline
                value={singleProductData.searchTitle}
                onChange={(e) =>
                  setSingleProductData({
                    ...singleProductData,
                    searchTitle: e.target.value,
                  })
                }
                sx={{ width: "300px" }}
                size="small"
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Search Keywords <br />
                (Separated By Comma) :{" "}
              </Typography>
              <TextField
                multiline
                placeholder="Search Keywords (Separated By Comma)"
                value={singleProductData.searchKeywords}
                onChange={(e) => {
                  const data = e.target.value.split(",");
                  setSingleProductData({
                    ...singleProductData,
                    searchKeywords: data,
                  });
                }}
                sx={{ width: "300px" }}
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Search Description :{" "}
              </Typography>
              <TextField
                multiline
                placeholder="Search Description"
                sx={{ width: "300px" }}
                value={singleProductData.searchDescription}
                onChange={(e) =>
                  setSingleProductData({
                    ...singleProductData,
                    searchDescription: e.target.value,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Shipment Fulfilled by Vedson :{" "}
              </Typography>
              <Select
                displayEmpty
                multiline
                value={singleProductData.shipment || ""}
                onChange={(e) =>
                  setSingleProductData({
                    ...singleProductData,
                    shipment: e.target.value,
                  })
                }
                sx={{ width: "300px" }}
              >
                <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
                <MenuItem value="SkillShop will pickUp and ship(SkillShop Fullfillment)">
                  SkillShop will pickUp and ship(SkillShop Fullfillment)
                </MenuItem>
                <MenuItem value=" I will ship items myself(self Fullfillment)">
                  I will ship items myself(self Fullfillment)
                </MenuItem>
              </Select>
            </Box>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Return Policy:{" "}
              </Typography>
              <Select
                displayEmpty
                multiline
                MenuProps={MenuProps}
                value={singleProductData?.returnPolicy}
                onChange={(e) =>
                  setChecked({
                    ...singleProductData,
                    returnPolicy: e.target.value,
                  })
                }
                sx={{ width: "300px" }}
              >
                <InputLabel sx={{ ml: 2 }}>Select Return Policy</InputLabel>
                {returnPolcy.map((item) => {
                  return (
                    <MenuItem value={item.Heading} key={item._id}>
                      {item.Heading}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            {checked.returnPolicy == "" ? (
              ""
            ) : (
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    minWidth: "300px",
                  }}
                >
                  Return Policy Description :{" "}
                </Typography>
                <Typography
                  sx={{
                    width: "600px",
                  }}
                >
                  {policyDescription}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Exchange & Return conditions:{" "}
              </Typography>
              <TextField
                multiline
                placeholder="Search Description"
                value={singleProductData.returnPolicyDescription}
                sx={{ width: "300px" }}
                onChange={(e) =>
                  setSingleProductData({
                    ...singleProductData,
                    returnPolicyDescription: e.target.value,
                  })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Replacement :{" "}
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue="No"
                >
                  <FormControlLabel
                    value={singleProductData.replacement}
                    control={<Radio />}
                    checked={singleProductData.replacement == "Yes"}
                    onChange={(e) =>
                      setSingleProductData({
                        ...singleProductData,
                        replacement: "Yes",
                      })
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value={singleProductData.replacement}
                    control={<Radio />}
                    checked={singleProductData.replacement == "No"}
                    onChange={(e) =>
                      setSingleProductData({
                        ...singleProductData,
                        replacement: "No",
                      })
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "5px",
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Refund :{" "}
              </Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue="No"
                >
                  <FormControlLabel
                    value={refund}
                    control={<Radio />}
                    checked={refund == "Yes"}
                    onChange={(e) =>
                      setSingleProductData({
                        ...singleProductData,
                        refund: "Yes",
                      })
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value={refund}
                    control={<Radio />}
                    checked={refund == "No"}
                    onChange={(e) =>
                      setSingleProductData({
                        ...singleProductData,
                        refund: "No",
                      })
                    }
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "5px",
                mt: 3,
                mb: 5,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              ></Typography>
              <Button
                variant="contained"
                onClick={() => handleChange(0, 3)}
                sx={{ width: "200px" }}
              >
                NEXT
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default BasicInfo;
