import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ProductTab from "./ProductTabs";

const UpdateSingleListing = () => {
  return (
    <div style={{ marginTop: "80px" }}>
      <Box sx={{ mb: 3 }}>
        <Typography
          sx={{ fontSize: "23px", fontWeight: "bold", textAlign: "center" }}
        >
          Product's Manage
        </Typography>
        <Typography
          sx={{ border: "2px solid #0099cc", width: "60px", margin: "auto" }}
        ></Typography>
      </Box>
      <ProductTab />
    </div>
  );
};

export default UpdateSingleListing;
