import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ManageHolidayTab = () => {
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [ToDate, setToDate] = useState();
  const [description, setDescription] = useState();
  const [DescriptionError, setDescriptionError] = useState("");
  const handleDateFromChange = (date) => {
    if (date && date <= new Date()) {
      setError(true);
    } else {
      setError(false);
      setFromDate(date);
    }
  };
  const handleDateToChange = (date) => {
    if (date < new Date(fromDate)) {
      setError1(true);
    } else {
      setError1(false);
      setToDate(date);
    }
  };

  const handleDescription = (e) => {
    const value = e.target.value;
    if (value.length === 0) {
      setDescriptionError("Description is required");
    } else {
      setDescriptionError("");
    }
    setDescription(value);
  };

  console.log(fromDate, ToDate, description);
  return (
    <div>
      <Box sx={{ border: "1px solid #ddd", mb: 5 }}>
        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>Manage Holiday</Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mb: 2,
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              From Date:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ width: "350px" }}
                disablePast
                value={fromDate && fromDate.$d}
                onChange={(date) => handleDateFromChange(date)}
                error={error}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mb: 2,
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              To Date:
            </Typography>
            <Box>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                sx={{ color: "red" }}
              >
                <DatePicker
                  sx={{
                    width: "350px",
                    color: error1 ? "red" : "inherit", // Apply red color when isError is true
                  }}
                  disablePast
                  value={ToDate && ToDate.$d}
                  onChange={(date) => handleDateToChange(date)}
                />
              </LocalizationProvider>
              <Box>
                {error1 && (
                  <Typography sx={{ color: "red" }}>
                    To Date should be greater than From Date
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mb: 2,
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            >
              Description:
            </Typography>
            <TextField
              placeholder="Description"
              sx={{ width: "350px" }}
              multiline
              value={description}
              onChange={handleDescription}
              error={DescriptionError}
              helperText={DescriptionError}
              size="medium"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mb: 2,
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            ></Typography>
            <Button
              variant="contained"
              style={{ backgroundColor: "#0099cc", width: "200px" }}
              disableElevation
            >
              SUBMIT
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ManageHolidayTab;
