import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteBrand } from "../axios";
import Swal from "sweetalert2";

export default function SellerBrandTable({
  SellerData,
  setSellerData,
  setRefresh,
}) {
  const handleDeleteBrand = async (id) => {
    try {
      const res = await DeleteBrand(SellerData._id, id);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Brand Deleted SuccessFully",
          showConfirmButton: true,
        });
        setRefresh((refresh) => !refresh);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Grid container spacing={2} justifyContent="center">
      {SellerData?.SellerBrand?.length !== 0 ? (
        <Grid item xs={12} lg={12} md={12} xl={12}>
          <TableContainer component={Paper} sx={{ width: "96.5%", ml: 3 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ background: "#eee" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "black",
                      borderRight: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "black",
                      borderRight: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  >
                    Brand Name
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "black",
                      borderRight: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  >
                    Document Type
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "black",
                      borderRight: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  >
                    Document
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      color: "black",
                      borderRight: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SellerData?.SellerBrand?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      {row.brandName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      {row.brandCertificate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      <Box sx={{ width: "60px" }}>
                        <img
                          src={row.brandCertificateFile}
                          alt={row.brandCertificate}
                          width="100%"
                          height="100%"
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      <Tooltip>
                        <IconButton onClick={() => handleDeleteBrand(row._id)}>
                          <DeleteIcon sx={{ color: "red" }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                border: "1px solid #ddd",
                p: 1,
                width: "100%",
                textAlign: "center",
                background: "#000",
                color: "white",
              }}
            >
              No records found
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
