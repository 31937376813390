import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { CKEditor } from "ckeditor4-react";
import React, { useEffect, useState } from "react";
import {
  getAllBrandAttributes,
  getAllColorAttributes,
  getAllCustomAttributes,
  getAllMainAttributes,
  getAllSizeAttributes,
  getAllSizeCharts,
  getProductTypeValues,
} from "../../axios";
import Swal from "sweetalert2";

const Attribute = ({
  selectedId,
  handleChange,
  checked,
  setChecked,
  Color = "Colors",
  Size = "Sizes",
  Fit,
}) => {
  const [data, setData] = useState({});
  const [CustomAttributedata, setCustomAttributeData] = useState([]);
  const [MainAttributedata, setMainAttributeData] = useState([]);
  const [customAttributes, setCustomAttributes] = useState([]);
  const [MainAttributes, setMainAttributes] = useState([]);
  const [colorAttributes, setColorAttributes] = useState([]);
  const [SizeAttributes, setSizeAttributes] = useState([]);
  const [SizeCharts, setSizeCharts] = useState([]);
  const [BrandAttributes, setBrandAttributes] = useState([]);
  const [Loading1, setLoading1] = useState(false);
  const [Loading2, setLoading2] = useState(false);
  const [Loading3, setLoading3] = useState(false);
  const [Loading4, setLoading4] = useState(false);
  const [Loading5, setLoading5] = useState(false);
  const [Loading6, setLoading6] = useState(false);
  const [Loading7, setLoading7] = useState(false);
  const [Loading8, setLoading8] = useState(false);

  console.log(checked);

  //   console.log(BrandAttributes);

  const handleGetAllProductTypesCategory = async () => {
    setLoading1(true);
    try {
      const res = await getProductTypeValues(selectedId);

      if (res) {
        setData(res.data);
        setCustomAttributeData(res.data.CustomAttributes);
        setMainAttributeData(res.data.MainAttributes);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };
  const handleGetAllCustomAttribues = async () => {
    setLoading2(true);
    try {
      const res = await getAllCustomAttributes();

      if (res.status === 200) {
        setCustomAttributes(res.data);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading2(false);
    }
  };

  // get handle All Main Attributes

  const handleGetAllMainAttribues = async () => {
    setLoading3(true);
    try {
      const res = await getAllMainAttributes();

      if (res.status === 200) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        setMainAttributes(res.data);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading3(false);
    }
  };
  const handleGetAllBrandAttribues = async () => {
    setLoading4(true);
    try {
      const res = await getAllBrandAttributes();

      if (res) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
        setBrandAttributes(res.data);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading4(false);
    }
  };
  const handleGetAllColorAttribues = async () => {
    setLoading5(true);
    try {
      const res = await getAllColorAttributes();
      if (res.status === 200) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
        setColorAttributes(res.data);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading5(false);
    }
  };
  const handleGetAllSizeAttribues = async () => {
    setLoading6(true);
    try {
      const res = await getAllSizeAttributes();
      if (res.status === 200) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
        setSizeAttributes(res.data);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading6(false);
    }
  };
  const handleGetAllSizeCharts = async () => {
    setLoading7(true);
    try {
      const res = await getAllSizeCharts();

      if (res.status === 200) {
        // setCustomAttributes(res.data);
        // console.log(res.data);
        // setMainAttributes(res.data);
        // console.log(res.data);
        setSizeCharts(res.data);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading7(false);
    }
  };

  const handleCheckedChange = (event, Colors) => {
    const { name, checked: isChecked } = event.target;
    setChecked((prevChecked) => {
      const prevColor = prevChecked?.[Color] || [];
      const newColor = isChecked
        ? [...prevColor, name]
        : prevColor.filter((color) => color !== name);
      return {
        ...prevChecked,
        [Color]: newColor,
      };
    });
  };
  const handleCheckedSizeChange = (event) => {
    const { name, checked: isChecked } = event.target;
    setChecked((prevChecked) => {
      const prevColor = prevChecked[Size] || [];
      const newColor = isChecked
        ? [...prevColor, name]
        : prevColor.filter((size) => size !== name);
      return {
        ...prevChecked,
        [Size]: newColor,
      };
    });
  };

  //   console.log(newData);

  const productData = [];
  customAttributes.forEach((item, i) => {
    const dataItem = CustomAttributedata.find((data, i) => data === item.name);
    if (dataItem) {
      const res = customAttributes.find((item) => item.name === dataItem);
      productData.push(res);
    }
  });
  const productMainData = [];
  MainAttributes.forEach((item, i) => {
    const dataItem = MainAttributedata.find(
      (data, i) => data === item.AttributeName
    );
    if (dataItem) {
      const res = MainAttributes.find(
        (item) => item.AttributeName === dataItem
      );
      productMainData.push(res);
    }
  });

  const ITEM_HEIGHT = 68;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const isMediumScreen = useMediaQuery("(min-width: 960px)");

  const handleEditorChange = (event, name) => {
    const parseddata = event.editor.getData();
    setChecked({ ...checked, [name]: parseddata });
  };

  //   console.log(productMainData);

  useEffect(() => {
    Promise.all([
      handleGetAllProductTypesCategory(),
      handleGetAllCustomAttribues(),
      handleGetAllMainAttribues(),
      handleGetAllBrandAttribues(),
      handleGetAllColorAttribues(),
      handleGetAllSizeAttribues(),
      handleGetAllSizeCharts(),
    ]);
  }, []);
  return (
    <div>
      <Box>
        <Card sx={{ border: "1px solid #dddddd", p: 2 }} elevation={0}>
          {!Loading1 &&
          !Loading2 &&
          !Loading3 &&
          !Loading4 &&
          !Loading5 &&
          !Loading6 &&
          !Loading7 &&
          !Loading8 ? (
            <>
              <Box>
                {productData.map((item) => {
                  // console.log("item", item);

                  // const name = item.name;

                  if (item.AttributType == "DropdownList") {
                    return (
                      <Box
                        sx={{
                          display: "flex",

                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "5px",
                          mb: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                          key={item.name}
                        >
                          {item.name} :{" "}
                        </Typography>
                        <Select
                          displayEmpty
                          sx={{ width: "300px" }}
                          value={checked?.[item.name]}
                          MenuProps={MenuProps}
                          name={item.name}
                          onChange={(e) =>
                            setChecked({
                              ...checked,
                              [item.name]: e.target.value,
                            })
                          }
                        >
                          <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
                          {item.initialValue.map((data) => (
                            <MenuItem value={data}>{data}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    );
                  }
                  if (item.AttributType == "TextArea") {
                    return (
                      <Box
                        sx={{
                          display: "flex",

                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "5px",
                          mb: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item.name} :{" "}
                        </Typography>
                        {/* <TextField
                      multiline
                      sx={{ width: "300px" }}
                      value={checked.item}
                      onChange={(e) =>
                        setChecked({ ...checked, [item.name]: e.target.value })
                      }
                      placeholder={item.name}
                    /> */}
                        <CKEditor
                          initData={checked[item.name]}
                          onChange={(event) =>
                            handleEditorChange(event, item.name)
                          }
                        />
                      </Box>
                    );
                  }
                  if (item.AttributType == "TextBox") {
                    return (
                      <Box
                        sx={{
                          display: "flex",

                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "5px",
                          mb: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item.name} :{" "}
                        </Typography>
                        <TextField
                          multiline
                          sx={{ width: "300px" }}
                          value={checked[item.name]}
                          onChange={(e) =>
                            setChecked({
                              ...checked,
                              [item.name]: e.target.value,
                            })
                          }
                          placeholder={item.name}
                        />
                      </Box>
                    );
                  }
                })}
              </Box>
              <Box sx={{ mt: 2 }}>
                {MainAttributedata.map((item, i) => {
                  if (item === "Brand") {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item} :{" "}
                        </Typography>
                        <Select
                          displayEmpty
                          sx={{ width: "300px" }}
                          MenuProps={MenuProps}
                          value={checked?.[item]}
                          onChange={(e) =>
                            setChecked({ ...checked, [item]: e.target.value })
                          }
                        >
                          <InputLabel sx={{ ml: 2 }}>Select Brand</InputLabel>
                          {BrandAttributes.map((item) => (
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    );
                  }
                  if (item === "Color") {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          // justifyContent: "space-between",
                          alignItems: "flex-start",
                          flexWrap: !isMediumScreen && "wrap",
                          gap: "5px",
                          mt: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item}:
                        </Typography>
                        <Grid container spacing={2}>
                          {colorAttributes?.map((item) => (
                            <Grid
                              item
                              key={item.name}
                              xs={6}
                              sm={4}
                              md={4}
                              lg={3}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  // minWidth: "200px",
                                }}
                              >
                                <Checkbox
                                  name={item?.name}
                                  checked={
                                    checked?.[Color]?.includes(item.name) ||
                                    false
                                  }
                                  onChange={handleCheckedChange}
                                />

                                <Typography sx={{ fontSize: "14px" }}>
                                  {item.name}
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    );
                  }
                  if (item === "Size") {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          // justifyContent: "space-between",
                          alignItems: "flex-start",
                          flexWrap: !isMediumScreen && "wrap",
                          gap: "5px",
                          mt: 4,
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item}:
                        </Typography>
                        <Box>
                          <Grid container spacing={2}>
                            {SizeAttributes.map((item) => (
                              <Grid item xs={12} lg={3} sm={4} md={4}>
                                <Box
                                  key={item.name} // Add a unique key for each item
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    name={item.name}
                                    checked={
                                      checked[Size]?.includes(item.name) ||
                                      false
                                    }
                                    onChange={handleCheckedSizeChange}
                                  />

                                  <Typography sx={{ fontSize: "14px" }}>
                                    {item.name}
                                  </Typography>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </Box>
                    );
                  }
                  if (item === "Size Chart") {
                    return (
                      <Box
                        sx={{
                          display: "flex",

                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "5px",
                          mt: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            minWidth: "300px",
                          }}
                        >
                          {item} :{" "}
                        </Typography>
                        <Select
                          displayEmpty
                          sx={{ width: "300px" }}
                          value={checked.item}
                          MenuProps={MenuProps}
                          name={item.name}
                          onChange={(e) =>
                            setChecked({ ...checked, [item]: e.target.value })
                          }
                        >
                          <InputLabel sx={{ ml: 2 }}>Select </InputLabel>
                          {SizeCharts.map((item) => (
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                    );
                  }
                })}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: "5px",
                    mt: 3,
                    mb: 5,
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "300px",
                    }}
                  ></Typography>
                  <Button
                    variant="contained"
                    onClick={() => handleChange(0, 4)}
                    sx={{ width: "200px" }}
                  >
                    NEXT
                  </Button>
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40vh",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Card>
      </Box>
    </div>
  );
};

export default Attribute;
