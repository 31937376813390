import axios from "axios";
import { BASE_URL_Seller, BASE_URL_Admin, BASE_URL_Buyer } from "./BaseUrl";
import { useSelector } from "react-redux";

axios.defaults.baseURL = BASE_URL_Seller;

export const getSellerById = async (id) => {
  try {
    const res = await axios.get(`/getSellerById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getLogoAndDetails = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getLogoDetails`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getLogoAndDetailsById = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getLogoDetailsById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getMarketPlaceProfile = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getAllProfilers`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getMarketPlaceProfileById = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getStoreProfile/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllStaticPages = async () => {
  try {
    const res = await axios.get(
      `${BASE_URL_Admin}/handleGetAllSellerStaticPages`
    );
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const DeleteBrand = async (id, BrandId) => {
  try {
    const res = await axios.put(`/DeleteParticularBrand/${id}`, {
      BrandId: BrandId,
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleSubmitBrandData = async (id) => {
  try {
    const res = await axios.put(`/handleSubmitBrandData/${id}`, {
      sellerBrandDocumentsUpdated: true,
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleForgotPassword = async (email) => {
  try {
    const res = await axios.post("/forgotPasswordSeller", {
      email: email,
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const ResetSellerPassword = async (id, password, rePassword) => {
  try {
    const res = await axios.put(`/handleResetPassword/${id}`, {
      password,
      rePassword,
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// Seller All Orders

export const handleGetAllOrdersBySeller = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL_Buyer}/getAllOrderBySeller/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleGetAllProductsBySeller = async (config) => {
  try {
    const res = await axios.get(
      `${BASE_URL_Seller}/Products/all-Products-by-me`,
      config
    );
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleGetAllSellerNavigations = async () => {
  try {
    const res = await axios.get(
      `${BASE_URL_Admin}/handleGetAllSellerNavigations`
    );
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// Seller Proile
export const handleGetSellerById = async (id) => {
  try {
    const res = await axios.get(`/getSellerById/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const EditSellerData = async (
  id,
  mobileNumber,
  companyDisplayName,
  warehouseAddress,
  warehouselandmark,
  POBox,
  sellerBankAccountName,
  sellerAccountNumber,
  sellerBankName,
  sellerIBAN,
  sellerSWIFTCode
) => {
  try {
    const res = await axios.put(`${BASE_URL_Admin}/EditSellerData/${id}`, {
      mobileNumber,
      companyDisplayName,
      warehouseAddress,
      warehouselandmark,
      POBox,
      sellerBankAccountName,
      sellerAccountNumber,
      sellerBankName,

      sellerIBAN,
      sellerSWIFTCode,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleGetAllBrand = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getAllBrandAttributes`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleGetAllCategories = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getAllCategoryAssociations`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const ChangePasswordForSeller = async (
  id,
  password,
  confirmPassword
) => {
  try {
    const res = await axios.put(`/ChangePasswordForSeller/${id}`, {
      password,
      confirmPassword,
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};
export const StoreActiveForSeller = async (id, sellerStoreActive) => {
  try {
    const res = await axios.put(`/StoreActiveForSeller/${id}`, {
      sellerStoreActive,
    });
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// seller All products

export const AllProductssByMe = async (config) => {
  try {
    const res = await axios.get(`/Products/all-Products-by-me`, config);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// stock overview update

export const updateStockOverView = async (data) => {
  try {
    const res = await axios.post("/Products/stockOverview", {
      data,
    });
    if (res) {
      return res.data.message;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateColorImages = async (id, formData) => {
  try {
    const res = await axios.put(`/Products/uploadColorImages/${id}`, {
      formData,
    });
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

/// return policy

export const getAllPolicy = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getAllPolicy`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

// seller addBrand

export const ManageProfileAddBrand = async (id, formData, config) => {
  try {
    const res = await axios.put(
      `/manageBrand/${id}`,

      formData,
      config
    );
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const storeCalculation = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/StoreCalculationData`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// Seller Query

export const createSellerQuery = async (
  orderNo,
  Description,
  createdBy,
  orderStatus,
  orderDate
) => {
  try {
    const res = await axios.post("/NewSellerQuery", {
      orderNo,
      Description,
      createdBy,
      orderStatus,
      orderDate,
    });
    if (res.status === 200) {
      return res.data;
    } else {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getQueriesforSellerBYId = async (id) => {
  try {
    const res = await axios.get(`/getQueriesforSellerBYId/${id}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

//Questions  by Buyer

export const QuestionByBuyer = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL_Buyer}/QuestionFOrSeller/${id}`);

    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const SolutionforQuestion = async (
  id,
  Answer,
  AnsweredBy,
  AnsweredBySeller
) => {
  try {
    const res = await axios.put(
      `${BASE_URL_Buyer}/SolutionForQuestionBySeller/${id}`,
      {
        Answer,
        AnsweredBy,
        AnsweredBySeller,
      }
    );
    if (res.status === 200) {
      return res.data.message;
    }
  } catch (error) {
    console.log(error);
  }
};

// seller login

export const SellerLogin = async (email, password) => {
  try {
    const res = await axios.post(`${BASE_URL_Seller}/sellerLogin`, {
      email,
      password,
    });

    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// seller Register

export const SellerRegister = async (
  fullName,
  email,
  password,
  confirmPassword,
  mobileNumber
) => {
  try {
    const res = await axios.post(`${BASE_URL_Seller}/Sellerregister`, {
      fullName,
      email,
      password,
      confirmPassword,
      mobileNumber,
    });

    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// seller Information

export const SellerInformationUpdate = async (
  id,
  personFullName,
  personTitle,
  personMobileNumber,
  personEmailAddress,
  companyName,
  companyDisplayName,
  companyAddress,
  city,
  state,
  country,
  POBox,
  certificationAwards,
  tradeLicenseNumber,
  VAT,
  warehouseShippingPersonFullName,
  warehouseAddress,
  warehouselandmark,
  warehouseCity,
  warehouseState,
  wareshouseCountry,
  warehousePersonTitle,
  warehousePersonPhoneNumber,
  warehousePersonEmail,
  sellerBankAccountName,
  sellerAccountNumber,
  sellerBranch,
  sellerIfscCode,
  sellerIBAN,
  sellerSWIFTCode,
  sellerBankName,
  sellerFinanceContactPersonName,
  SellerFinanceContactEmailID,
  sellerInformationUpdated
) => {
  try {
    const res = await axios.put(`${BASE_URL_Seller}/SellerInformation/${id}`, {
      personFullName,
      personTitle,
      personMobileNumber,
      personEmailAddress,
      companyName,
      companyDisplayName,
      companyAddress,
      city,
      state,
      country,
      POBox,
      certificationAwards,
      tradeLicenseNumber,
      VAT,
      warehouseShippingPersonFullName,
      warehouseAddress,
      warehouselandmark,
      warehouseCity,
      warehouseState,
      wareshouseCountry,
      warehousePersonTitle,
      warehousePersonPhoneNumber,
      warehousePersonEmail,
      sellerBankAccountName,
      sellerAccountNumber,
      sellerBranch,
      sellerIfscCode,
      sellerIBAN,
      sellerSWIFTCode,
      sellerBankName,
      sellerFinanceContactPersonName,
      SellerFinanceContactEmailID,
      sellerInformationUpdated,
    });

    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// seller Documents

export const SellerDocumentsUpdate = async (
  id,
  companyTradLicenseUrl,
  VATCertificateURL,
  sellerDocumentsUpdated
) => {
  try {
    const res = await axios.put(`${BASE_URL_Seller}/SellerDocuments/${id}`, {
      companyTradLicenseUrl,
      VATCertificateURL,
      sellerDocumentsUpdated,
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// Seller Add brand

export const SellerAddBrandUpdate = async (
  id,
  brandName,
  brandCertificate,
  brandCertificateFile,
  chooseCategory
) => {
  try {
    const res = await axios.put(`${BASE_URL_Seller}/SelleraddBrand/${id}`, {
      brandName,
      brandCertificate,
      brandCertificateFile,
      chooseCategory,
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};

// ----------------------------------------------- From Admin panel --------------------------------------------------

// get All Brands from admin panel

export const getAllBrands = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getAllBrandAttributes`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get All Categories from admin panel

export const getAllRootCategories = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getAllSubCategoriesForRoot`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all product Types from admin panel

export const getAllProductTypes = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getAllProductTypesValues`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get single product by Id from admin panel

export const getSingleProductById = async (id) => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/get-single-product/${id}`);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all product type values

export const getProductTypeValues = async (id) => {
  try {
    const res = await axios.get(
      `${BASE_URL_Admin}/getProductTypeValuesById/${id}`
    );
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get All custom attributes

export const getAllCustomAttributes = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getAllCustomAttributes`);

    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all main product attributes

export const getAllMainAttributes = async () => {
  try {
    const res = await axios.get(
      `${BASE_URL_Admin}/getAllMainProductAttributes`
    );

    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all Brand attributes

export const getAllBrandAttributes = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getAllBrandAttributes`);

    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all Color attributes

export const getAllColorAttributes = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getAllColorAttributes`);

    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all Size attributes

export const getAllSizeAttributes = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getAllSizeAttributes`);

    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// get all Size charts

export const getAllSizeCharts = async () => {
  try {
    const res = await axios.get(`${BASE_URL_Admin}/getAllSizeCharts`);

    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

// upload image and pdf while creating seller product

export const handleUploadImageAndPDF = async (formData, config) => {
  try {
    const res = await axios.post(
      `${BASE_URL_Seller}/Products/Upload-image-pdf`,
      formData,
      config
    );

    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};
