import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

import Swal from "sweetalert2";
import { getSingleProductById } from "../axios";

const BulkUpload = () => {
  const { token } = useSelector((data) => data.auth);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [colors, setColors] = useState([]);
  const [Sizes, setSizes] = useState([]);
  const [NewSkuCode, setNewSkuCode] = useState();
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [ProductId, setProductId] = useState([]);

  const navigate = useNavigate();

  console.log(data);

  const { user } = useSelector((state) => state.auth);

  const uploadedIds = JSON.parse(localStorage.getItem("uploadedIds"));
  const SKUCodes = JSON.parse(localStorage.getItem("SKUCode"));

  const getSingleListingData = async () => {
    try {
      const dataPromises = uploadedIds.map(async (item) => {
        const res = await getSingleProductById(item);
        return res.data;
      });

      const allData = await Promise.all(dataPromises);

      const allColors = allData.reduce((colors, data) => {
        return [...colors, ...data.Colors];
      }, []);

      const allSizes = allData.reduce((sizes, data) => {
        return [...sizes, ...data.Sizes];
      }, []);
      const allProductIds = allData.reduce((producIds, data) => {
        return [...producIds, ...data._id];
      }, []);

      // Do something with the obtained colors and sizes
      setColors(allColors);
      setSizes(allSizes);
      setProductId(allProductIds);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleListingData();
  }, []);

  useEffect(() => {
    if (Array.isArray(responseData) && responseData.length > 0) {
      const innerArray = responseData[0]; // Access the inner array

      console.log(innerArray);

      const uploadedIds = innerArray.map((data) => data._id);
      const SKUCode = innerArray.map((data) => data.SKUCode);

      localStorage.setItem("uploadedIds", JSON.stringify(uploadedIds));
      localStorage.setItem("SKUCode", JSON.stringify(SKUCode));
    }
  }, [responseData]);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleFileSelect1 = (event) => {
    setSelectedFile1(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setMessage("Please select a file");
      return;
    }

    try {
      const reader = new FileReader();

      const readFile = () => {
        return new Promise((resolve, reject) => {
          reader.onload = (e) => {
            resolve(new Uint8Array(e.target.result));
          };
          reader.onerror = (e) => {
            reject(e);
          };
        });
      };

      reader.readAsArrayBuffer(selectedFile);
      const data = await readFile();

      const workbook = XLSX.read(data, { type: "array" });

      // Access the first sheet in the workbook
      const sheet = workbook.Sheets[workbook.SheetNames[0]];

      // Convert the sheet data to JSON format
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const [titles, ...values] = jsonData;

      const generateUniqueUrl = (title) => {
        const randomNumber = Math.floor(100000 + Math.random() * 900000);
        const formattedTitle = title.replace(/\s+/g, "-");
        return `${formattedTitle}-${randomNumber}`;
      };

      // Map the data to the desired format
      const mappedData = values.map((item) => {
        const mappedItem = {
          data: {
            CodAvailable: item[titles.indexOf("COD")] || "No",
            Fragile: item[titles.indexOf("Fragile")] || "No",
            PreBook: item[titles.indexOf("PreBook")] || "No",
            replacement: item[titles.indexOf("replacement")] || "No",
            refund: item[titles.indexOf("refund")] || "No",
            Colors: item[titles.indexOf("Colors")]
              ? item[titles.indexOf("Colors")].split(",")
              : [],
            Sizes: item[titles.indexOf("Sizes")]
              ? item[titles.indexOf("Sizes")].split(",")
              : [],
            ["Bank Offers"]: item[titles.indexOf("Bank Offers")],
            ["Memory Storage"]: item[titles.indexOf("Memory Storage")],
            ["Memory Size"]: item[titles.indexOf("Memory Size")],
          },
          Title: item[titles.indexOf("Title")],
          SKUCode: item[titles.indexOf("SKU code")],
          BrandName: item[titles.indexOf("Brand")],
          UniqueUrl: generateUniqueUrl(item[titles.indexOf("Title")]), // Replace with your logic for generating unique URL
          Status: false,
          ComboProduct: false,
          Category: item[titles.indexOf("Category")],
          mainCategory: item[titles.indexOf("mainCategory")],
          subCategory: item[titles.indexOf("subCategory")],
          ProductType: item[titles.indexOf("ProductType")],
          HSNCode: item[titles.indexOf("HSN No")],
          Tax: item[titles.indexOf("GST(In Percentage)")],
          Description: item[titles.indexOf("Description")],
          SkuCode: item[titles.indexOf("SkuCode")],
          searchTitle: item[titles.indexOf("searchTitle")],
          searchKeywords: item[titles.indexOf("searchKeywords")]
            ? item[titles.indexOf("searchKeywords")].split(",")
            : [],
          searchDescription: item[titles.indexOf("searchDescription")],
          shipment: item[titles.indexOf("shipment")],
          returnPolicy: item[titles.indexOf("returnPolicy")],
          returnPolicyDescription:
            item[titles.indexOf("returnPolicyDescription")],
          ShippingWeight: item[titles.indexOf("Shipping Weight(In grams)")],
          metaKeyWords: [],
          metaDescription: "",
          popularClicks: "",
          TrendingStyles: false,
          latest: false,
          Stock: false,
          sellOnline: false,
          mrp: [],
          IGST: "",
          CGST: "",
          SGST: "",
          taxonProduct: "",
          commission: "",
          addstock: [],
          removeStock: [],
          makeDefault: [],
          hide: false,
          rejected: false,
          active: false,
          ProductImages: {},
          videoUrls: [],
          sellerPayout: [],
          MainImage: "",
          PrdoductDetailsDoc: "",
          createdBy: user._id, // Replace with the appropriate user information
          uniqueId: "",
          Colors: item[titles.indexOf("Colors")]
            ? item[titles.indexOf("Colors")].split(",")
            : "Default",
          Sizes: item[titles.indexOf("Sizes")]
            ? item[titles.indexOf("Sizes")].split(",")
            : "Default",
        };

        return mappedItem;
      });

      console.log(mappedData);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        "https://rccore-backend.cstechns.com/api/seller/Products/bulkUpload",
        {
          mappedData,
        },
        config
      );

      if (response) {
        console.log(response.data);
        if (Array.isArray(response)) {
          // Handle multiple responses
          setResponseData(response.data);
        } else {
          // Handle single response
          setResponseData([response.data]);
        }
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Product's Updated Successfully",
          showConfirmButton: true,
        });
      } // Output the response data to the console
    } catch (error) {
      // Handle any error that occurred during the request
      console.error(error);
    }
  };

  const handleUpload1 = async () => {
    if (!selectedFile1) {
      setMessage("Please select a file");
      return;
    }

    const reader = new FileReader();

    const readFileAsync = (file) => {
      return new Promise((resolve, reject) => {
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (e) => reject(e);
        reader.readAsArrayBuffer(file);
      });
    };

    try {
      const fileContent = await readFileAsync(selectedFile1);
      const data = new Uint8Array(fileContent);
      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const [titles, ...values] = jsonData;

      const skuCodeIndex = titles.indexOf("SKU code");
      const colorIndex = titles.indexOf("Color");
      const sizeIndex = titles.indexOf("Size");
      const mrpIndex = titles.indexOf("mrp");
      const offerPriceIndex = titles.indexOf("Offer Price");
      const stockIndex = titles.indexOf("stock");
      const defaultSetIndex = titles.indexOf("Default Set");

      const mappedData = {};
      let showAlert = false;

      values.forEach((item) => {
        console.log(item);
        const skuCode = item[skuCodeIndex];
        const color = item[colorIndex];
        const size = item[sizeIndex];
        const mrp = item[mrpIndex];
        const offerPrice = item[offerPriceIndex];
        const stock = item[stockIndex];
        const defaultSet = item[defaultSetIndex];

        if (colors.includes(color) && Sizes.includes(size)) {
          const key = `${color}-${size}`;

          if (!mappedData[skuCode]) {
            mappedData[skuCode] = {
              SKUCode: skuCode,
              mrp: {},
              addStock: [],
              images: {},
              defaultSet: [],
              id: [],
            };
          }

          const skuData = mappedData[skuCode];

          if (!skuData.mrp[key]) {
            skuData.mrp[key] = {
              mrp: mrp,
              offerPrice: offerPrice,
            };
          }

          if (mrp < offerPrice) {
            showAlert = true;
          }

          skuData.addStock.push(stock);

          if (!skuData.images[color]) {
            const images = [];
            for (let i = 1; i <= 9; i++) {
              const imageName = item[titles.indexOf(`Color Image ${i}`)];
              if (imageName) {
                images.push(imageName);
              }
            }
            skuData.images[color] = images;
          }

          skuData.defaultSet.push(defaultSet);
          skuData.id.push(uploadedIds);
        }
      });

      if (showAlert) {
        alert(
          "Error: Offer Price is greater than the MRP for one or more items."
        );
        return;
      }

      const mappedItems = Object.values(mappedData);

      console.log(mappedItems);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `https://rccore-backend.cstechns.com/api/seller/Products/BulkUploadPriceData`,
        {
          mappedItems,
          uploadedIds,
          SKUCodes,
        },
        config
      );

      if (response) {
        console.log(response.data);

        Swal.fire({
          position: "center",
          icon: "success",
          text: "Product's Updated Successfully",
          showConfirmButton: true,
        });
      }

      // Rest of the code...
    } catch (error) {
      console.error("Error while processing the file:", error);
    }
  };

  const UploadedIds = JSON.parse(localStorage.getItem("uploadedIds"));

  const handleDownload = () => {
    fetch(
      "https://rccore-backend.cstechns.com/api/seller/Products/handleDownloadExistingCSVSheet"
    )
      .then((response) => response.blob())
      .then((blob) => {
        console.log(blob);
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Multibulkuploadproductsheet.csv";
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      })
      .catch((error) => {
        console.error("Error:", error);
        Swal.fire({
          position: "center",
          icon: "error",
          text: "Error downloading file",
          showConfirmButton: true,
        });
      });
  };

  return (
    <div style={{ marginTop: "80px" }}>
      <Box sx={{ mb: 3 }}>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
        >
          Bulk Upload
        </Typography>
        <Typography
          sx={{ border: "2px solid #0099cc", width: "60px", margin: "auto" }}
        ></Typography>
      </Box>
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12} lg={4}>
          <Box
            sx={{
              border: "1px solid #ddd",
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Product (Comma delimited)(*.csv) File
              </Typography>
              <Grid item lg={12} xs={12}>
                <TextField
                  type="file"
                  inputProps={{ accept: ".csv" }}
                  sx={{ mt: 2 }}
                  fullWidth
                  onChange={handleFileSelect}
                />
              </Grid>
              <Button
                variant="contained"
                style={{ backgroundColor: "#0099CC" }}
                sx={{ mt: 2 }}
                onClick={handleUpload}
              >
                SUBMIT
              </Button>
              <Link
                onClick={handleDownload}
                style={{ marginTop: "20px", textDecoration: "none" }}
              >
                Click Here To Download Format{" "}
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            sx={{
              border: "1px solid #ddd",
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Price (Comma delimited)(*.csv) File
              </Typography>
              <Grid item lg={12} xs={12}>
                <TextField
                  type="file"
                  inputProps={{ accept: ".csv" }}
                  sx={{ mt: 2 }}
                  fullWidth
                  onChange={handleFileSelect1}
                />
              </Grid>
              <Button
                variant="contained"
                style={{ backgroundColor: "#0099CC" }}
                sx={{ mt: 2 }}
                onClick={handleUpload1}
              >
                SUBMIT
              </Button>
              <Link
                onClick={handleDownload}
                style={{ marginTop: "20px", textDecoration: "none" }}
              >
                Click Here To Download Format{" "}
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box
            sx={{
              border: "1px solid #ddd",
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Upload Multiple Image
              </Typography>
              <Button
                variant="contained"
                style={{ backgroundColor: "#000" }}
                sx={{ mt: 2 }}
                onClick={() =>
                  navigate("/UploadProductImages", {
                    state: { UploadedIds: UploadedIds },
                  })
                }
              >
                Upload Images
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default BulkUpload;
