import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { updateStockOverView } from "../../axios";
import Swal from "sweetalert2";

export default function StockTable({ data, setData, setRefresh, Loading }) {
  const [addstock1, setaddStock1] = useState(0);
  const [removestock1, setremovestock1] = useState(0);
  const [newData, setNewData] = useState("");
  const [Loading1, setLoading1] = useState(false);
  const [removeStockErrors, setRemoveStockErrors] = useState([]);

  const handleUpdateStock = async () => {
    setLoading1(true);
    try {
      if (removeStockErrors.length > 0) {
        return;
      }
      const res = await updateStockOverView(data);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res,
          showConfirmButton: true,
        });
        setNewData(res);
        setRefresh((refresh) => !refresh);
        setaddStock1(0);
        setremovestock1(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  const exportToExcel = () => {
    let csvContent =
      "Image,Product SKU Code,Brand Name,Product Details,Inventory Against,Price,Offer Price,Avail. Stock,Stock Add,Stock Remove\n";

    data.forEach((item) => {
      item?.data?.Colors?.forEach((color, colorIndex) => {
        item?.data?.Sizes?.forEach((size, sizeIndex) => {
          const index = colorIndex * item?.data?.Sizes?.length + sizeIndex;
          const productDetails =
            `Name: ${item.Title}, Color: ${color}, Size: ${size}`.replace(
              /,/g,
              ";"
            );
          const inventoryAgainst =
            `SellerId: ${item?.createdBy?.sellerId}, SkuCode: ${item?.SKUCode}, Color: ${color}, Size: ${size}`.replace(
              /,/g,
              ";"
            );

          const rowData = [
            item.MainImage,
            item.SKUCode,
            item.BrandName,
            productDetails,
            inventoryAgainst,
            item?.mrp?.[0]?.[`${color}-${size}`]?.mrp,
            item?.mrp?.[0]?.[`${color}-${size}`]?.offerPrice,
            item?.addstock[index],
            0,
            0,
          ];

          csvContent += rowData.map((value) => `"${value}"`).join(",") + "\n";
        });
      });
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", downloadUrl);
    link.setAttribute("download", "stockOverview.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
    {
      data.length > 0 ?(
        <Grid container spacing={2} sx={{ px: 3 }}>
      <Grid item xs={12} lg={12} xl={12} md={12}>
        <>
          {!Loading ? (
            <>
              <Grid item xs={12} lg={12} xl={12} md={4} justifyContent="end">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    gap: "10px",

                    mt: 3,
                    mb: 3,
                  }}
                >
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handleUpdateStock}
                    disabled={Loading1}
                  >
                    {Loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={exportToExcel}
                  >
                    EXPORT TO EXCEL
                  </Button>
                </Box>
              </Grid>
              <TableContainer
                component={Paper}
                sx={{ width: "100%", border: "1px solid #ddd" }}
                elevation={0}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead sx={{ background: "#eee" }}>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                        }}
                      >
                        Image
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                        }}
                      >
                        Product SKU Code
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                        }}
                      >
                        Brand Name
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                        }}
                      >
                        Product Details
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                        }}
                      >
                        Inventory Against
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                        }}
                      >
                        Price{" "}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                        }}
                      >
                        Offer Price
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                        }}
                      >
                        Avail. Stock
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                        }}
                      >
                        Stock Add
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          borderRight: "1px solid #ddd",
                          fontWeight: "bold",
                        }}
                      >
                        Stock Remove
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item, index1) =>
                      item?.data?.Colors?.map((color, colorIndex) =>
                        item?.data?.Sizes?.map((size, sizeIndex) => {
                          const index =
                            colorIndex * item?.data?.Sizes?.length + sizeIndex;

                          return (
                            <TableRow key={index1}>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ borderRight: "1px solid #ddd" }}
                              >
                                <Box sx={{ width: "60px" }}>
                                  <img
                                    src={item.MainImage}
                                    alt="Product Image1"
                                    width="100%"
                                    height="100%"
                                  />
                                </Box>
                              </TableCell>
                              <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                                {item.SKUCode}
                              </TableCell>
                              <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                                {item.BrandName}
                              </TableCell>
                              <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "60px",
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Name:
                                    </Typography>
                                    <Typography sx={{ fontSize: "13px" }}>
                                      {item.Title}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "60px",
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Color:
                                    </Typography>
                                    <Typography sx={{ fontSize: "13px" }}>
                                      {color}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "60px",
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Size:
                                    </Typography>
                                    <Typography sx={{ fontSize: "13px" }}>
                                      {size}
                                    </Typography>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ border: "1px solid #ddd" }}>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      minWidth: "60px",
                                      fontWeight: "bold",
                                      fontSize: "13px",
                                    }}
                                  >
                                    SellerId:
                                  </Typography>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    {item?.createdBy?.sellerId}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      minWidth: "60px",
                                      fontWeight: "bold",
                                      fontSize: "13px",
                                    }}
                                  >
                                    SkuCode:
                                  </Typography>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    {item?.SKUCode}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      minWidth: "60px",
                                      fontWeight: "bold",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Color:
                                  </Typography>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    {color}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      minWidth: "60px",
                                      fontWeight: "bold",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Size:
                                  </Typography>
                                  <Typography sx={{ fontSize: "13px" }}>
                                    {size}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ border: "1px solid #ddd" }}>
                                {item?.mrp?.[0]?.[`${color}-${size}`]?.mrp}
                              </TableCell>
                              <TableCell sx={{ border: "1px solid #ddd" }}>
                                {
                                  item?.mrp?.[0]?.[`${color}-${size}`]
                                    ?.offerPrice
                                }
                              </TableCell>
                              <TableCell sx={{ border: "1px solid #ddd" }}>
                                {item?.addstock[index]}
                              </TableCell>
                              <TableCell sx={{ border: "1px solid #ddd" }}>
                                <Box>
                                  <TextField
                                    size="small"
                                    defaultValue={addstock1}
                                    sx={{ width: "70px" }}
                                    onChange={(e) => {
                                      setData((prevData) => {
                                        if (!Array.isArray(prevData)) {
                                          console.error("data is not an array");
                                          return prevData;
                                        }

                                        const newData = prevData.map(
                                          (item, i) => {
                                            if (i === index1) {
                                              const addstock = [
                                                ...(item?.addstock ?? []),
                                              ];

                                              addstock[index] = Number(
                                                e.target.value
                                              );
                                              return { ...item, addstock };
                                            }
                                            return item;
                                          }
                                        );

                                        return newData;
                                      });
                                    }}
                                  />
                                </Box>
                              </TableCell>

                              <TableCell sx={{ border: "1px solid #ddd" }}>
                                <Box>
                                  <TextField
                                    size="small"
                                    defaultValue={0}
                                    sx={{ width: "70px" }}
                                    error={removeStockErrors.includes(
                                      `${index1}-${color}-${size}`
                                    )} // Updated error prop
                                    onChange={(e) => {
                                      const value = Number(e.target.value);

                                      setData((prevData) => {
                                        if (!Array.isArray(prevData)) {
                                          console.error("data is not an array");
                                          return prevData;
                                        }

                                        const newData = prevData.map(
                                          (item, i) => {
                                            if (i === index1) {
                                              const removeStock = [
                                                ...(item?.removeStock ?? []),
                                              ];

                                              if (
                                                value > item?.addstock?.[index]
                                              ) {
                                                const errorKey = `${index1}-${color}-${size}`;

                                                if (
                                                  !removeStockErrors.includes(
                                                    errorKey
                                                  )
                                                ) {
                                                  setRemoveStockErrors(
                                                    (prevErrors) => [
                                                      ...prevErrors,
                                                      errorKey,
                                                    ]
                                                  );
                                                }
                                              } else {
                                                removeStock[index] = value;

                                                setRemoveStockErrors(
                                                  (prevErrors) =>
                                                    prevErrors.filter(
                                                      (errorKey) =>
                                                        errorKey !==
                                                        `${index1}-${color}-${size}`
                                                    )
                                                );
                                              }

                                              return { ...item, removeStock };
                                            }
                                            return item;
                                          }
                                        );

                                        return newData;
                                      });

                                      setremovestock1(0);
                                    }}
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </>
      </Grid>
    </Grid>
      ):(
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",height:"90vh"}}>
          <Typography sx={{border:"1px solid #ddd",p:2,width:"300px",textAlign:"center",background:"#f9f9f9"}}>No records found</Typography>
        </Box>
      )
    }
    </>
  );
}
