const navigations1 = [
  {
    name: "HOME",
  },
  {
    name: "ABOUT US",
  },
  {
    name: "FAQ'S",
  },
  {
    name: "TERMS OF USE",
  },
  {
    name: "SELLER POLICY",
  },
  {
    name: "CONTACT US",
  },
  {
    name: "COMMISSION PAYOUT",
  },
];

export default navigations1;
