import { Box, Typography } from "@mui/material";
import React from "react";
import ManageProfileTabs from "./ManageProfileTabs";
import { handleGetSellerById } from "../../axios";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

const ManageProfile = () => {
  const { user, token } = useSelector((state) => state.auth);
  const [data, setData] = useState({});
  const [Loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleGetSellerById1 = async () => {
    setLoading(true);
    try {
      const res = await handleGetSellerById(user._id);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSellerById1();
  }, [refresh]);
  return (
    <div style={{ marginTop: "80px" }}>
      <Box>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bolder",
            textAlign: "center",
            mt: 3,
          }}
        >
          MANAGE PROFILE
        </Typography>
        <Typography
          style={{ margin: "auto", border: "2px solid #0099cc", width: "60px" }}
        ></Typography>
      </Box>
      <Box sx={{ mt: 5 }}>
        <ManageProfileTabs
          data={data}
          Loading={Loading}
          setRefresh={setRefresh}
          setData={setData}
        />
      </Box>
    </div>
  );
};

export default ManageProfile;
