// store.js

import { composeWithDevTools } from "@redux-devtools/extension";
import { createStore, combineReducers } from "redux";
import authReducer from "./reducers/AuthReducer";

const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const rootReducer = combineReducers({
  auth: authReducer,
});

const store = createStore(
  rootReducer,
  loadFromLocalStorage(),
  composeWithDevTools()
);

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

export default store;
