import {
  Box,
  Card,
  CircularProgress,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import {
  handleGetAllOrdersBySeller,
  handleGetAllProductsBySeller,
  handleGetSellerById,
} from "../axios";
import MyOrderStatusDashBoard from "./MyOrderStatusDashBoard";
import PendingOrderListDashboard from "./PendingOrderListDashboard";
import DashboardPieChart1 from "./DashboardPieChart1";

const Dashboard = ({ theme, isMediumScreen }) => {
  const { user, token } = useSelector((state) => state.auth);
  const [data, setData] = useState({});
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [Loading2, setLoading2] = useState(false);
  const [MyOrders, setMyOrders] = useState([]);
  const [AllProducts, setAllProducts] = useState([]);

  const getSellerById = async () => {
    setLoading(true);
    try {
      const res = await handleGetSellerById(user._id);
      console.log(res);
      if (res) {
        // console.log(res);
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetAllOrderForSellers = async () => {
    setLoading1(true);
    try {
      const res = await handleGetAllOrdersBySeller(user._id);
      if (res) {
        setMyOrders(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  const handleGetAllProducts = async () => {
    setLoading2(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await handleGetAllProductsBySeller(config);
      if (res) {
        setAllProducts(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading2(false);
    }
  };

  const Pending = MyOrders.filter((item) => item.orderStatus === "pending");
  const Accepted = MyOrders.filter((item) => item.orderStatus === "accepted");
  const ReadyToShip = MyOrders.filter(
    (item) => item.orderStatus === "Ready To Ship"
  );
  const Shipped = MyOrders.filter((item) => item.orderStatus === "Shipped");
  const Delivered = MyOrders.filter((item) => item.orderStatus === "Delivered");
  const SellerCancelled = MyOrders.filter(
    (item) =>
      item.orderStatus === "cancelled" && item.orderSellerCancelled === true
  );
  const BuyerCancelled = MyOrders.filter(
    (item) =>
      item.orderStatus === "cancelled" && item.orderBuyerCancelled === true
  );
  const AdminCancelled = MyOrders.filter(
    (item) =>
      item.orderStatus === "cancelled" && item.orderAdminCancelled === true
  );
  // console.log(Pending);

  useEffect(() => {
    Promise.all([
      getSellerById(),
      handleGetAllOrderForSellers(),
      handleGetAllProducts(),
    ]);
  }, []);

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Box style={{ marginTop: "80px" }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bolder",
            textAlign: "center",
            mt: 3,
          }}
        >
          Dashboard
        </Typography>
        <Typography
          style={{ margin: "auto", border: "2px solid #0099cc", width: "60px" }}
        ></Typography>
        {!Loading && !Loading1 && !Loading2 ? (
          <>
            <Box>
              <Box sx={{ mt: 3 }}>
                <Typography
                  sx={{
                    fontSize: "17px",

                    textAlign: isMediumScreen ? "center" : "start",
                    ml: !isMediumScreen && 2,
                  }}
                >
                  Hi <span style={{ color: "#0099cc" }}>{user.fullName}</span>{" "}
                  Welcome to your Account .
                </Typography>
                <Typography
                  sx={{
                    fontSize: "17px",

                    mt: 1.5,
                    textAlign: isMediumScreen ? "center" : "start",
                    ml: !isMediumScreen && 2,
                  }}
                >
                  Your Unique Code is :{" "}
                  <span style={{ color: "#0099cc" }}>{data?.sellerId}</span>
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography
                sx={{
                  fontSize: "17px",

                  textAlign: "center",
                  mb: 2,
                }}
              >
                You have low stocks for products. To manage stocks please{" "}
                <Link
                  style={{ color: "#0099cc", textDecoration: "none" }}
                  to="/Inventory-manage"
                >
                  Click Here
                </Link>
              </Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2} sx={{ px: 2 }}>
                <Grid item xs={12} sm={6} lg={3} xl={3}>
                  <Box sx={{ border: "2px solid #dddddd" }}>
                    <Box sx={{ height: "450px" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#fff",
                          textAlign: "center",
                          background: "#0099cc",
                          p: 1,
                        }}
                      >
                        MANAGE
                      </Typography>
                      <Box
                        sx={{
                          p: 1,
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Link className="link" to="/manageSeller-profile">
                          Your Profile
                        </Link>
                        <Link to="/my-product-list" className="link">
                          Your Products
                        </Link>
                        <Link className="link" to="/SellerOrder-list">
                          Total Orders
                        </Link>
                        <Link className="link">Commission Structure</Link>
                        <Link className="link" to="/manageSeller-brand">
                          Manage Brand
                        </Link>
                        <Link className="link">Manage Sub Users</Link>
                        <Link className="link">Raise Query</Link>
                        <Link className="link">Size Chart List</Link>
                        <Link className="link">Add Size Chart</Link>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} xl={3}>
                  <Box sx={{ border: "2px solid #dddddd" }} elevation={0}>
                    <Box sx={{ height: "450px" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#fff",
                          textAlign: "center",
                          background: "#0099cc",
                          p: 1,
                        }}
                      >
                        PRODUCTS
                      </Typography>
                      <Box
                        sx={{
                          p: 1,
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Link className="link" to="/add-single-listing-product">
                          Add Single Listing
                        </Link>
                        <Link className="link" to="/my-product-list">
                          Product List
                        </Link>
                        <Link className="link" to="/bulkUpload">
                          Multiple Bulk Upload
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} xl={3}>
                  <Box sx={{ border: "2px solid #dddddd" }} elevation={0}>
                    <Box sx={{ height: "450px" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#fff",
                          textAlign: "center",
                          background: "#0099cc",
                          p: 1,
                        }}
                      >
                        ORDERS
                      </Typography>
                      <Box
                        sx={{
                          p: 1,
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            className="link"
                            onClick={() => navigate("/Pending-orders")}
                          >
                            Pending
                          </Typography>
                          <Typography>{Pending?.length}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            className="link"
                            onClick={() => navigate("/confirmed-orders")}
                          >
                            Confirm
                          </Typography>
                          <Typography>{Accepted?.length}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            className="link"
                            onClick={() => navigate("/ReadyToShip-orders")}
                          >
                            Ready To Ship
                          </Typography>
                          <Typography>{ReadyToShip?.length}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            className="link"
                            onClick={() => navigate("/Shipped-orders")}
                          >
                            Shipped
                          </Typography>
                          <Typography>{Shipped.length}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            className="link"
                            onClick={() => navigate("/Delivered-orders")}
                          >
                            Delivered
                          </Typography>
                          <Typography>{Delivered.length}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            className="link"
                            onClick={() =>
                              navigate("/SellerCancellation-orders")
                            }
                          >
                            Seller Cancelled
                          </Typography>
                          <Typography>{SellerCancelled.length}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            className="link"
                            onClick={() =>
                              navigate("/BuyerCancellation-orders")
                            }
                          >
                            Buyer Cancelled
                          </Typography>
                          <Typography>{BuyerCancelled.length}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            className="link"
                            onClick={() =>
                              navigate("/AdminCancellation-orders")
                            }
                          >
                            Admin Cancelled
                          </Typography>
                          <Typography>{AdminCancelled.length}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography className="link">Pre Book</Typography>
                          <Typography>0</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography className="link">SLA Breach</Typography>
                          <Typography>0</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography className="link">Refund</Typography>
                          <Typography>0</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            className="link"
                            onClick={() => navigate("/SellerOrder-list")}
                          >
                            Total Orders
                          </Typography>
                          <Typography>{MyOrders.length}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} xl={3}>
                  <Box sx={{ border: "2px solid #dddddd" }} elevation={0}>
                    <Box sx={{ height: "450px" }}>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          color: "#fff",
                          textAlign: "center",
                          background: "#0099cc",
                          p: 1,
                        }}
                      >
                        PAYMENTS
                      </Typography>
                      <Box
                        sx={{
                          p: 1,
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Total Amount</Typography>
                          <Typography>0.00</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Total Paid</Typography>
                          <Typography>0.00</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Outstanding</Typography>
                          <Typography>0.00</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography>Reserve Fund</Typography>
                          <Typography>0.00</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ mb: 3, mt: 3 }}>
                <MyOrderStatusDashBoard MyOrders={MyOrders} />
              </Box>
              <Box sx={{ mb: 3 }}>
                <PendingOrderListDashboard MyOrders={MyOrders} />
              </Box>
              <Box sx={{ mb: 3 }}>
                <DashboardPieChart1
                  AllProducts={AllProducts}
                  MyOrders={MyOrders}
                />
              </Box>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;
