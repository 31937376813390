import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Attribute from "./Attribute";
import CodAndShipping from "./CodAndShipping";
import { Title } from "@mui/icons-material";
import BasicInfo from "./BasicInfo";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {
  getAllProductTypes,
  getAllRootCategories,
  getSingleProductById,
} from "../../../axios";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function ProductTab() {
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState();
  const [mainCategories, setMainCategories] = React.useState([]);
  const [selectMainCategories, setSelectMainCategories] = React.useState();
  const [SubCategories, setSubCategories] = React.useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState();
  const [RootName, setRootName] = React.useState({});
  const [productType, setProductType] = React.useState([]);
  const [selected, setSelected] = React.useState();
  const [selectedId, setSelectedId] = React.useState();
  const [expanded, setExpanded] = React.useState({});
  const [checked, setChecked] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const [singleProductData, setSingleProductData] = React.useState({});
  const [refresh, setRefresh] = useState(false);
  const [refresh1, setRefresh1] = useState(false);

  // console.log(singleProductData);

  //   console.log(singleProductData.mainCategory);

  // const [checked, setChecked] = React.useState({});
  const { id } = useParams();

  const handleSingleProductlistingById = async () => {
    setLoading1(true);
    try {
      const res = await getSingleProductById(id);
      if (res.status === 200) {
        // console.log(res);
        setSingleProductData(res.data);
        setRefresh1((active) => !active);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  // console.log(checked);

  React.useEffect(() => {
    const initialExpanded = {};
    const initialChecked = {};
    data.forEach((item) => {
      initialExpanded[item.name] = false;
      initialChecked[item.name] = false;
      item.subcategories.forEach((mainCategory) => {
        initialExpanded[mainCategory.name] = false;
        initialChecked[mainCategory.name] = false;
        mainCategory.subcategories.forEach((subCategory) => {
          initialExpanded[subCategory.name] = false;
          initialChecked[subCategory.name] = false;
        });
      });
    });
    setExpanded(initialExpanded);
    setChecked(initialChecked);
  }, [data]);

  const handleCategoryToggle = (categoryId) => {
    setExpanded((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  const handleCategoryCheck = (event, category) => {
    const isChecked = event.target.checked;
    setChecked((prevState) => ({
      ...prevState,
      [category.name]: isChecked,
    }));
    if (category.subcategories.length > 0) {
      const updatedChecked = category.subcategories.reduce(
        (acc, subCategory) => {
          acc[subCategory.name] = isChecked;
          if (subCategory.subcategories) {
            subCategory.subcategories.forEach((childCategory) => {
              acc[childCategory.name] = isChecked;
              if (childCategory.subcategories) {
                handleCategoryCheck(event, childCategory);
              }
            });
          }
          return acc;
        },
        {}
      );
      setChecked((prevState) => ({
        ...prevState,
        ...updatedChecked,
      }));
    }
    if (isChecked && category.parentCategoryId) {
      const parentCategory = data.find(
        (item) => item.name === category.parentCategoryId
      );
      if (
        parentCategory &&
        parentCategory.subcategories.every(
          (subCategory) =>
            checked[subCategory.name] &&
            subCategory.subcategories.every(
              (grandChildCategory) => checked[grandChildCategory.name]
            )
        )
      ) {
        setChecked((prevState) => ({
          ...prevState,
          [parentCategory.name]: true,
        }));
      }
    } else if (!isChecked && category.parentCategoryId) {
      setChecked((prevState) => ({
        ...prevState,
        [category.parentCategoryId]: false,
      }));
    } else if (isChecked && !category.parentCategoryId) {
      category.subcategories.forEach((subCategory) => {
        setChecked((prevState) => ({
          ...prevState,
          [subCategory.name]: true,
        }));
        if (subCategory.subcategories) {
          subCategory.subcategories.forEach((grandChildCategory) => {
            setChecked((prevState) => ({
              ...prevState,
              [grandChildCategory.name]: true,
            }));
          });
        }
      });
    }
  };

  // console.log(productType);
  // console.log(selectedId);

  const handleRootCategory = async () => {
    setLoading(true);
    try {
      const res = await getAllRootCategories();

      if (res) {
        setData(res.data[0].subcategories);
        setRootName(res.data[0]);

        // Find the selected category object
        let selectedCategory = res.data[0].subcategories.find(
          (item) => item.name === singleProductData.Category
        );

        // Define main categories here
        let mainCategories = [];
        if (selectedCategory) {
          mainCategories = selectedCategory.subcategories;
          setLoading(false);
        }

        setMainCategories(mainCategories);

        let selectedMainCategory = mainCategories.find(
          (item) => item.name === singleProductData.mainCategory
        );

        // console.log(selectedMainCategory);

        // Define main categories here
        let subMainCategories = [];
        if (selectedMainCategory) {
          subMainCategories = selectedMainCategory.subcategories;
          setLoading(false);
        }
        // console.log(subMainCategories);
        setSubCategories(subMainCategories);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetAllProductTypesCategory = async () => {
    try {
      const res = await getAllProductTypes();

      if (res) {
        setProductType(res.data);
        setRefresh((refresh) => !refresh);
        let selectedProductCategory = productType.find(
          (item) => item.ProductType === singleProductData.ProductType
        );

        // console.log(selectedProductCategory._id);

        // console.log(selectedMainCategory);

        // Define main categories here
        // let subProductCategories = [];
        // if (selectedMainCategory) {
        //   subMainCategories = selectedMainCategory.subcategories;
        // }
        // // console.log(subMainCategories);
        // setSubCategories(subMainCategories);
        setSelectedId(selectedProductCategory._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    handleSingleProductlistingById();
  }, []);

  useEffect(() => {
    Promise.all([handleRootCategory(), handleGetAllProductTypesCategory()]);
  }, [refresh1]);

  const isLargeScreen = useMediaQuery("(min-width: 1280px)");
  const isMediumScreen = useMediaQuery("(min-width: 960px)");

  const ITEM_HEIGHT = 68;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ overflowX: isLargeScreen ? "hidden" : "auto" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant={isLargeScreen ? "fullWidth" : "scrollable"}
          scrollButtons={isMediumScreen ? "on" : "on"}
          textColor="#fff"
          TabIndicatorProps={{ style: { display: "none" } }}
          sx={{
            "& .MuiTab-root": {
              border: "1px solid #dddddd",
              marginLeft: "5px",
              whiteSpace: "nowrap",
              minWidth: isMediumScreen ? "120px" : "auto",
              backgroundColor: "#f1f1f1",
              "&.Mui-selected": {
                backgroundColor: "#262626",
                color: "white",
                border: "1px solid #dddddd",
              },
            },
            ml: 3,
            mr: 3,
          }}
        >
          <Tab label="COMMISSION CATEGORY" disableRipple />
          <Tab label="DISPLAY CATEGORY" disableRipple />
          <Tab label="BASIC INFO" disableRipple />
          <Tab label="ATTRIBUTE" disableRipple />
          <Tab label="COD AND SHIPPING" disableRipple />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Card sx={{ border: "1px solid #dddddd", p: 2 }} elevation={0}>
          {!loading ? (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Category :{" "}
                </Typography>
                <Select
                  displayEmpty
                  sx={{ width: "300px" }}
                  MenuProps={MenuProps}
                  value={
                    (singleProductData && singleProductData.Category) || ""
                  }
                  onChange={(e) => {
                    const newData = data.find(
                      (item) => item.name === e.target.value
                    );
                    setSingleProductData({
                      ...singleProductData,
                      Category: e.target.value,
                    });
                    setMainCategories(newData.subcategories);
                  }}
                >
                  <InputLabel sx={{ ml: 2 }}>--Select Category--</InputLabel>
                  {data.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Product Category :{" "}
                </Typography>
                <Select
                  displayEmpty
                  sx={{ width: "300px" }}
                  MenuProps={MenuProps}
                  value={
                    (singleProductData && singleProductData?.mainCategory) || ""
                  }
                  onChange={(e) => {
                    const selectMainCategoryData = mainCategories.find(
                      (item) => item.name === e.target.value
                    );
                    setSingleProductData({
                      ...singleProductData,
                      mainCategory: e.target.value,
                    });
                    setSubCategories(selectMainCategoryData.subcategories);
                  }}
                >
                  <InputLabel sx={{ ml: 2 }}>--Select Category--</InputLabel>
                  {mainCategories.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Product Sub Category :{" "}
                </Typography>
                <Select
                  sx={{ width: "300px" }}
                  MenuProps={MenuProps}
                  value={
                    (singleProductData && singleProductData?.subCategory) || ""
                  }
                  onChange={(e) =>
                    setSingleProductData({
                      ...singleProductData,
                      subCategory: e.target.value,
                    })
                  }
                  displayEmpty
                >
                  <InputLabel sx={{ ml: 2 }}>--Select Category--</InputLabel>
                  {SubCategories.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  {" "}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => handleChange(0, 1)}
                  sx={{ width: "200px" }}
                >
                  NEXT
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40vh",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Card>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          <Card sx={{ border: "1px solid #dddddd", p: 2 }} elevation={0}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton disabled>
                <ExpandMoreIcon />
              </IconButton>
              <Checkbox
                checked={checked[RootName.name]}
                onChange={(event) => handleCategoryCheck(event, RootName)}
              />
              <Typography>{RootName.name}</Typography>
            </Box>
            {data.map((item) => (
              <Box sx={{ ml: 2 }} key={item._id}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    onClick={() => handleCategoryToggle(item._id)}
                    disabled={item.subcategories.length === 0}
                  >
                    {expanded[item._id] && item.subcategories.length > 0 ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </IconButton>
                  <Checkbox
                    checked={singleProductData?.data?.[item.name]}
                    onChange={(event) => handleCategoryCheck(event, item)}
                  />
                  <Typography>{item.name}</Typography>
                </Box>
                {expanded[item._id] && item.subcategories.length > 0 ? (
                  <Box sx={{ ml: 2 }}>
                    {item.subcategories.map((mainCategory) => (
                      <Box key={mainCategory._id}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            onClick={() =>
                              handleCategoryToggle(mainCategory._id)
                            }
                            disabled={mainCategory.subcategories.length === 0}
                          >
                            {expanded[mainCategory._id] &&
                            mainCategory.subcategories.length > 0 ? (
                              <ExpandMoreIcon />
                            ) : (
                              <ChevronRightIcon />
                            )}
                          </IconButton>
                          <Checkbox
                            checked={singleProductData.data[mainCategory.name]}
                            onChange={(event) =>
                              handleCategoryCheck(event, mainCategory)
                            }
                          />
                          <Typography>{mainCategory.name}</Typography>
                        </Box>
                        {expanded[mainCategory._id] &&
                        mainCategory.subcategories.length > 0 ? (
                          <Box sx={{ ml: 7 }}>
                            {mainCategory.subcategories.map((subCategory) => (
                              <Box
                                key={subCategory._id}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  checked={
                                    singleProductData.data[subCategory.name]
                                  }
                                  onChange={(event) =>
                                    handleCategoryCheck(event, subCategory)
                                  }
                                />
                                <Typography>{subCategory.name}</Typography>
                              </Box>
                            ))}
                          </Box>
                        ) : null}
                      </Box>
                    ))}
                  </Box>
                ) : null}
              </Box>
            ))}
            <Typography sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                sx={{ mt: 2, width: "200px" }}
                onClick={() => handleChange(0, 2)}
              >
                NEXT
              </Button>
            </Typography>
          </Card>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <BasicInfo
          checked={checked}
          setChecked={setChecked}
          productType={productType}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          handleChange={handleChange}
          PreBook={checked.PreBook}
          replacement={checked.replacement}
          refund={checked.refund}
          Title={checked.Title}
          singleProductData={singleProductData}
          setSingleProductData={setSingleProductData}
          loading1={loading1}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Attribute
          selectedId={selectedId}
          handleChange={handleChange}
          checked={checked}
          setChecked={setChecked}
          Color={checked.Color}
          Size={checked.Size}
          singleProductData={singleProductData}
          setSingleProductData={setSingleProductData}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CodAndShipping
          checked={checked}
          setChecked={setChecked}
          CodAvailable={checked.CodAvailable}
          ShippingWeight={checked.ShippingWeight}
          Fragile={checked.Fragile}
          singleProductData={singleProductData}
          setSingleProductData={setSingleProductData}
        />
      </TabPanel>
    </Box>
  );
}
