import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function ManageTabs({ OrderData, setData, setRefresh }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [selected, setSelected] = React.useState(
    OrderData?.[0]?.orderStatus || ""
  );
  const [orderConfirmDate, setorderConfirmDate] = React.useState();
  const [Loading, setLoading] = React.useState(false);
  const [OrderCancelledDate, setOrderCancelledDate] = React.useState();
  const [RTSDate, setRTSDate] = React.useState();
  const [ShippedDate, setShippedDate] = React.useState();
  const [DeliveredDate, setDeliveredDate] = React.useState();
  const [Mode, setMode] = useState("Surface");

  React.useEffect(() => {
    if (selected === "accepted") {
      const currentDate = new Date().toLocaleDateString();
      // console.log(currentDate);
      setorderConfirmDate(currentDate);
    } else if (selected === "cancelled") {
      const currentDate = new Date().toLocaleDateString();
      setOrderCancelledDate(currentDate);
    } else if (selected === "Ready To Ship") {
      const currentDate = new Date().toLocaleDateString();
      setRTSDate(currentDate);
    } else if (selected === "Shipped") {
      const currentDate = new Date().toLocaleDateString();
      setShippedDate(currentDate);
    } else if (selected === "Delivered") {
      const currentDate = new Date().toLocaleDateString();
      setDeliveredDate(currentDate);
    }
  }, [selected]);

  //   console.log(selected);

  const { id } = useParams();

  const handleUpdateOrder = async () => {
    setLoading(true);
    try {
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/buyer/updateOrder/${id}`,
        {
          orderStatus: selected,
          orderConfirmDate: orderConfirmDate,
          orderSellerCancelled: selected === "cancelled" ? true : false,
          OrderCancelledDate: OrderCancelledDate,
          RTSDate: RTSDate,
          ShippedDate: ShippedDate,
          DeliveredDate: DeliveredDate,
        }
      );
      if (res) {
        toast.success("Order Data Updated Successfully", {
          position: "top-right",
        });
        setRefresh((refresh) => !refresh);

        if (selected === "accepted") {
          navigate("/confirmed-orders");
        } else if (selected === "cancelled") {
          navigate("/SellerCancellation-orders");
        } else if (selected === "Ready To Ship") {
          navigate("/ReadyToShip-orders");
        } else if (selected === "Shipped") {
          navigate("/Shipped-orders");
        } else if (selected === "Delivered") {
          navigate("/Delivered-orders");
        }
      } else {
        toast.error("Order Data Update Failed", {
          position: "top-right",
        });
      }
    } catch (error) {
      toast.error(error.response.data, {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const [value, setValue] = React.useState(0); // Set the initial value to 2

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTabContent = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return (
          <div>
            <Box sx={{ border: "1px solid #ddd", ml: -3, p: 2 }}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "gray", minWidth: "200px" }}>
                    Status :
                  </Typography>
                  {OrderData?.[0]?.orderStatus === "pending" && (
                    <Select
                      sx={{ width: "350px" }}
                      size="small"
                      value={selected}
                      onChange={(e) => setSelected(e.target.value)}
                    >
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="accepted">Accept</MenuItem>
                      <MenuItem value="cancelled">Cancel</MenuItem>
                    </Select>
                  )}
                  {OrderData?.[0]?.orderStatus === "accepted" && (
                    <Select
                      sx={{ width: "350px" }}
                      size="small"
                      value={selected}
                      onChange={(e) => setSelected(e.target.value)}
                    >
                      <MenuItem value="accepted">Accept</MenuItem>
                      <MenuItem value="Ready To Ship">Ready To Ship</MenuItem>
                    </Select>
                  )}

                  {OrderData?.[0]?.orderStatus === "Ready To Ship" && (
                    <Select
                      sx={{ width: "350px" }}
                      size="small"
                      value={selected}
                      onChange={(e) => setSelected(e.target.value)}
                    >
                      <MenuItem value="Ready To Ship">Ready To Ship</MenuItem>
                      <MenuItem value="Shipped">Shipped</MenuItem>
                    </Select>
                  )}
                  {OrderData?.[0]?.orderStatus === "Shipped" && (
                    <Select
                      sx={{ width: "350px" }}
                      size="small"
                      value={selected}
                      onChange={(e) => setSelected(e.target.value)}
                    >
                      <MenuItem value="Shipped">Shipped</MenuItem>
                      <MenuItem value="Delivered">Delivered</MenuItem>
                    </Select>
                  )}
                  {OrderData?.[0]?.orderStatus === "cancelled" && (
                    <Select
                      sx={{ width: "350px" }}
                      size="small"
                      value={selected}
                      onChange={(e) => setSelected(e.target.value)}
                    >
                      <MenuItem value="cancelled">Cancel</MenuItem>
                    </Select>
                  )}
                </Box>
                <Box></Box>
                {selected === "Ready To Ship" && (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          minWidth: "200px",
                          color: "gray",
                        }}
                      >
                        Mode:
                      </Typography>
                      <Select
                        sx={{ width: "350px" }}
                        size="small"
                        value={Mode}
                        onChange={(e) => setMode(e.target.value)}
                      >
                        <MenuItem value="Air">Air</MenuItem>
                        <MenuItem value="Surface">Surface</MenuItem>
                      </Select>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          minWidth: "200px",
                          color: "gray",
                        }}
                      >
                        Package Height (cm):
                      </Typography>
                      <TextField
                        size="small"
                        placeholder="Package Height (cm)"
                        sx={{ width: "350px" }}
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          minWidth: "200px",
                          color: "gray",
                        }}
                      >
                        Package Length (cm):
                      </Typography>
                      <TextField
                        size="small"
                        placeholder="Package Length (cm)"
                        sx={{ width: "350px" }}
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          minWidth: "200px",
                          color: "gray",
                        }}
                      >
                        Package Width (cm) :
                      </Typography>
                      <TextField
                        size="small"
                        placeholder="Package Width (cm) "
                        sx={{ width: "350px" }}
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          minWidth: "200px",
                          color: "gray",
                        }}
                      >
                        Shipping Weight (grm) :
                      </Typography>
                      <TextField
                        size="small"
                        placeholder="Shipping Weight (grm) "
                        sx={{ width: "350px" }}
                      />
                    </Box>
                  </>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Typography
                  sx={{ color: "gray", minWidth: "200px" }}
                ></Typography>
                <Button
                  variant="contained"
                  onClick={handleUpdateOrder}
                  disabled={Loading}
                >
                  {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
                </Button>
              </Box>
            </Box>
          </div>
        );
      case 1:
        return (
          <div>
            <Box sx={{ border: "1px solid #ddd", ml: -3, p: 2 }}>
              <Typography>
                Since your order is not ready to shipped. You cannot upload a
                manifest.
              </Typography>
            </Box>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {OrderData?.[0]?.orderStatus === "cancelled" ||
      OrderData?.[0]?.orderStatus === "Delivered" ||
      OrderData?.[0]?.orderBuyerCancelled === true ||
      OrderData?.[0]?.orderSellerCancelled === true ||
      OrderData?.[0]?.orderAdminCancelled === true ? (
        ""
      ) : (
        <Box sx={{ width: "96.5%", bgcolor: "background.paper", ml: 3, mt: 4 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant={isLargeScreen ? "300px" : "scrollable"}
            scrollButtons={isLargeScreen ? "off" : "auto"}
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            sx={{
              "& .MuiTabs-flexContainer": {
                // borderBottom: "1px solid #ddd",
              },
              "& .MuiTab-root": {
                border: "1px solid #ddd",
                background: "#eee",

                "&.Mui-selected": {
                  backgroundColor: "#0099cc",
                  color: "white",
                },
                "&:hover": {
                  backgroundColor: "#0099cc",
                  color: "#fff",
                },
              },
              "& .MuiTab-textColorPrimary": {
                color: "black",
              },
            }}
          >
            <Tab
              label="SHIPPING"
              sx={{ fontWeight: "bold", width: isLargeScreen && "200px" }}
            />
            <Tab
              label="MANIFEST"
              sx={{ fontWeight: "bold", width: isLargeScreen && "200px" }}
            />
          </Tabs>
          <Box sx={{ p: 3 }}>{renderTabContent(value)}</Box>
        </Box>
      )}
    </>
  );
}
