import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CKEditor } from "ckeditor4-react";
import Swal from "sweetalert2";
import { createSellerQuery } from "../../axios";
import { useSelector } from "react-redux";

const RaiseQuery = ({ data }) => {
  const [options, setOptions] = useState([]);
  const [orderNo, setOrderNo] = useState();
  const [description, setDescription] = useState("");
  const [Loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState();
  const [orderDate, SetOrderDate] = useState();

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const orderIds = data.map((item) => item.orderId);
    setOptions(orderIds);

    const order = data.find((item) => item.orderId === orderNo);
    if (order) {
      setOrderStatus(order.orderStatus);
      SetOrderDate(order.orderDate);
    } else {
      setOrderStatus("");
      SetOrderDate("");
    }
  }, [data, orderNo]);

  function parseHTMLToText(html) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  }

  const handleEditorChange = (event) => {
    const parsedData = parseHTMLToText(event.editor.getData());
    setDescription(parsedData);
  };
  const ITEM_HEIGHT = 68;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleSubmitQuery = async () => {
    setLoading(true);
    try {
      const res = await createSellerQuery(
        orderNo,
        description,
        user._id,
        orderStatus,
        orderDate
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res.message,
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "success",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: "80px" }}>
      <Box>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
        >
          Add Query
        </Typography>
        <Typography
          sx={{
            border: "2px solid #0099cc",
            width: "60px",
            margin: "auto",
          }}
        />
      </Box>
      <Grid container spacing={2} sx={{ p: 3, mt: 3 }}>
        <Grid item xs={12} lg={8}>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "200px" }}
            >
              Order No:{" "}
            </Typography>
            <Select
              displayEmpty
              sx={{ width: "400px" }}
              MenuProps={MenuProps}
              size="small"
              value={orderNo}
              onChange={(e) => setOrderNo(e.target.value)}
            >
              <InputLabel sx={{ ml: 2 }}>Select OrderNo </InputLabel>
              {options.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ display: "flex", mt: 3 }}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "flex-start",
                minWidth: "200px",
              }}
            >
              Description:{" "}
            </Typography>
            <CKEditor onChange={handleEditorChange} />
          </Box>
          <Box sx={{ display: "flex", mt: 3 }}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "flex-start",
                minWidth: "200px",
              }}
            ></Typography>
            <Button
              variant="contained"
              sx={{ width: "200px" }}
              disableElevation
              onClick={handleSubmitQuery}
              disabled={Loading || !orderNo || !description}
            >
              {Loading ? <CircularProgress size={24} /> : "SUBMIT QUERY"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default RaiseQuery;
