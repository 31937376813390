import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Typography } from "@mui/material";
import CustomerAnswerDialog from "./CustomerAnswerDialog";

export default function CustomerQuestionTable({ data, setRefresh }) {
  return (
    <>
      {data.length > 0 ? (
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ border: "1px solid #ddd" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: "#f9f9f9" }}>
              <TableRow>
                <TableCell
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  S.No
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Buyer Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Buyer Email
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Skucode
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Product Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Question
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Answer
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
                >
                  Answered By
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ borderRight: "1px solid #ddd" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.Buyer.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.Buyer.userEmail}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.product.SKUCode}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.product.Title}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.Question}
                  </TableCell>
                  {row.Answer === "" ? (
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      <CustomerAnswerDialog
                        Question={row.Question}
                        id={row._id}
                        setRefresh={setRefresh}
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      align="left"
                      sx={{ borderRight: "1px solid #ddd" }}
                    >
                      {row.Answer}
                    </TableCell>
                  )}
                  <TableCell
                    align="left"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.AnsweredBy}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <Typography
            sx={{
              border: "1px solid #ddd",
              p: 2,
              width: "300px",
              textAlign: "center",
              background: "#f9f9f9",
            }}
          >
            No Question's Found
          </Typography>
        </Box>
      )}
    </>
  );
}
