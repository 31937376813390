import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { AllProductssByMe } from "../../axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import StockTable from "./StockOverViewTable";

const StockOverView = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [Loading, setLoading] = useState(false);
  const handleAllProductsBySeller = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await AllProductssByMe(config);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleAllProductsBySeller();
  }, [refresh]);
  return (
    <div style={{ marginTop: "80px" }}>
      <Box>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bolder",
            textAlign: "center",
            mt: 3,
          }}
        >
          Stock Overview
        </Typography>
        <Typography
          style={{ margin: "auto", border: "2px solid #0099cc", width: "60px" }}
        ></Typography>
      </Box>
      {!Loading ? (
        <Box>
          <StockTable
            data={data}
            setData={setData}
            setRefresh={setRefresh}
            refresh={refresh}
            Loading={Loading}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default StockOverView;
