import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import arrow from "../images/arrow-order.png";
import { useNavigate } from "react-router-dom";

const MyOrderStatusDashBoard = ({ MyOrders }) => {
  const Pending = MyOrders.filter((item) => item.orderStatus === "pending");
  const Accepted = MyOrders.filter((item) => item.orderStatus === "accepted");
  const Cancelled = MyOrders.filter(
    (item) =>
      item.orderStatus === "cancelled" && item.orderSellerCancelled === true
  );
  const ReadyToShip = MyOrders.filter(
    (item) => item.orderStatus === "Ready To Ship"
  );
  const Shipped = MyOrders.filter((item) => item.orderStatus === "Shipped");
  const Delivered = MyOrders.filter((item) => item.orderStatus === "Delivered");

  const navigate = useNavigate();
  return (
    <div>
      <Grid container spacing={2} sx={{ px: 2 }}>
        <Grid item xs={12} lg={12} xl={12}>
          <Box>
            <Typography
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
            >
              My Order Status
            </Typography>
            <Typography
              sx={{
                border: "2px solid #0099cc",
                width: "60px",
                margin: "auto",
              }}
            ></Typography>
          </Box>

          <Grid container item sx={{ mt: 3 }}>
            <Grid item lg={4} xs={12}>
              <Box sx={{ backgroundColor: "#ed1c24", p: 1.3 }}>
                <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
                  ORDER IN PROCESS
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Box sx={{ backgroundColor: "#ffc90e", p: 1.3 }}>
                <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
                  READY TO SHIP (RTS)
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2.5} xs={12}>
              <Box sx={{ backgroundColor: "#37cd05", p: 1.3 }}>
                <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
                  SHIPPED ORDERS
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2.5} xs={12}>
              <Box sx={{ backgroundColor: "#efe4b0", p: 1.3 }}>
                <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
                  COMPLETED ORDERS
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12} lg={1.6} md={2} xl={1.6}>
              <Box sx={{ mt: 1 }}>
                <Box
                  sx={{
                    border: "2px solid #0099cc",
                    height: "130px",
                    borderRadius: "5px",
                    mb: 2,
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/Pending-orders")}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      p: 1,
                      textAlign: "center",
                    }}
                  >
                    Pending Orders
                  </Typography>
                  <Divider
                    sx={{ border: "2px solid #000", borderRadius: "7px" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "67%",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#eeeeee",
                        p: 1,
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {Pending?.length ?? "0"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "2px solid #0099cc",
                    height: "130px",
                    borderRadius: "5px",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      p: 1,
                      textAlign: "center",
                    }}
                  >
                    Prebook Orders
                  </Typography>
                  <Divider
                    sx={{ border: "2px solid #000", borderRadius: "7px" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "65%",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#eeeeee",
                        p: 1,
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        0
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "2px solid #0099cc",
                    height: "130px",
                    borderRadius: "5px",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      p: 1,
                      textAlign: "center",
                    }}
                  >
                    SLA Breach
                  </Typography>
                  <Divider
                    sx={{ border: "2px solid #000", borderRadius: "7px" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "65%",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#eeeeee",
                        p: 1,
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        0
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={0.9} md={1.2} xl={0.9}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img src={arrow} alt="arrow" />
              </Box>
            </Grid>
            <Grid item lg={1.6} md={2} xl={1.6}>
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    border: "2px solid #0099cc",
                    height: "130px",
                    borderRadius: "5px",
                    mb: 2,
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/confirmed-orders")}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      p: 1,
                      textAlign: "center",
                    }}
                  >
                    Confirm Orders
                  </Typography>
                  <Divider
                    sx={{ border: "2px solid #000", borderRadius: "7px" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "65%",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#eeeeee",
                        p: 1,
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {Accepted?.length ?? "0"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "2px solid #0099cc",
                    height: "130px",
                    borderRadius: "5px",
                    mb: 2,
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/SellerCancellation-orders")}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      p: 1,

                      textAlign: "center",
                    }}
                  >
                    Cancel Orders
                  </Typography>
                  <Divider
                    sx={{ border: "2px solid #000", borderRadius: "7px" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "65%",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#eeeeee",
                        p: 1,
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {Cancelled?.length ?? "0"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={0.9} md={1.2} xl={0.9}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img src={arrow} alt="arrow" />
              </Box>
            </Grid>
            <Grid item lg={1.6} md={2} xl={1.6}>
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    border: "2px solid #0099cc",
                    height: "130px",
                    borderRadius: "5px",
                    mb: 2,
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/ReadyToShip-orders")}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      p: 1,
                      textAlign: "center",
                    }}
                  >
                    Ready To Ship
                  </Typography>
                  <Divider
                    sx={{ border: "2px solid #000", borderRadius: "7px" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "65%",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#eeeeee",
                        p: 1,
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {ReadyToShip?.length ?? "0"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={0.9} md={1.2} xl={0.9}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img src={arrow} alt="arrow" />
              </Box>
            </Grid>
            <Grid item lg={1.6} md={2} xl={1.6}>
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    border: "2px solid #0099cc",
                    height: "130px",
                    borderRadius: "5px",
                    mb: 2,
                    // cursor: "pointer",
                  }}
                  //   onClick={() => navigate("/ReadyToShip-orders")}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      p: 1,
                      textAlign: "center",
                    }}
                  >
                    Shipped Orders
                  </Typography>
                  <Divider
                    sx={{ border: "2px solid #000", borderRadius: "7px" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "65%",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#eeeeee",
                        p: 1,
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {Shipped?.length ?? "0"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={0.9} md={1.2} xl={0.9}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img src={arrow} alt="arrow" />
              </Box>
            </Grid>
            <Grid item xs={12} lg={1.6} md={2} xl={1.6}>
              <Box sx={{ mt: 1 }}>
                <Box
                  sx={{
                    border: "2px solid #0099cc",
                    height: "130px",
                    borderRadius: "5px",
                    mb: 2,
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/Delivered-orders")}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      p: 1,
                      textAlign: "center",
                    }}
                  >
                    Delivered Orders
                  </Typography>
                  <Divider
                    sx={{ border: "2px solid #000", borderRadius: "7px" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "65%",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#eeeeee",
                        p: 1,
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {Delivered?.length ?? "0"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "2px solid #0099cc",
                    height: "130px",
                    borderRadius: "5px",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      p: 1,
                      textAlign: "center",
                    }}
                  >
                    Return Request
                  </Typography>
                  <Divider
                    sx={{ border: "2px solid #000", borderRadius: "7px" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "65%",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#eeeeee",
                        p: 1,
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        0
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "2px solid #0099cc",
                    height: "130px",
                    borderRadius: "5px",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      p: 1,
                      textAlign: "center",
                    }}
                  >
                    Refund Orders
                  </Typography>
                  <Divider
                    sx={{ border: "2px solid #000", borderRadius: "7px" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "65%",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#eeeeee",
                        p: 1,
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        0
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MyOrderStatusDashBoard;
