import React from "react";
import { QuestionByBuyer } from "../../axios";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import CustomerQuestionTable from "./CustomerQuestionTable";

const CustomerQuestions = () => {
  const [data, setData] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const [Loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const handleGetAllQuestionByBuyer = async () => {
    setLoading(true);
    try {
      const res = await QuestionByBuyer(user._id);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAllQuestionByBuyer();
  }, [refresh]);
  return (
    <div style={{ marginTop: "80px" }}>
      <Box>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
        >
          Customer Questions
        </Typography>
        <Typography
          sx={{
            border: "2px solid #0099cc",
            width: "60px",
            margin: "auto",
          }}
        ></Typography>
      </Box>
      {!Loading ? (
        <Grid container spacing={2} sx={{ p: 3, mt: 2 }}>
          <Grid item xs={12} lg={12} xl={12}>
            <Box>
              <Typography sx={{ mt: 1, mb: 2 }}>
                Total Question :{" "}
                <span style={{ color: "#0099cc" }}>{data?.length}</span>
              </Typography>
            </Box>
            <CustomerQuestionTable data={data} setRefresh={setRefresh} />
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default CustomerQuestions;
