import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import AccordingTable from "./AccordinTable";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function OrderWiseAccordin({ data }) {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panelId) => () => {
    setExpanded(panelId === expanded ? null : panelId);
  };

  return (
    <div>
      {data.map((row, index) => {
        if (row.orderPaymentStatus === "Paid" && row.orderPaymentDate) {
          return (
            <React.Fragment key={index}>
              {row.orderItems.map((item, itemIndex) => {
                const panelId = `panel${index}-${itemIndex}`;
                return (
                  <Accordion
                    key={panelId}
                    expanded={expanded === panelId}
                    onChange={handleChange(panelId)}
                  >
                    <AccordionSummary
                      aria-controls={`${panelId}d-content`}
                      id={`${panelId}d-header`}
                    >
                      <Typography>
                        Against order.no <strong>{row.orderId}</strong>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <AccordingTable row={row} />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </React.Fragment>
          );
        } else {
          return null; // Return null if the condition is not met
        }
      })}
    </div>
  );
}
