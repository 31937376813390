import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { EditSellerData } from "../../axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useState } from "react";

const ManageProfileTab = ({ data, Loading, setData }) => {
  const { user } = useSelector((state) => state.auth);
  const [Loading1, setLoading1] = useState(false);
  const [ContactphoneNumberError, setContactphoneNumberError] = useState("");
  const [DisplayNAmeError, setDisplayNameError] = useState("");
  const [ShippingAddressError, setShippingAddressError] = useState("");
  const [ShippingLandMarkError, setShippingLandMarkError] = useState("");
  const [AccountNumberError, setAccountNumberError] = useState("");
  const [BenificiaryNameError, setBenificiaryNameError] = useState("");
  const [BankNameError, setBankNameError] = useState("");
  const handleUpdateSellerInfo = async () => {
    if (
      (!data.mobileNumber && ContactphoneNumberError) ||
      (!data.companyDisplayName && DisplayNAmeError) ||
      (!data.warehouseAddress && ShippingAddressError) ||
      (!data.warehouselandmark && ShippingLandMarkError) ||
      !data.POBox ||
      (!data.sellerBankAccountName && AccountNumberError) ||
      (!data.sellerAccountNumber && BenificiaryNameError) ||
      (!data.sellerBankName && BankNameError)
    ) {
      return;
    }
    setLoading1(true);
    try {
      const res = await EditSellerData(
        user._id,
        data.mobileNumber,
        data.companyDisplayName,
        data.warehouseAddress,
        data.warehouselandmark,
        data.POBox,
        data.sellerBankAccountName,
        data.sellerAccountNumber,
        data.sellerBankName,
        data.sellerIBAN,
        data.sellerSWIFTCode
      );
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Seller information updated successfully",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  const handleContactphoneNumber = (e) => {
    const pattern = /^\d{10}$/; // Assuming the mobile number format is a 10-digit number
    if (pattern.test(e.target.value)) {
      setContactphoneNumberError("");
    } else if (e.target.length === 0) {
      setContactphoneNumberError(" Phone Number is required");
    } else {
      setContactphoneNumberError("Enter a valid 10 digit Phone Number");
    }
    setData({ ...data, mobileNumber: e.target.value });
  };

  const handleCompanyDisplayName = (e) => {
    const value = e.target.value;
    if (value.length === 0) {
      setDisplayNameError("Company DisplayName is required");
    } else {
      setDisplayNameError("");
    }

    setData({ ...data, companyDisplayName: value });
  };
  const handleShippingAddress = (e) => {
    const value = e.target.value;
    if (value.length === 0) {
      setShippingAddressError("Shipping Address is required");
    } else {
      setShippingAddressError("");
    }

    setData({ ...data, warehouseAddress: value });
  };
  const handleShippingLandMark = (e) => {
    const value = e.target.value;
    if (value.length === 0) {
      setShippingLandMarkError("Shipping LandMark is required");
    } else {
      setShippingLandMarkError("");
    }

    setData({ ...data, warehouselandmark: value });
  };
  const handleBeniicaryName = (e) => {
    const value = e.target.value;
    if (value.length === 0) {
      setBenificiaryNameError("Benificiary Name is required");
    } else {
      setBenificiaryNameError("");
    }

    setData({ ...data, sellerBankAccountName: value });
  };
  const handleBankName = (e) => {
    const value = e.target.value;
    if (value.length === 0) {
      setBankNameError("Bank Name is required");
    } else {
      setBankNameError("");
    }

    setData({ ...data, sellerBankName: value });
  };
  const handleAccountNumber = (e) => {
    const value = e.target.value;
    const accountNumber = value.replace(/\s/g, "");
    if (value.length === 0) {
      setAccountNumberError("Account Number is required");
    } else if (!/^\d+$/.test(accountNumber)) {
      setAccountNumberError("Enter a Valid Account Number");
    } else if (value.includes(" ")) {
      setAccountNumberError("Remove Spaces from Account Number");
    } else {
      setAccountNumberError("");
    }

    setData({ ...data, sellerAccountNumber: value });
  };

  return (
    <>
      {!Loading ? (
        <div>
          <Box sx={{ border: "1px solid #ddd", mb: 5 }}>
            <Box sx={{ p: 2 }}>
              <Typography sx={{ fontWeight: "bold" }}>Basic Details</Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  FullName:
                </Typography>
                <TextField
                  placeholder="FullName"
                  sx={{ width: "350px" }}
                  value={data.fullName}
                  disabled
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Email Address:
                </Typography>
                <TextField
                  placeholder="Email Address"
                  sx={{ width: "350px" }}
                  value={data.email}
                  disabled
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Mobile Number:
                </Typography>
                <TextField
                  placeholder="Mobile Number"
                  sx={{ width: "350px" }}
                  value={data.mobileNumber}
                  onChange={handleContactphoneNumber}
                  error={ContactphoneNumberError}
                  helperText={ContactphoneNumberError}
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Company Name:
                </Typography>
                <TextField
                  placeholder="Company Name"
                  sx={{ width: "350px" }}
                  value={data.companyName}
                  disabled
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Company Display Name:
                </Typography>
                <TextField
                  placeholder="Company Display Name"
                  sx={{ width: "350px" }}
                  value={data.companyDisplayName}
                  onChange={handleCompanyDisplayName}
                  error={DisplayNAmeError}
                  helperText={DisplayNAmeError}
                  size="medium"
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ border: "1px solid #ddd", mb: 5 }}>
            <Box sx={{ p: 2 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Address Details
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Shiping Address:
                </Typography>
                <TextField
                  placeholder="Shipping Address"
                  sx={{ width: "350px" }}
                  value={data.warehouseAddress}
                  onChange={handleShippingAddress}
                  error={ShippingAddressError}
                  helperText={ShippingAddressError}
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Shipping Landmark:
                </Typography>
                <TextField
                  placeholder="Shipping Landmark"
                  sx={{ width: "350px" }}
                  value={data.warehouselandmark}
                  onChange={handleShippingLandMark}
                  error={ShippingLandMarkError}
                  helperText={ShippingLandMarkError}
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Pincode:
                </Typography>
                <TextField
                  placeholder="Pincode"
                  sx={{ width: "350px" }}
                  value={data.POBox}
                  onChange={(e) => {
                    setData({ ...data, POBox: e.target.value });
                  }}
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  City:
                </Typography>
                <TextField
                  placeholder="City"
                  sx={{ width: "350px" }}
                  value={data.warehouseCity}
                  disabled
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  State:
                </Typography>
                <TextField
                  placeholder="State"
                  sx={{ width: "350px" }}
                  value={data.warehouseState}
                  disabled
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Country:
                </Typography>
                <TextField
                  placeholder="State"
                  sx={{ width: "350px" }}
                  value={data.wareshouseCountry}
                  disabled
                  size="medium"
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ border: "1px solid #ddd", mb: 5 }}>
            <Box sx={{ p: 2 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Seller Documents
              </Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Company Trade License:
                </Typography>
                <Box sx={{ width: "120px" }}>
                  <img
                    src={data.CompanyTradeLicense}
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Brand Authorization Letter:
                </Typography>
                <Box sx={{ width: "120px" }}>
                  <img
                    src={data.BrandAuthorizationLetter}
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ border: "1px solid #ddd" }}>
            <Box sx={{ p: 2 }}>
              <Typography sx={{ fontWeight: "bold" }}>Bank Details</Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Account No:
                </Typography>
                <TextField
                  placeholder="Account No"
                  sx={{ width: "350px" }}
                  value={data.sellerAccountNumber}
                  onChange={handleAccountNumber}
                  error={AccountNumberError}
                  helperText={AccountNumberError}
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Beneficiary Name:
                </Typography>
                <TextField
                  placeholder="Beneficiary Name"
                  sx={{ width: "350px" }}
                  value={data.sellerBankAccountName}
                  onChange={handleBeniicaryName}
                  error={BenificiaryNameError}
                  helperText={BenificiaryNameError}
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Bank Name:
                </Typography>
                <TextField
                  placeholder="Bank Name"
                  sx={{ width: "350px" }}
                  value={data.sellerBankName}
                  onChange={handleBankName}
                  error={BankNameError}
                  helperText={BankNameError}
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Seller IBAN:
                </Typography>
                <TextField
                  placeholder="Seller IBAN"
                  sx={{ width: "350px" }}
                  value={data.sellerIBAN}
                  onChange={(e) => {
                    setData({ ...data, sellerIBAN: e.target.value });
                  }}
                  size="medium"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "300px",
                  }}
                >
                  Seller SWIFT Code:
                </Typography>
                <TextField
                  placeholder="Seller SWIFT Code"
                  sx={{ width: "350px" }}
                  value={data.sellerSWIFTCode}
                  onChange={(e) => {
                    setData({ ...data, sellerSWIFTCode: e.target.value });
                  }}
                  size="medium"
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            ></Typography>
            <Button
              variant="contained"
              sx={{ background: "#0099cc", width: "200px", ml: 2, mt: 3 }}
              disableElevation
              onClick={handleUpdateSellerInfo}
              disabled={Loading1}
            >
              {Loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
            </Button>
          </Box>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "60vh",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ManageProfileTab;
