import {
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Category } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { handleUploadImageAndPDF } from "../../axios";

const CodAndShipping = ({
  checked,
  setChecked,
  CodAvailable = "No",
  ShippingWeight,
  Fragile = "No",
}) => {
  const { token } = useSelector((data) => data.auth);
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);

  const generateRandomNumber = () => {
    const uuid = uuidv4(); // Generate a UUID
    const randomNumberString = uuid.split("-").join("").slice(0, 12); // Remove hyphens and take the first 12 characters
    const randomNumber = parseInt(randomNumberString, 16); // Convert the string to a decimal number
    return randomNumber;
  };

  const handleSingleListing = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("MainImage", checked.MainImage);
      formData.append("PrdoductDetailsDoc", checked.PrdoductDetailsDoc);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };
      const config1 = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      console.log(formData);

      const res1 = await handleUploadImageAndPDF(formData, config);

      if (res1) {
        const res = await axios.post(
          "https://rccore-backend.cstechns.com/api/seller/Products/add-single-listing",
          {
            data: checked,
            CodAvailable: checked.CodAvailable,
            Fragile: checked.Fragile,
            PreBook: checked.PreBook,
            replacement: checked.replacement,
            refund: checked.refund,
            Title: checked.Title,
            SKUCode: checked.SkuCode,
            BrandName: checked.Brand,
            UniqueUrl: checked.UniqueUrl,
            Category: checked.Category,
            mainCategory: checked.mainCategory,
            subCategory: checked.subCategory,
            ProductType: checked.ProductType,
            HSNCode: checked.HSNCode,
            Tax: checked.Tax,
            Description: checked.Description,
            SkuCode: checked.SkuCode,
            searchTitle: checked.searchTitle,
            searchKeywords: checked.searchKeywords,
            searchDescription: checked.searchDescription,
            shipment: checked.shipment,
            returnPolicy: checked.returnPolicy,
            returnPolicyDescription: checked.returnPolicyDescription,
            MemoryStorage: checked.MemoryStorage,
            ShippingWeight: checked.ShippingWeight,
            Colors:
              checked && checked.Colors && checked?.Colors?.length > 0
                ? checked.Colors
                : "Default",
            MainImage: res1.data.imageUrl,
            PrdoductDetailsDoc: res1.data.pdfUrl,
            uniqueId: generateRandomNumber(),
            Sizes:
              checked && checked.Sizes && checked?.Sizes?.length > 0
                ? checked.Sizes
                : "Default",
          },
          config1
        );
        if (res) {
          // console.log(res);
          Swal.fire({
            position: "center",
            icon: "success",
            text: "Created SuccessFully",
            showConfirmButton: true,
          });
          navigate(`/manage-price-stock/${res.data._id}`, { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        position: "center",
        icon: "error",
        text: "error Occured",
        showConfirmButton: true,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Card sx={{ p: 2, border: "1px solid #dddddd" }} elevation={0}>
        <Box>
          <Box
            sx={{
              display: "flex",
              //   justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              COD Available :{" "}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                // defaultValue="No"
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  checked={CodAvailable == "Yes"}
                  label="Yes"
                  onChange={(e) =>
                    setChecked({ ...checked, CodAvailable: "Yes" })
                  }
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  checked={CodAvailable == "No"}
                  label="No"
                  onChange={(e) =>
                    setChecked({ ...checked, CodAvailable: "No" })
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",

              mt: 2,
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Shipping Weight (gms) :{" "}
            </Typography>
            <TextField
              placeholder="Shipping Weight (gms) : "
              sx={{ width: "300px" }}
              value={ShippingWeight}
              onChange={(e) =>
                setChecked({ ...checked, ShippingWeight: e.target.value })
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              //   justifyContent: "space-between",

              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mt: 2,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            >
              Fragile :{" "}
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue="No"
              >
                <FormControlLabel
                  value={Fragile}
                  control={<Radio />}
                  checked={Fragile == "Yes"}
                  label="Yes"
                  onChange={(e) => setChecked({ ...checked, Fragile: "Yes" })}
                />
                <FormControlLabel
                  value={Fragile}
                  control={<Radio />}
                  checked={Fragile == "No"}
                  label="No"
                  onChange={(e) => setChecked({ ...checked, Fragile: "No" })}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mt: 3,
              mb: 5,
            }}
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "300px",
              }}
            ></Typography>
            <Button
              variant="contained"
              onClick={() => handleSingleListing()}
              sx={{ width: "200px" }}
              disabled={Loading}
            >
              {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
            </Button>
          </Box>
        </Box>
      </Card>
    </div>
  );
};

export default CodAndShipping;
