import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { TbFileSettings } from "react-icons/tb";

export default function PendingOrderDashboardTable({ Pending }) {
  const navigate = useNavigate();
  return (
    <>
      {Pending.length > 0 ? (
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{ border: "1px solid #ddd" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: "#eee" }}>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Order No
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Order date{" "}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Amount (₹){" "}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Mode of Payment{" "}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Status{" "}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderRight: "1px solid #ddd" }}
                >
                  Manage Order
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Pending.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    <Typography
                      variant="subtitle2"
                      onClick={() =>
                        navigate(`/manage-order/${row._id}`, {
                          state: { orderData: [row] },
                        })
                      }
                      style={{
                        color: "#0099cc",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      {row.orderId}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.orderDate}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.totalPrice}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.orderPaymentMethod}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    {row.orderStatus}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: "1px solid #ddd" }}
                  >
                    <Tooltip title="Manage Order">
                      <IconButton
                        onClick={() =>
                          navigate(`/manage-order/${row._id}`, {
                            state: { orderData: [row] },
                          })
                        }
                      >
                        <TbFileSettings />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              border: "1px solid #ddd",
              p: 1,
              width: "300px",
              background: "#f9f9f9",
              fontWeight: "bold",
            }}
          >
            No records found
          </Typography>
        </Box>
      )}
    </>
  );
}
