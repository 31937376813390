import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { StoreActiveForSeller } from "../../axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";

const AcitveTab = ({ data, Loading, setRefresh }) => {
  const { user } = useSelector((state) => state.auth);
  const [active, setActive] = useState();
  const [Loading1, setLoading1] = useState(false);

  useEffect(() => {
    setActive(data?.sellerStoreActive);
  }, [data]);

  const handleActiveStore = async () => {
    setLoading1(true);
    try {
      const res = await StoreActiveForSeller(user._id, active);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: res.message,
          showConfirmButton: true,
        });
        setRefresh((refresh) => !refresh);
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "success",
        text: error.response.data.error,
        showConfirmButton: true,
      });
    } finally {
      setLoading1(false);
    }
  };
  return (
    <div>
      {!Loading ? (
        <Box sx={{ border: "1px solid #ddd", mb: 5 }}>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              Active/Inactive Store
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Typography>
              Note : When you disable your store, all your products active on
              the website will disappear.
            </Typography>
            <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                }}
              >
                Your Store:
              </Typography>
              <Box>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={active}
                      checked={active === true}
                      onChange={(e) => setActive(true)}
                      control={<Radio />}
                      label="Active"
                    />
                    <FormControlLabel
                      value={active}
                      checked={active === false}
                      onChange={(e) => setActive(false)}
                      control={<Radio />}
                      label="Inactive"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "5px",
              mb: 2,
            }}
          >
            <Typography
              sx={{ display: "flex", alignItems: "center", minWidth: "300px" }}
            ></Typography>
            <Box>
              <Button
                variant="contained"
                sx={{
                  background: "#0099cc",
                  width: "120px",
                  marginLeft: "10px",
                }}
                disableElevation
                onClick={handleActiveStore}
                disabled={Loading1}
              >
                {Loading1 ? <CircularProgress size={24} /> : "SUBMIT"}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "60vh",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default AcitveTab;
