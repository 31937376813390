import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { handleGetAllSellerNavigations } from "../../axios";
import { useEffect } from "react";
import PaymentImage from "../../images/Payment.png";
import "../../App.css";

const Footer = () => {
  const [data, setData] = useState([]);
  const handleGetAllNavigations = async () => {
    try {
      const res = await handleGetAllSellerNavigations();
      if (res) {
        // console.log(res);
        setData(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAllNavigations();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        bottom: 0,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          borderTop: "1px solid #ddd",
          borderBottom: "1px solid #ddd",
          p: 2,
          mt: 5,
          background: "#f9f9f9",
          display: "flex",
        }}
      >
        <Grid item xs={12} lg={2.3} md={3}>
          {/* Footer Column 1 */}
          {data.map((item, index) => {
            if (
              item.NavigationFor === "Seller Footer" &&
              item.FooterColumn === "Footer Column 1"
            ) {
              return (
                <Box sx={{ display: "flex" }} key={index}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Link
                        to={`/content/${item.PageUrl}`}
                        style={{
                          textDecoration: "none",
                          color: "gray",
                          fontSize: "15px",
                          marginBottom: "20px",
                        }}
                      >
                        {item.Link}
                      </Link>
                    </Box>
                  </Box>
                </Box>
              );
            }
            return null;
          })}
        </Grid>

        <Grid item xs={12} lg={2.3} md={3}>
          {/* Footer Column 2 */}
          {data.map((item, index) => {
            if (
              item.NavigationFor === "Seller Footer" &&
              item.FooterColumn === "Footer Column 2"
            ) {
              return (
                <Box sx={{ display: "flex" }} key={index}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Link
                        to={`/content/${item.PageUrl}`}
                        style={{
                          textDecoration: "none",
                          color: "gray",
                          fontSize: "15px",
                          marginBottom: "20px",
                        }}
                      >
                        {item.Link}
                      </Link>
                    </Box>
                  </Box>
                </Box>
              );
            }
            return null;
          })}
        </Grid>

        <Grid item xs={12} lg={2.3} md={3}>
          {/* Footer Column 3 */}
          {data.map((item) => {
            if (
              item.NavigationFor === "Seller Footer" &&
              item.FooterColumn === "Footer Column 3"
            ) {
              return (
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Link
                        to={`/content/${item.PageUrl}`}
                        style={{
                          textDecoration: "none",
                          color: "gray",
                          fontSize: "15px",
                          marginBottom: "20px",
                        }}
                      >
                        {item.Link}
                      </Link>
                    </Box>
                  </Box>
                </Box>
              );
            }
            return null;
          })}
        </Grid>
        <Grid item xs={12} lg={2.3} md={3}>
          {/* Footer Column 3 */}
          {data.map((item) => {
            if (
              item.NavigationFor === "Seller Footer" &&
              item.FooterColumn === "Footer Column 4"
            ) {
              return (
                <Box sx={{ display: "flex" }}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <Link
                        to={`/content/${item.PageUrl}`}
                        style={{
                          textDecoration: "none",
                          color: "gray",
                          fontSize: "15px",
                          marginBottom: "20px",
                        }}
                      >
                        {item.Link}
                      </Link>
                    </Box>
                  </Box>
                </Box>
              );
            }
            return null;
          })}
        </Grid>

        <Grid item xs={12} lg={2.8}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Tooltip title="Facebook">
              <IconButton>
                <FacebookIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Instagram">
              <IconButton>
                <InstagramIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Twitter">
              <IconButton>
                <TwitterIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Youtube">
              <IconButton>
                <YouTubeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="LinkedIn">
              <IconButton>
                <LinkedInIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "17px", mt: 1.2, ml: 1 }}>
              PAYMENT METHOD
            </Typography>
            <Box sx={{ mt: 1.2 }}>
              <img src={PaymentImage} alt="" width="100%" height="100%" />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Typography
        sx={{
          fontSize: "14px",
          color: "gray",
          p: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#f9f9f9",
        }}
      >
        © 2023 SkillShop Ecommerce Private Limited. All Rights Reserved
      </Typography>
    </div>
  );
};

export default Footer;
