import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import AppBarNav from "./components/Navbar";
import NavbarTop from "./components/NavbarTop";
import AddSingleListing from "./components/Products/AddSingleListing";
import ManagePriceAndStock from "./components/Products/ManagePriceAndStock";
import MyProductList from "./components/Products/MyProductList";
import UpdateSingleListing from "./components/Products/updateSingleListing/UpdateSingleListing";
import Register from "./components/Register";
import SellerAddBrand from "./components/SellerAddBrand";
import SellerDocuments from "./components/SellerDocuments";
import SellerInformation from "./components/SellerInformation";
import ThankYou from "./components/ThankYou";
import ThankYou1 from "./components/ThankYou1";
import ForgotPassword from "./components/ForgotPassword";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ManageImage from "./components/Products/ManageImage/ManageImage";
import PendingOrders from "./components/Orders/PendingOrders";
import axios from "axios";
import ManageOrder from "./components/Orders/ManageOrder";
import BulkUpload from "./components/BulkUpload";
import UploadProductImages from "./components/UploadProductImages";
import ReadyToShip from "./components/Orders/ReadyToShip";
import BuyerCancellation from "./components/Orders/BuyerCancellationTable";
import SellerCancellation from "./components/Orders/SellerCancellationTable";
import AcceptedOrderTable from "./components/Orders/AcceptedOrderTable";
import AdminCancellation from "./components/Orders/AdminCancellationTable";
import Shipped from "./components/Orders/SShippedTable";
import DeliveredTable from "./components/Orders/DeliveredTable";
import {
  getAllStaticPages,
  handleGetAllOrdersBySeller,
  handleGetAllSellerNavigations,
} from "./axios";
import AboutUs from "./components/StaticPages/AboutUs";
import FAQS from "./components/StaticPages/FAQS";
import TermsOfUse from "./components/StaticPages/TermsOfUse";
import SellerPolicy from "./components/StaticPages/SellerPolicy";
import ContactUs from "./components/StaticPages/ContactUs";
import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import ResetPassword from "./components/ResetPassword";
import DashboardPieChart1 from "./components/DashboardPieChart1";
import Footer from "./components/Footer/Footer";
import SellerOrderListAll from "./components/Orders/SellerOrderListAll";
import ManageProfile from "./components/ManageProfile/ManageProfile";
import ManageBrandTab from "./components/ManageProfile/ManageBrandTab";
import StockOverView from "./components/StockOverview/StockOverView";
import HomePageStatic from "./components/StaticPages/HomePageStatic";
import CommissionPayout from "./components/StaticPages/CommissionPayout";
import { useLayoutEffect } from "react";
import AccountofStateMent from "./components/payments/AccountofStateMent";
import OrderWiseAccountSummary from "./components/payments/OrderWiseAccountSummary";
import SellerQuery from "./components/sellerQueries/SellerQuery";
import RaiseQuery from "./components/sellerQueries/RaiseQuery";
import CustomerQuestions from "./components/customerQuestions/CustomerQuestions";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  const [data, setData] = useState([]);
  const [staticPages, setStaticPages] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [SellerNav, setSellerNav] = useState([]);

  const { token, user } = useSelector((state) => state.auth);

  const handleGetAllNavbarLinks = async () => {
    try {
      const res = await handleGetAllSellerNavigations();
      if (res) {
        setSellerNav(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllStaticPages = async () => {
    setLoading1(true);
    try {
      const res = await getAllStaticPages();
      if (res) {
        setStaticPages(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    Promise.all([handleGetAllStaticPages(), handleGetAllNavbarLinks()]);
  }, []);

  useEffect(() => {
    if (token && window.location.pathname === "/") {
      window.location.href = "/default/home";
    }
  }, [window.location.pathname, token]);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="App">
      {!Loading1 ? (
        <BrowserRouter>
          <Wrapper>
            <NavbarTop />
            <AppBarNav SellerNav={SellerNav} />
            <Routes>
              {staticPages.map((item) => {
                if (item.active === true) {
                  return (
                    <Route
                      exact
                      path={`content/${item.Page}`}
                      element={<FAQS data={item} Loading1={Loading1} />}
                    />
                  );
                }
              })}
              {!token ? (
                <>
                  <Route
                    exact
                    path="/sellerInformation/:id"
                    element={<SellerInformation />}
                  />
                  <Route
                    exact
                    path="/register"
                    element={
                      <Register theme={theme} isMediumScreen={isMediumScreen} />
                    }
                  />
                  <Route
                    exact
                    path="/forgot-password"
                    element={<ForgotPassword />}
                  />
                  <Route
                    exact
                    path="/content/home"
                    element={<HomePageStatic />}
                  />
                  <Route
                    exact
                    path="/"
                    element={
                      <Login
                        toast={toast}
                        theme={theme}
                        isMediumScreen={isMediumScreen}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/sellerDocuments"
                    element={
                      <SellerDocuments
                        theme={theme}
                        isMediumScreen={isMediumScreen}
                      />
                    }
                  />
                  <Route exact path="/addBrand" element={<SellerAddBrand />} />
                  <Route exact path="/thank-you" element={<ThankYou />} />
                  <Route exact path="/thank-you1" element={<ThankYou1 />} />
                  <Route
                    exact
                    path="/reset-password/:id"
                    element={<ResetPassword />}
                  />

                  <Route exact path={`/contact`} element={<ContactUs />} />
                  <Route
                    exact
                    path={`/payout`}
                    element={<CommissionPayout />}
                  />
                </>
              ) : (
                <>
                  <Route
                    exact
                    path="/default/home"
                    element={
                      <Dashboard
                        theme={theme}
                        isMediumScreen={isMediumScreen}
                      />
                    }
                  />
                  {/* Products */}

                  <Route
                    path="/add-single-listing-product"
                    element={<AddSingleListing />}
                  />
                  <Route path="/my-product-list" element={<MyProductList />} />
                  <Route
                    exact
                    path="/manage-price-stock/:id"
                    element={<ManagePriceAndStock />}
                  />
                  <Route
                    exact
                    path="/update-single-listing/:id"
                    element={<UpdateSingleListing />}
                  />
                  <Route
                    exact
                    path="/manage-image/:id"
                    element={<ManageImage />}
                  />
                  <Route
                    exact
                    path="/Pending-orders"
                    element={
                      <PendingOrders
                        data={data}
                        setData={setData}
                        Loading={Loading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/confirmed-orders"
                    element={<AcceptedOrderTable />}
                  />
                  <Route
                    exact
                    path="/ReadyToShip-orders"
                    element={
                      <ReadyToShip
                        data={data}
                        setData={setData}
                        Loading={Loading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/BuyerCancellation-orders"
                    element={
                      <BuyerCancellation
                        data={data}
                        setData={setData}
                        Loading={Loading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/AdminCancellation-orders"
                    element={
                      <AdminCancellation
                        data={data}
                        setData={setData}
                        Loading={Loading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/SellerCancellation-orders"
                    element={
                      <SellerCancellation
                        data={data}
                        setData={setData}
                        Loading={Loading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/Shipped-orders"
                    element={
                      <Shipped
                        data={data}
                        setData={setData}
                        Loading={Loading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/Delivered-orders"
                    element={
                      <DeliveredTable
                        data={data}
                        setData={setData}
                        Loading={Loading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/SellerOrder-list"
                    element={
                      <SellerOrderListAll
                        data={data}
                        setData={setData}
                        Loading={Loading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/manage-order/:id"
                    element={
                      <ManageOrder setData={setData} setRefresh={setRefresh} />
                    }
                  />
                  <Route
                    exact
                    path="/manageSeller-profile"
                    element={<ManageProfile />}
                  />
                  <Route
                    exact
                    path="/manageSeller-brand"
                    element={<ManageProfile />}
                  />
                  <Route
                    exact
                    path="/manageSeller-changepassword"
                    element={<ManageProfile />}
                  />
                  <Route
                    exact
                    path="/manageSeller-activeStore"
                    element={<ManageProfile />}
                  />
                  <Route
                    exact
                    path="/manageSeller-manageHoliday"
                    element={<ManageProfile />}
                  />
                  <Route exact path="/bulkUpload" element={<BulkUpload />} />
                  <Route
                    exact
                    path="/UploadProductImages"
                    element={<UploadProductImages />}
                  />
                  <Route
                    exact
                    path="/Inventory-manage"
                    element={<StockOverView />}
                  />
                  <Route
                    exact
                    path="/account-statement"
                    element={<AccountofStateMent />}
                  />
                  <Route
                    exact
                    path="/orderwise-account-statement"
                    element={<OrderWiseAccountSummary data={data} />}
                  />
                  <Route exact path="/SellerQuery" element={<SellerQuery />} />
                  <Route
                    exact
                    path="/RaiseQuery"
                    element={<RaiseQuery data={data} />}
                  />
                  <Route
                    exact
                    path="/CustomerQuestions"
                    element={<CustomerQuestions />}
                  />
                </>
              )}
            </Routes>
            {Loading && Loading1 ? (
              <Box>
                <CircularProgress />
              </Box>
            ) : (
              <Box className="footer-container">
                <Footer />
              </Box>
            )}
          </Wrapper>
        </BrowserRouter>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <ToastContainer />
    </div>
  );
}

export default App;
