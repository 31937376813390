import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { ResetSellerPassword } from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [Loading, setLoading] = useState(false);

  const [passwordError, setPasswordError] = useState("");
  const [RepasswordError, setRePasswordError] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      if (!password || !rePassword) {
        setPasswordError("Password is required");
        setRePasswordError("ReEnter-Password is required");
        return;
      }
      const res = await ResetSellerPassword(id, password, rePassword);
      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Password reset successfull",
          showConfirmButton: true,
        });
        navigate("/login", { replace: true });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleValidatePassword = (e) => {
    const value = e.target.value;
    if (value.length === 0) {
      setPasswordError("Password is Required");
    } else if (value.length < 8) {
      setPasswordError("Password should be at least 8 characters");
    } else {
      setPasswordError("");
    }
    setPassword(value);
  };
  const handleValidateRePassword = (e) => {
    const value = e.target.value;
    if (value.length === 0) {
      setRePasswordError("ReEnter Password is Required");
    } else if (password !== value) {
      setRePasswordError("Password did not match");
    } else if (value.length < 8) {
      setRePasswordError("Password should be at least 8 characters");
    } else {
      setRePasswordError("");
    }
    setRePassword(value);
  };
  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Grid item xs={12} lg={4.5} xl={5} md={4}>
          <Card elevation={0} sx={{ border: "1px solid #ddd", p: 2 }}>
            <Box>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Reset Password
              </Typography>
              <Typography
                sx={{
                  border: "2px solid #0099cc",
                  width: "60px",
                  margin: "auto",
                }}
              ></Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <TextField
                placeholder="Enter Password"
                type="password"
                fullWidth
                variant="outlined"
                label="Enter Password"
                value={password}
                onChange={handleValidatePassword}
                error={passwordError}
                helperText={passwordError}
                sx={{ mb: 2 }}
              />
              <TextField
                placeholder="Re-Enter Password"
                fullWidth
                variant="outlined"
                label="Re-Enter Password"
                type="password"
                value={rePassword}
                onChange={handleValidateRePassword}
                error={RepasswordError}
                helperText={RepasswordError}
                sx={{ mb: 2 }}
              />
              <Button
                sx={{ p: 1.3 }}
                variant="contained"
                fullWidth
                onClick={handleResetPassword}
                disabled={Loading}
              >
                {Loading ? <CircularProgress size={24} /> : "SUBMIT"}
              </Button>
            </Box>
          </Card>
          <Typography
            sx={{
              fontSize: "17px",
              fontWeight: "bold",
              textAlign: "center",
              mt: 2,
            }}
            variant="subtitle2"
            gutterBottom
          >
            Copyright © 2023, skillshop.in, All Rights Reserved
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPassword;
