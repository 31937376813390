import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const FAQS = ({ data, Loading1 }) => {
  const [faqs, setFaqs] = useState([]);
  const [paragraphs, setParagraphs] = useState([]);
  const [Withoutparagraphs, setWithoutParagraphs] = useState([]);
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    const parser = new DOMParser();
    const html = parser.parseFromString(data.Description, "text/html");
    const paragraphElements = html.getElementsByTagName("p");
    const tableElement = html.getElementsByTagName("table")[0];

    const faqArray = Array.from(paragraphElements)
      .filter((element) => element.textContent.trim().endsWith("?"))
      .map((element, index, array) => {
        const question = element.innerHTML.trim();
        const answerElement = element.nextElementSibling;
        const answer = answerElement ? answerElement.innerHTML : "";
        return {
          question,
          answer,
          textBefore:
            index === 0
              ? array[0].previousElementSibling.innerHTML
              : element.previousElementSibling.innerHTML,
        };
      });

    const paragraphsWithoutQuestionAnswer = Array.from(paragraphElements)
      .filter((element) => {
        return !faqArray.some(
          (faq) =>
            faq.question === element.innerHTML.trim() ||
            faq.answer === element.innerHTML.trim()
        );
      })
      .map((element) => element.innerHTML.trim());

    const paragraphArray = Array.from(paragraphElements)
      .filter((element) => !element.textContent.trim().endsWith("?"))
      .map((element) => element.innerHTML.trim());

    setFaqs(faqArray);
    setParagraphs(paragraphArray);
    setWithoutParagraphs(paragraphsWithoutQuestionAnswer);

    if (tableElement) {
      const tableRows = tableElement.getElementsByTagName("tr");
      const headers = Array.from(tableRows[0].getElementsByTagName("th")).map(
        (header) => header.textContent.trim()
      );
      const rows = Array.from(tableRows)
        .slice(1)
        .map((row) =>
          Array.from(row.getElementsByTagName("td")).map((cell) =>
            cell.textContent.trim()
          )
        );
      setTableData({ headers, rows });
    } else {
      setTableData(null);
    }
  }, [data.Description]);

  useEffect(() => {
    return () => {
      setTableData(null); // Reset tableData when component unmounts
    };
  }, []);

  return (
    <>
      {!Loading1 ? (
        <div style={{ marginTop: "80px" }}>
          <Box>
            <Typography
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
            >
              {data.Heading.toUpperCase()}
            </Typography>
            <Typography
              sx={{
                border: "2px solid #0099cc",
                width: "60px",
                margin: "auto",
              }}
            ></Typography>
          </Box>
          {tableData && (
            <Box sx={{ mt: 5, ml: 3, mr: 3, mb: 3 }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead sx={{ background: "#eeee" }}>
                    <TableRow>
                      {tableData.headers.map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontWeight: "600",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.rows.map((row, index) => (
                      <TableRow key={index}>
                        {row.map((cell, cellIndex) => (
                          <TableCell
                            key={cellIndex}
                            sx={{
                              borderRight: "1px solid #ddd",
                              width: "0px",
                            }}
                          >
                            {cell}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          {!tableData && faqs.length > 0 && (
            <Box sx={{ mt: 5, ml: 3, mr: 3, mb: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                  mb: 2,
                }}
              >
                {Withoutparagraphs.map((paragraph, index) => (
                  <Typography
                    key={index}
                    variant="subtitle1"
                    gutterBottom
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                  ></Typography>
                ))}
              </Box>
              {faqs.map((faq, index) => (
                <>
                  <Accordion key={index}>
                    <AccordionSummary>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: faq.question }}
                      ></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      ></Typography>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
            </Box>
          )}
          {!tableData && faqs.length === 0 && (
            <Box sx={{ ml: 3, mr: 3, mt: 5 }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "30px" }}
              >
                {paragraphs.map((paragraph, index) => (
                  <Typography
                    key={index}
                    variant="subtitle1"
                    gutterBottom
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                  ></Typography>
                ))}
              </Box>
            </Box>
          )}
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default FAQS;
