import React from "react";
import MyProductListTable from "./MyProductListTable";

const MyProductList = () => {
  return (
    <div style={{ marginTop: "40px" }}>
      <MyProductListTable />
    </div>
  );
};

export default MyProductList;
