import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useNavigate } from "react-router-dom";
import { FcEditImage } from "react-icons/fc";
import { handleGetAllProductsBySeller } from "../../axios";

const columns = [
  { id: "sno", label: "S NO", minWidth: 40, align: "left" },
  { id: "image", label: "Image", minWidth: 50, align: "left" },
  {
    id: "SkuCode",
    label: "Product SKU Code.",
    minWidth: 70,
    align: "left",
  },
  {
    id: "BrandName",
    label: "Brand Name.",
    minWidth: 60,
    align: "left",
  },
  {
    id: "Title",
    label: "Product Name",
    minWidth: 60,
    align: "left",
  },
  {
    id: "createdAt",
    label: "Date of Upload",
    minWidth: 60,
    align: "left",
  },
  {
    id: "UniqueUrl",
    label: "Unique Url",
    minWidth: 90,
    align: "left",
  },
  {
    id: "PreBook",
    label: "Prebook",
    minWidth: 60,
    align: "left",
  },
  {
    id: "Status",
    label: "Status",
    minWidth: 60,
    align: "left",
  },
  {
    id: "Reason",
    label: "Reason",
    minWidth: 70,
    align: "left",
  },
  {
    id: "ComboProduct",
    label: "Combo Product",
    minWidth: 60,
    align: "left",
  },
  {
    id: "manageProduct",
    label: "Manage Product",
    minWidth: 150,
    align: "left",
  },
];

export default function MyProductListTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [data, setData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchQueryforName, setSearchQueryforName] = React.useState("");
  const [status, setStatus] = React.useState("All");
  const [loading, setLoading] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("");

  const [selected, setSelected] = React.useState("All");

  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { token } = useSelector((state) => state.auth);
  let filteredProductsBySort = [];

  if (status === "All") {
    filteredProductsBySort = data;
  } else if (status === "Pending") {
    filteredProductsBySort = data.filter((item) => item.active === false);
  } else if (status === "Approved") {
    filteredProductsBySort = data.filter((item) => item.active === true);
  } else if (status === "Rejected") {
    filteredProductsBySort = data.filter((item) => item.rejected === true);
  }

  const handleGetProductsByMe = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await handleGetAllProductsBySeller(config);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleGetProductsByMe();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleSearchforName = (e) => {
    setSearchQueryforName(e.target.value);
  };

  const filterData = () => {
    const newData = data.filter((row) =>
      row?.SKUCode?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setData(newData);
  };
  const filterDataforName = () => {
    const newData = data.filter((row) =>
      row?.Title?.toLowerCase().includes(searchQueryforName.toLowerCase())
    );
    setData(newData);
  };

  const convertUTCtoIST = (utcDate) => {
    const date = new Date(utcDate);
    // const istOffset = 330; // IST offset in minutes
    // const istTime = new Date(date.getTime() + istOffset * 60 * 1000);
    return date.toLocaleDateString();
  };

  const filterDataByStatus = (data, status) => {
    return data.filter((row) =>
      row.active === true ? (status = "Approved") : (status = "Pending")
    );
  };

  const convertToCSV = (data) => {
    const rows = [];
    const headers = Object.keys(data[0]);

    rows.push(headers.join(","));

    data.forEach((row) => {
      const values = headers.map((header) => row[header]);
      rows.push(values.join(","));
    });

    return rows.join("\n");
  };

  const handleExport = () => {
    const filteredData = filterDataByStatus(data, false);
    console.log(filteredData);
    const csv = convertToCSV(filteredData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${status}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ px: 4 }} justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Box>
            <Typography
              sx={{
                fontSize: "23px",
                fontWeight: "bold",
                textAlign: "center",
                mt: 5,
              }}
            >
              Product List
            </Typography>
            <Typography
              sx={{
                border: "2px solid #3482bc",
                width: "60px",
              }}
              style={{ margin: "auto" }}
            ></Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <Typography sx={{ fontSize: "17px" }}>
                Total Items :{" "}
                <span style={{ color: "#0099cc" }}>{data.length}</span>
              </Typography>
              <Grid item xs={12} lg={7} md={7}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                    justifyContent: "end",
                  }}
                >
                  <Grid item xs={12} lg={4} md={4}>
                    <Select
                      displayEmpty
                      size="small"
                      sx={{ height: "40px" }}
                      fullWidth
                    >
                      <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Approved">Approved</MenuItem>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                      <MenuItem value="Low Inventory">Low Inventory</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} lg={3} md={4}>
                    <Button
                      variant="contained"
                      sx={{ height: "40px", mr: 4 }}
                      disableElevation
                      onClick={handleExport}
                      fullWidth
                    >
                      Export To Excel
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                  <Grid item xs={12} lg={3.3}>
                    <TextField
                      placeholder="Product SKU Code"
                      size="small"
                      value={searchQuery}
                      fullWidth
                      onChange={handleSearch}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3.3}>
                    <TextField
                      placeholder="Product Name"
                      size="small"
                      value={searchQueryforName}
                      fullWidth
                      onChange={handleSearchforName}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      onClick={() => {
                        if (searchQuery !== "") {
                          filterData();
                        } else if (searchQueryforName !== "") {
                          filterDataforName();
                        }
                      }}
                    >
                      SUBMIT
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      onClick={() => {
                        setSearchQuery("");
                        setSearchQueryforName("");
                        handleGetProductsByMe();
                      }}
                    >
                      CLEAR
                    </Button>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mt: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "end",
                  }}
                >
                  <Grid item xs={12} lg={3}>
                    <Typography>Product Sorting : </Typography>
                  </Grid>
                  <Grid item xs={12} lg={7}>
                    <Select
                      displayEmpty
                      size="small"
                      fullWidth
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <InputLabel sx={{ ml: 2 }}>Select</InputLabel>
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Approved">Approved</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                    </Select>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Grid>

        {!loading ? (
          <>
            {data && data.length > 0 ? (
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  mt: 3,
                  border: "1px solid #ddd",
                }}
                elevation={0}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              background: "#eee",
                              fontWeight: "bold",
                              borderRight: "1px solid #ddd",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredProductsBySort
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const sequentialNumber =
                            index + 1 + page * rowsPerPage;
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row._id}
                            >
                              <TableCell
                                style={{ borderRight: "1px solid #ddd" }}
                                align="right"
                              >
                                {sequentialNumber}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ borderRight: "1px solid #ddd" }}
                              >
                                <img
                                  src={row.MainImage}
                                  alt="Product"
                                  width="80"
                                />
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ borderRight: "1px solid #ddd" }}
                              >
                                {row.SKUCode}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ borderRight: "1px solid #ddd" }}
                              >
                                {row.BrandName}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ borderRight: "1px solid #ddd" }}
                              >
                                {row.Title}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ borderRight: "1px solid #ddd" }}
                              >
                                {convertUTCtoIST(row.createdAt)}
                              </TableCell>{" "}
                              {/* modified to display IST time */}
                              <TableCell
                                align="left"
                                style={{ borderRight: "1px solid #ddd" }}
                              >
                                {row.UniqueUrl}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ borderRight: "1px solid #ddd" }}
                              >
                                {row.PreBook ? "Yes" : "No"}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ borderRight: "1px solid #ddd" }}
                              >
                                {row.active && row.active === true
                                  ? "Approved"
                                  : "Pending"}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ borderRight: "1px solid #ddd" }}
                              >
                                --
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ borderRight: "1px solid #ddd" }}
                              >
                                {row.ComboProduct ? "Yes" : "No"}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ borderRight: "1px solid #ddd" }}
                              >
                                <IconButton>
                                  <EditIcon
                                    onClick={() =>
                                      navigate(
                                        `/update-single-listing/${row._id}`
                                      )
                                    }
                                  />
                                </IconButton>
                                <Tooltip title="manage Price & Stock">
                                  <IconButton
                                    onClick={() =>
                                      navigate(`/manage-price-stock/${row._id}`)
                                    }
                                  >
                                    <MonetizationOnIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Manage Image">
                                  <IconButton
                                    onClick={() =>
                                      navigate(`/manage-image/${row._id}`)
                                    }
                                  >
                                    <FcEditImage />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    p: 2,
                    background: "#eee",
                    width: "300px",
                    textAlign: "center",
                  }}
                >
                  No Data Found
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </>
  );
}
