import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import { updateColorImages } from "../../../axios";

const ManageImage = () => {
  const [data, setData] = useState({});
  const [colors, setColors] = useState([]);
  const [uploadedImages, setUploadedImages] = useState({});
  const [videoUrls, setVideoUrls] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [mainPreview, setMainPreview] = useState({});
  const [fileError, setFileError] = useState(false);
  const [formData, setFormData] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [Loading2, SetLoading2] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();

  const navigate = useNavigate();

  const getSingleListingData = async () => {
    setLoading1(true);
    try {
      const res = await axios.get(
        `https://rccore-backend.cstechns.com/api/admin/get-single-product/${id}`
      );
      if (res) {
        // console.log(res.data.ProductImages["Red"]);
        setData(res.data);
        setColors(res.data.Colors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    getSingleListingData();
  }, [id, refresh]);

  const handleImageUpload = (files, color) => {
    const uploaded = uploadedImages[color] || [];
    uploaded.push(...files);
    setUploadedImages({ ...uploadedImages, [color]: uploaded });
  };

  console.log(formData);

  const handleDeleteImage = (index, color) => {
    const images = [...uploadedImages[color]];
    images.splice(index, 1);
    setUploadedImages({ ...uploadedImages, [color]: images });
  };
  const handleDeleteImage1 = (index, color) => {
    const images = [...mainPreview[color]];
    images.splice(index, 1);
    setMainPreview({ ...mainPreview, [color]: images });
  };

  function extractImageKeyFromURL(url) {
    // Remove the protocol and domain parts of the URL
    const path = url.split(".com/")[1];

    // Extract the image key from the path
    const imageKey = decodeURIComponent(path);

    return imageKey;
  }
  const handleDeleteImage2 = async (index, color) => {
    const imageToDelete = data.ProductImages[color][index];
    const res = await axios.put(
      `https://rccore-backend.cstechns.com/api/seller/Products/deleteImagefromS3/${id}`,
      {
        color,
        imageToDelete,
        imageKey: extractImageKeyFromURL(imageToDelete),
      }
    );
    if (res) {
      Swal.fire({
        position: "center",
        icon: "success",
        text: "Product Image Deleted successfully",
        showConfirmButton: true,
      });

      const updatedData = { ...data }; // Create a copy of the data object
      updatedData.ProductImages[color] = updatedData.ProductImages[
        color
      ].filter((_, i) => i !== index);

      setData(updatedData);
    }
  };

  const Dropzone = ({ color }) => {
    const { getRootProps, getInputProps } = useDropzone({
      multiple: true,
      maxSize: 1 * 1024 * 1024, // 1MB in bytes
      onDrop: (acceptedFiles) => handleImageUpload(acceptedFiles, color),
      onDropRejected: () => setFileError(true),
      onDropAccepted: () => setFileError(false),
    });

    return (
      <div
        {...getRootProps()}
        style={{
          width: "100%",
          height: "200px",
          border: `2px dashed ${fileError ? "red" : "#ddd"}`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          marginBottom: "10px",
        }}
      >
        <input {...getInputProps()} />
        <Typography
          variant="subtitle1"
          color={fileError ? "error" : "textSecondary"}
        >
          {fileError
            ? `File size exceeds 1MB limit`
            : `Click or drag images here to upload for ${color} (max size: 1MB)`}
        </Typography>
      </div>
    );
  };

  const handleOpenDialog = (color) => {
    setSelectedColor(color);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedColor("");
    setUploadedImages({});
    setIsDialogOpen(false);
  };

  const handleSubmit = () => {
    setMainPreview((prevImages) => {
      const newImages = { ...prevImages };

      for (const color in uploadedImages) {
        const files = uploadedImages[color];

        // Check if data.ProductImages already exists for the color

        newImages[color] = [...files];
      }

      return newImages;
    });

    handleCloseDialog();
  };

  const handleSubmit1 = async () => {
    SetLoading2(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const formData = new FormData();

      for (const color in mainPreview) {
        const files = mainPreview[color];

        if (Array.isArray(files)) {
          // Handle multiple images for the color
          files.forEach((file, index) => {
            formData.append(`${color}`, file);
          });
        } else {
          // Handle a single image for the color
          formData.append(`${color}`, files);
        }
      }

      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/seller/Products/uploadColorImages/${id}`,
        formData,
        config
      );

      if (res) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Product Images uploaded successfully",
          showConfirmButton: true,
        });
        navigate("/my-product-list");
      }
    } catch (error) {
      console.log(error);
    } finally {
      SetLoading2(false);
    }
  };

  const handleUploadUrls = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/seller/Products/ManageProductVideoUrls/${id}`,
        {
          urls: data.videoUrls,
        },

        config
      );
      if (res) {
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        position: "center",
        icon: "success",
        text: "Error while uploading the images",
        showConfirmButton: true,
      });
    }
  };

  return (
    <div style={{ marginTop: "80px" }}>
      <Box>
        <Box>
          <Typography
            variant="h6"
            sx={{ fontSize: "20px", fontWeight: "600", textAlign: "center" }}
          >
            Upload Multiple Images
          </Typography>

          <Typography
            sx={{
              border: "2px solid #0099CC",
              width: "60px",
              margin: "auto",
              mt: 0.5,
            }}
          ></Typography>
          <Typography sx={{ textAlign: "end", mt: 2, mb: 3, mr: 3 }}>
            <Button
              variant="contained"
              sx={{ width: "200px" }}
              disableElevation
              onClick={() => {
                handleSubmit1();
                handleUploadUrls();
              }}
              disabled={Loading2}
            >
              {Loading2 ? <CircularProgress size={24} /> : "SUBMIT"}
            </Button>
          </Typography>
        </Box>
        {!loading1 ? (
          <>
            <Grid container spacing={2} sx={{ px: 3 }}>
              <Grid item xs={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "200px",
                      color: "gray",
                    }}
                  >
                    Name :
                  </Typography>
                  <Typography>{data.Title}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",

                    mt: 2,
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "200px",
                      color: "gray",
                    }}
                  >
                    Sku Code :
                  </Typography>
                  <Typography>{data.SkuCode}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",

                    mt: 2,
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "180px",
                      color: "gray",
                    }}
                  >
                    Image :
                  </Typography>
                  <Box sx={{ width: "120px" }}>
                    <img
                      src={data.MainImage}
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Box sx={{ display: "flex" }}>
                  <Typography>Note:</Typography>
                  <Typography variant="subtitle1" sx={{ color: "red" }}>
                    Please submit your draggable images, then upload new images.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography>Note:</Typography>
                  <Typography variant="subtitle1" sx={{ color: "red" }}>
                    The file size should be below 300 KB and resolution should
                    be 600 (pixel) width & 900 (pixel) height.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography>Note:</Typography>
                  <Typography variant="subtitle1" sx={{ color: "red" }}>
                    Use the product image which is less than 1 MB.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography>Note:</Typography>
                  <Typography variant="subtitle1" sx={{ color: "red" }}>
                    Click on Upload images for color(ex:pink) button to upload
                    images for particular color.
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} lg={12} xl={12}>
                <Box>
                  {colors.map((color) => (
                    <Box>
                      <Box sx={{ display: "flex", mt: 3, mb: 3 }}>
                        <Button
                          key={color}
                          variant="contained"
                          sx={{ mr: 2, color: "white", width: "300px" }}
                          style={{ backgroundColor: "#000" }}
                          onClick={() => handleOpenDialog(color)}
                          disableElevation
                        >
                          Upload Images For {color}
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #ddd",
                          height: "50vh",
                          backgroundColor: "#eeeeee",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "20px",

                          mt: 1,
                          p: 2,
                          mb: 3,
                        }}
                      >
                        {data?.ProductImages &&
                          Object.keys(data?.ProductImages).length > 0 && (
                            <>
                              {data?.ProductImages[color]?.length > 0 && (
                                <>
                                  {data?.ProductImages[color].map(
                                    (item, index) => (
                                      <Box
                                        key={index}
                                        sx={{
                                          width: "100px",
                                          height: "100px",
                                          display: "flex",
                                          flexWrap: "wrap",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={item}
                                          alt=""
                                          width="100%"
                                          height="100%"
                                        />
                                        <Box>
                                          <Button
                                            sx={{
                                              background: "red",
                                              width: "100px",
                                              mt: 1,
                                            }}
                                            variant="contained"
                                            disableElevation
                                            onClick={() =>
                                              handleDeleteImage2(index, color)
                                            }
                                          >
                                            Delete
                                          </Button>
                                        </Box>
                                      </Box>
                                    )
                                  )}
                                </>
                              )}
                            </>
                          )}

                        {Object.keys(mainPreview).length > 0 && (
                          <>
                            {mainPreview[color]?.length > 0 && (
                              <>
                                {mainPreview[color].map((item, index) => (
                                  <Box
                                    key={index}
                                    sx={{
                                      width: "100px",
                                      height: "100px",
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={URL.createObjectURL(item)}
                                      alt=""
                                      width="100%"
                                      height="100%"
                                    />
                                    <Box>
                                      <Button
                                        sx={{
                                          background: "red",
                                          width: "100px",
                                          mt: 1,
                                        }}
                                        variant="contained"
                                        disableElevation
                                        onClick={() =>
                                          handleDeleteImage1(index, color)
                                        }
                                      >
                                        Delete
                                      </Button>
                                    </Box>
                                  </Box>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={11} lg={4} xl={4}>
                <Box sx={{ mb: 3, mt: 2 }}>
                  <Typography
                    sx={{ fontSize: "15px", fontWeight: "bold", mb: 1 }}
                  >
                    Enter Video URL's
                  </Typography>
                  <textarea
                    placeholder="Enter Video Urls For Product"
                    style={{ height: "200px", width: "95%" }}
                    value={data?.videoUrls?.join(",") || ""}
                    onChange={(e) => {
                      const urls = e.target.value.split(",");
                      setData({ ...data, videoUrls: urls });
                    }}
                  ></textarea>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>{`Upload Images for ${selectedColor}`}</DialogTitle>
        <DialogContent>
          <Dropzone color={selectedColor} />
          {uploadedImages[selectedColor] &&
            uploadedImages[selectedColor].map((file, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  width: "100px",
                  height: "100px",
                }}
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Uploaded `}
                  width="100%"
                  height="100%"
                />
                <Button
                  color="secondary"
                  onClick={() => handleDeleteImage(index, selectedColor)}
                  sx={{ ml: 1 }}
                >
                  Delete
                </Button>
              </Box>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageImage;
