import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MyDrawer from "./Drawer1";
import logo from "../images/logo.png";

export default function NavbarTop() {
  const { token, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    const res = dispatch(logout());
    if (res) {
      localStorage.clear();
    }
    navigate("/");
  };
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <ThemeProvider theme={theme}>
      {!isMediumScreen ? (
        <Box
          sx={{
            flexGrow: 1,
            position: "sticky",
            top: 0,
            zIndex: 999,
          }}
        >
          <Box
            sx={{
              background: "#0099cc",
            }}
          >
            <Toolbar sx={{ display: "flex", alignItems: "center" }}>
              {isMediumScreen && <MyDrawer />}
              <Typography sx={{ flexGrow: 1 }}>
                <Box sx={{ width: "120px" }}>
                  <img
                    src={logo}
                    width="100%"
                    height="100%"
                    alt="logo"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                </Box>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  mr: 2,
                }}
              >
                <Box>
                  <AccountCircleIcon sx={{ color: "#fff", mt: 0.7 }} />
                </Box>
                <Box>
                  {!token ? (
                    <Link
                      to="/"
                      style={{ color: "#fff", textDecoration: "none" }}
                    >
                      Login
                    </Link>
                  ) : (
                    <Typography sx={{ color: "#fff" }}>
                      Hi {user.fullName}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography
                    style={{
                      border: "1px solid #fff",
                      height: "19px",
                    }}
                    sx={{ mt: 0.3 }}
                  ></Typography>
                </Box>
                <Box>
                  {!token ? (
                    <Link
                      to="/register"
                      style={{ color: "#fff", textDecoration: "none" }}
                    >
                      SignUp
                    </Link>
                  ) : (
                    <Typography
                      onClick={() => handleLogout()}
                      style={{
                        color: "#fff",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      LogOut
                    </Typography>
                  )}
                </Box>
              </Box>
            </Toolbar>
          </Box>
          {/* <Navbar /> */}
        </Box>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            position: "sticky",
            top: 0,
            zIndex: 999,
          }}
        >
          <AppBar
            sx={{
              background: "#0099cc",
            }}
            elevation={1}
          >
            <Toolbar sx={{ display: "flex", alignItems: "center" }}>
              {isMediumScreen && <MyDrawer />}
              <Typography sx={{ flexGrow: 1 }}>
                <Box sx={{ width: "120px" }}>
                  <img
                    src={logo}
                    width="100%"
                    height="100%"
                    alt="logo"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                </Box>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  mr: 2,
                }}
              >
                <Box>
                  <AccountCircleIcon sx={{ color: "#fff" }} />
                </Box>
                <Box>
                  {!token ? (
                    <Link
                      to="/"
                      style={{ color: "#fff", textDecoration: "none" }}
                    >
                      Login
                    </Link>
                  ) : (
                    <Typography sx={{ color: "#fff" }}>
                      Hi {user.fullName}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography
                    style={{ border: "1px solid #fff", height: "15px" }}
                    sx={{ mt: 0.5 }}
                  ></Typography>
                </Box>
                <Box>
                  {!token ? (
                    <Link
                      to="/register"
                      style={{ color: "#fff", textDecoration: "none" }}
                    >
                      SignUp
                    </Link>
                  ) : (
                    <Typography
                      onClick={() => handleLogout()}
                      style={{
                        color: "#fff",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      LogOut
                    </Typography>
                  )}
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
          {/* <Navbar /> */}
        </Box>
      )}
    </ThemeProvider>
  );
}
