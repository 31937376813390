import { BsDot, BsQuestionSquare } from "react-icons/bs";

import { TbSeo, TbTruckReturn } from "react-icons/tb";
import {
  MdOutlineInventory2,
  MdOutlineLocalShipping,
  MdOutlinePayments,
  MdPayment,
} from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";

const navigations = [
  {
    name: "PRODUCTS",
    icon: <MdOutlineInventory2 size={24} />,
    children: [
      {
        name: "Search Product (Select & Sell)",
      },
      {
        name: "Add Single Listing",
        to: "/add-single-listing-product",
      },
      {
        name: "Product List",
        to: "/my-product-list",
      },
      {
        name: "Stock Overview",
        to: "/Inventory-manage",
      },

      {
        name: "Multiple Bulk Upload",
        to: "/bulkUpload",
      },
    ],
  },
  {
    name: "ORDERS",
    icon: <MdOutlineLocalShipping size={24} />,
    children: [
      {
        name: "PreBook Orders",
      },
      {
        name: "Pending Orders",
        to: "/Pending-orders",
      },
      {
        name: "Confirm Orders",
        to: "/confirmed-orders",
      },
      {
        name: "Ready To Ship",
        to: "/ReadyToShip-orders",
      },
      {
        name: "Shipped",
        to: "/Shipped-orders",
      },
      {
        name: "Delivered",
        to: "/Delivered-orders",
      },
      {
        name: "Buyer Cancellation",
        to: "/BuyerCancellation-orders",
      },
      {
        name: "Seller Cancellation",
        to: "/SellerCancellation-orders",
      },
      {
        name: "Admin Cancellation",
        to: "/AdminCancellation-orders",
      },
      {
        name: "Refund",
      },
      {
        name: "SLA Breach",
      },
    ],
  },
  {
    name: "RETURNS",
    icon: <TbTruckReturn size={24} />,
    children: [
      {
        name: "Return List",
      },
    ],
  },
  {
    name: "SEO",
    icon: <TbSeo size={24} />,
    children: [
      {
        name: "Product SEO",
      },
    ],
  },
  {
    name: "PAYMENTS",
    icon: <MdOutlinePayments size={24} />,
    children: [
      {
        name: "Statement Of Account",
        to: "/account-statement",
      },
      {
        name: "OrderWise Account Summary",
        to: "/orderwise-account-statement",
      },
      {
        name: "Invoice List",
      },
      {
        name: "Order Sales Report",
      },
      {
        name: "Payment Statement Report",
      },
      {
        name: "TDS Statement Report",
      },
      {
        name: "TCS Statement Report",
      },
    ],
  },
  {
    name: "PROFILE",
    icon: <AiOutlineUser size={24} />,
    children: [
      {
        name: "Manage Profile",
        to: "/manageSeller-profile",
      },
      {
        name: "Manage Brand",
        to: "/manageSeller-brand",
      },
      {
        name: "Change Password",
        to: "/manageSeller-changepassword",
      },
      {
        name: "Active/Inactive Store",
        to: "/manageSeller-activeStore",
      },
      {
        name: "Manage Holiday",
        to: "/manageSeller-manageHoliday",
      },
      {
        name: "Manage SubUser",
      },
      {
        name: "Raise Query",
        to: "/SellerQuery",
      },
      {
        name: "Size Chart List",
      },
    ],
  },
  {
    name: "COMMISSION PAYOUT",
    icon: <MdPayment size={24} />,
    children: [
      {
        name: "Commission Payout",
      },
    ],
  },
  {
    name: "CUSTOMER'S QUESTIONS",
    icon: <BsQuestionSquare size={22} />,
    children: [
      {
        name: "Customers Questions",
        to: "/CustomerQuestions",
        // icon: <BsDot />,
      },
    ],
  },
];

export default navigations;
