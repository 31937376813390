import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { getQueriesforSellerBYId } from "../../axios";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function SellerQueryTable({ data }) {
  function convertUTCtoIST(utcTimestamp) {
    const utcDate = new Date(utcTimestamp);
    const istOptions = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const istTimestamp = utcDate.toLocaleString("en-IN", istOptions);
    return istTimestamp;
  }
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ border: "1px solid #ddd" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: "#eee" }}>
          <TableRow>
            <TableCell
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              S.No
            </TableCell>
            <TableCell
              align="left"
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              OrderNo
            </TableCell>
            <TableCell
              align="left"
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              QueryDate & Time
            </TableCell>
            <TableCell
              align="left"
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              OrderDate(MM/DD/YYYY)
            </TableCell>
            <TableCell
              align="left"
              sx={{
                borderRight: "1px solid #ddd",
                fontWeight: "bold",
                width: "500px",
              }}
            >
              Description
            </TableCell>
            <TableCell
              align="left"
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              Order Status{" "}
            </TableCell>
            <TableCell
              align="left"
              sx={{ borderRight: "1px solid #ddd", fontWeight: "bold" }}
            >
              Query Status{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                component="th"
                scope="row"
                sx={{ borderRight: "1px solid #ddd" }}
              >
                {index + 1}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {row.orderNo}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {convertUTCtoIST(row.createdAt)}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {row.orderDate}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {row.Description}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {row.orderStatus}
              </TableCell>
              <TableCell align="left" sx={{ borderRight: "1px solid #ddd" }}>
                {row.QueryStatus}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
