import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import AccountofStatementTable from "./AccountofStatementTable";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { handleGetAllOrdersBySeller } from "../../axios";
import { useSelector } from "react-redux";

const AccountofStateMent = () => {
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState(data);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const { token, user } = useSelector((state) => state.auth);
  const [Loading, setLoading] = useState(false);

  const getAllOrders = async () => {
    setLoading(true);
    try {
      const res = await handleGetAllOrdersBySeller(user?._id);

      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  function formatDateInTimeZone(dateStr) {
    const date = new Date(dateStr);
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      timeZone: "Asia/Kolkata",
    };

    return date.toLocaleDateString("en-US", options);
  }

  function convertDateFormat(dateString) {
    const [day, month, year] = dateString.split("/");

    // Create a new Date object with the year, month, and day values
    const date = new Date(year, month - 1, day);

    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    return formattedDate;
  }

  const handleSort = () => {
    const filteredData = data.filter((item) => {
      const orderDate = new Date(item.orderPaymentDate);

      return (
        convertDateFormat(item.orderPaymentDate) >=
          formatDateInTimeZone(fromDate) &&
        convertDateFormat(item.orderPaymentDate) <= formatDateInTimeZone(toDate)
      );
    });

    setSortedData(filteredData);
  };

  return (
    <div style={{ marginTop: "80px" }}>
      <Box>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
        >
          Statement of Account
        </Typography>
        <Typography
          sx={{ border: "2px solid #0099cc", width: "60px", margin: "auto" }}
        ></Typography>
      </Box>
      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12} lg={7}>
          <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "100px",
                }}
              >
                From Date:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    value={fromDate}
                    onChange={(date) => setFromDate(date)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "100px",
                }}
              >
                To Date:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    value={toDate}
                    onChange={(date) => setToDate(date)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Button
              variant="contained"
              sx={{ width: "200px", mt: 0.5 }}
              disableElevation
              onClick={handleSort}
              disabled={!fromDate || !toDate}
            >
              SUBMIT
            </Button>
          </Box>
        </Grid>
        {!Loading ? (
          <>
            {sortedData.length > 0 ? (
              <Grid item xs={12} lg={12}>
                <AccountofStatementTable data={sortedData} />
              </Grid>
            ) : (
              <Grid item xs={12} lg={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <Typography
                    sx={{
                      border: "1px solid #ddd",
                      minWidth: "600px",
                      p: 2,
                      textAlign: "center",
                      background: "#eee",
                      fontWeight: "bold",
                    }}
                  >
                    No Result Found
                  </Typography>
                </Box>
              </Grid>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </div>
  );
};

export default AccountofStateMent;
