import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import { useState } from "react";
import { Key } from "@mui/icons-material";
import { getSingleProductById, storeCalculation } from "../../axios";
import { useEffect } from "react";

const columns = [
  {
    id: "ProductParticular",
    label: "Product Details",
    minWidth: 130,
    align: "center",
  },
  {
    id: "MRP",
    label: "MRP",
    minWidth: 90,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "OfferPrice",
    label: "Offer Price (inc GST)",
    minWidth: 90,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "taxonProduct",
    label: "Tax on Product (inclusive)",
    minWidth: 70,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Commission",
    label: "Commission",
    minWidth: 20,
    align: "center",
    format: (value) => value.toFixed(2),
  },

  {
    id: "ClosingFee ",
    label: "Closing Fee ",
    minWidth: 40,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "shippingcharges",
    label: "Shipping Charges(Subjective to Changes)",
    minWidth: 10,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "paymentprocessing",
    label: "Payment Processing Charges",
    minWidth: 40,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "GST",
    label: "GST",
    minWidth: 20,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "sellerpayout",
    label: "Seller Payout",
    minWidth: 30,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "AvailStock",
    label: "Avail Stock ",
    minWidth: 20,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "AddStock",
    label: "Add Stock ",
    minWidth: 60,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "RemoveStock",
    label: "Remove Stock ",
    minWidth: 60,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "MakeDefaultSet ",
    label: "Make Default Set ",
    minWidth: 40,
    align: "center",
    format: (value) => value.toFixed(2),
  },
];

export default function ApprovePriceTable({
  priceData,
  IGST,
  CGST,
  SGST,
  IgstValue,
  setPriceData,
  taxonProducts,
  handleSetPrice,
  settaxonProduct,
}) {
  // console.log(taxonProducts);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { id } = useParams();
  const [data, setData] = React.useState({});
  const [colors, setColors] = React.useState([]);
  const [Sizes, setSizes] = React.useState([]);
  const [SellerPayout, setSellerPayout] = React.useState();
  const [removeStocks, setRemoveStocks] = React.useState([]);
  const [Loading1, setLoading1] = useState(false);
  const [Loading2, setLoading2] = useState(false);
  const [Loading3, setLoading3] = useState(false);
  const [offerPriceErrors, setOfferPriceErrors] = useState(null);
  const [stockremoveErrors, setstockremoveErrors] = useState(null);
  const [storeData, setStoreData] = useState({});
  const [GSTType, setGSTPercent] = useState(0);

  // console.log(priceData);

  console.log(data);

  React.useEffect(() => {
    const initialRemoveStocks = Array.from(
      { length: colors?.length * Sizes?.length },
      () => 0
    );
    setRemoveStocks(initialRemoveStocks);
  }, [colors?.length, Sizes?.length]);

  const navigate = useNavigate();

  // console.log(removeStocks);

  const getSingleListingData = async () => {
    setLoading1(true);
    try {
      const res = await getSingleProductById(id);
      if (res.status === 200) {
        setData(res.data);

        setColors(res.data.Colors);
        setSizes(res.data.Sizes);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading1(false);
    }
  };

  const handleGetStoreCalculation = async () => {
    setLoading3(true);
    try {
      const res = await storeCalculation();
      if (res.status === 200) {
        // console.log(res[0]);
        setStoreData(res?.data?.[0]);
        setGSTPercent(parseInt(res?.data?.[0].GSTType, 10));
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading3(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    Promise.all([getSingleListingData(), handleGetStoreCalculation()]);
  }, []);

  const calculateCommission = (color, size) => {
    const key = `${color}-${size}`;
    let value = priceData?.[key]?.offerPrice;

    if (!value) {
      value = data.mrp?.[0]?.[key]?.offerPrice;
    }

    return (value * 18) / 100;
  };

  let commission;
  let GSTFinalValue;

  const calculateSellerPayout = (price, key, value) => {
    // console.log(value);
    return Object.keys(priceData).length > 0
      ? priceData[key]?.offerPrice - value
      : data?.mrp?.[0]?.[key]?.offerPrice - value;
  };

  const getPriceForItemAndRow = (item, row) => {
    const priceEntry =
      Object.keys(priceData).length > 0 &&
      Object.entries(priceData).find(([colorAndSize]) => {
        return colorAndSize === `${item}-${row}`;
      });

    if (priceEntry) {
      const [, price] = priceEntry;
      return price;
    }

    return null; // or handle the case when no price is found for the given item and row
  };

  const updatedPrices = {};
  const sellerPayouts = {};
  const dataSellerPayouts = {};

  let price;

  colors.forEach((color) => {
    Sizes.forEach((size) => {
      price = getPriceForItemAndRow(color, size);
      commission = calculateCommission(color, size);
      // Call getPriceForItemAndRow with color and size
      const key = `${color}-${size}`;
      if (price) {
        const sellerPayout = calculateSellerPayout(price.offerPrice, key);

        // console.log(sellerPayout);

        updatedPrices[key] = {
          mrp: price.mrp,
          offerPrice: price.offerPrice,
        };

        sellerPayouts[key] = sellerPayout;
      }
      const datasellerPayout = calculateSellerPayout(
        data?.mrp?.[0]?.[key].offerPrice,
        key
      );
      dataSellerPayouts[key] = datasellerPayout;
    });
  });

  // Object to store prices for different colors and sizes

  const handleUpdateProductPrice = async () => {
    setLoading2(true);
    try {
      const updatedAddStock = [...data.addstock];

      removeStocks.forEach((value, index) => {
        const currentValue = updatedAddStock[index] || 0;
        const updatedValue = currentValue - parseInt(value, 10);
        updatedAddStock[index] = updatedValue < 0 ? 0 : updatedValue;
      });

      const res = await axios.put(
        `https://rccore-backend.cstechns.com/api/admin/update-single-product/${id}`,
        {
          mrp:
            data.Colors.length > 0
              ? Object.keys(updatedPrices).length > 0
                ? updatedPrices
                : data.mrp
              : updatedPrices,
          IGST: IGST,
          CGST: CGST,
          SGST: SGST,
          taxonProduct: IGST,
          commission: commission,
          addstock: updatedAddStock,
          removeStock: removeStocks.map((value) => parseInt(value, 10)),
          makeDefault: data.makeDefault,
          taxonProduct:
            Object.keys(taxonProducts).length > 0
              ? taxonProducts
              : data.taxonProduct,
          sellerPayout:
            Object.keys(sellerPayouts).length > 0
              ? sellerPayouts
              : dataSellerPayouts,
        }
      );

      if (res.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          text: "Updated successfully",
          showConfirmButton: true,
        });

        if (data?.mrp?.length > 0) {
          navigate(`/my-product-list`);
        } else {
          navigate(`/manage-image/${res.data._id}`, { replace: true });
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: "Error Occured while updating",
          showConfirmButton: true,
        });
      }
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        text: error.response?.data?.error || "An error occurred",
        showConfirmButton: true,
      });
    } finally {
      setLoading2(false);
    }
  };

  // console.log(taxonProducts);

  let DifferentialValueClosingFee;
  let DifferentialValuePercentageClosingFee;
  let FlatValueValueClosingFee = storeData.closingFeeTypeFlatValue
    ? storeData.closingFeeTypeFlatValue
    : 0;
  let shippingCharges = 60;

  let paymentProcessingDifferentialValue;
  let DifferentialValuePercentagepaymentProcessing;
  let FlatValueValuePaymentProcessing =
    storeData.PaymentChargesForOnlineTypeFlat
      ? storeData.PaymentChargesForOnlineTypeFlat
      : 0;

  return (
    <>
      {!Loading1 && !Loading3 ? (
        <>
          <Paper
            sx={{
              width: "96.5%",
              overflow: "hidden",
              ml: 3,
              border: "1px solid #ddd",
            }}
            elevation={0}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          background: "#eee",
                          fontWeight: "bold",
                          fontSize: "12px",
                          borderRight: "1px solid #ddd ",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    {colors?.map((item, colorIndex) =>
                      Sizes?.map((row, sizeIndex) => {
                        const index = colorIndex * Sizes.length + sizeIndex;

                        const price = getPriceForItemAndRow(item, row);

                        const updatePriceForColorAndSize = (updatedPrice) => {
                          const colorAndSize = `${item}-${row}`;
                          setPriceData((prevPriceData) => ({
                            ...prevPriceData,
                            [colorAndSize]: updatedPrice,
                          }));
                        };
                        const updateTaxforcolorandsize = (updatedPrice) => {
                          const colorAndSize = `${item}-${row}`;
                          settaxonProduct((prevPriceData) => ({
                            ...prevPriceData,
                            [colorAndSize]: updatedPrice,
                          }));
                        };

                        const key = `${item}-${row}`;

                        let CommisonValue = calculateCommission(item, row);
                        // console.log(CommisonValue);
                        let shippingCharges = 60;

                        const calculateGSt = () => {
                          return (
                            ((calculateCommission(item, row) +
                              (storeData?.closingFeeType === "Differential" &&
                              storeData?.closingFeeTypeDifferentialType === true
                                ? Number(DifferentialValueClosingFee)
                                : 0) +
                              (storeData?.closingFeeType === "Differential" &&
                              storeData?.closingFeeTypeDifferentialType ===
                                false
                                ? Number(DifferentialValuePercentageClosingFee)
                                : 0) +
                              (storeData?.closingFeeType === "Flat"
                                ? FlatValueValueClosingFee
                                : 0) +
                              shippingCharges +
                              (storeData?.PaymentChargesForOnlineType ===
                                "Differential" &&
                              storeData?.PaymentChargesForOnlineTypeDifferentialType ===
                                true
                                ? Number(paymentProcessingDifferentialValue)
                                : 0) +
                              (storeData?.PaymentChargesForOnlineType ===
                                "Differential" &&
                              storeData?.PaymentChargesForOnlineTypeDifferentialType ===
                                false
                                ? Number(
                                    DifferentialValuePercentagepaymentProcessing
                                  )
                                : 0) +
                              (storeData?.PaymentChargesForOnlineType === "Flat"
                                ? FlatValueValuePaymentProcessing
                                : 0)) *
                              18) /
                            100
                          );
                        };

                        const GSTIncludingAllGSt = () => {
                          return (
                            calculateCommission(item, row) +
                            (storeData?.closingFeeType === "Differential" &&
                            storeData?.closingFeeTypeDifferentialType === true
                              ? Number(DifferentialValueClosingFee)
                              : 0) +
                            (storeData?.closingFeeType === "Differential" &&
                            storeData?.closingFeeTypeDifferentialType === false
                              ? Number(DifferentialValuePercentageClosingFee)
                              : 0) +
                            (storeData?.closingFeeType === "Flat"
                              ? FlatValueValueClosingFee
                              : 0) +
                            shippingCharges +
                            (storeData?.PaymentChargesForOnlineType ===
                              "Differential" &&
                            storeData?.PaymentChargesForOnlineTypeDifferentialType ===
                              true
                              ? Number(paymentProcessingDifferentialValue)
                              : 0) +
                            (storeData?.PaymentChargesForOnlineType ===
                              "Differential" &&
                            storeData?.PaymentChargesForOnlineTypeDifferentialType ===
                              false
                              ? Number(
                                  DifferentialValuePercentagepaymentProcessing
                                )
                              : 0) +
                            (storeData?.PaymentChargesForOnlineType === "Flat"
                              ? FlatValueValuePaymentProcessing
                              : 0) +
                            calculateGSt()
                          );
                        };

                        // console.log(GSTIncludingAllGSt());

                        let GSTFinalValue = GSTIncludingAllGSt();

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row._id}
                          >
                            {/* Existing table cells */}

                            <TableCell
                              sx={{ borderRight: "1px solid #ddd " }}
                              align="center"
                            >
                              <Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "13px" }}>
                                    SKUCODE:
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {data.SKUCode}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "13px" }}>
                                    Name:
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {data.Title}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "13px" }}>
                                    Color:
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "13px" }}>
                                    Size:
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {row}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{ borderRight: "1px solid #ddd " }}
                              align="center"
                            >
                              <TextField
                                defaultValue={0}
                                size="small"
                                value={price?.mrp || data.mrp[0]?.[key]?.mrp}
                                onChange={(e) => {
                                  if (price) {
                                    const updatedPrice = {
                                      ...price,
                                      mrp: e.target.value,
                                    };
                                    updatePriceForColorAndSize(updatedPrice);
                                  } else {
                                    const updatedMrp = data.mrp.map((item) => {
                                      const updatedItem = { ...item };
                                      const key1 = Object.keys(item);
                                      if (key1.includes(key)) {
                                        updatedItem[key] = {
                                          ...updatedItem[key],
                                          mrp: e.target.value,
                                        };
                                      }
                                      return updatedItem;
                                    });

                                    setData({
                                      ...data,
                                      mrp: updatedMrp,
                                    });
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell
                              sx={{ borderRight: "1px solid #ddd " }}
                              align="center"
                            >
                              <TextField
                                size="small"
                                value={
                                  price?.offerPrice ||
                                  data?.mrp?.[0]?.[key]?.offerPrice
                                }
                                onChange={(e) => {
                                  const newOfferPrice =
                                    e.target.value ||
                                    data?.mrp?.[0]?.[key]?.offerprice;
                                  const newMRP =
                                    price?.mrp || data?.mrp?.[0]?.[key]?.mrp;

                                  if (newOfferPrice > newMRP) {
                                    // Show an error message or handle the error condition here
                                    const index = data.mrp.findIndex((item) =>
                                      Object.keys(item).includes(key)
                                    );
                                    setOfferPriceErrors(index);
                                  } else {
                                    setOfferPriceErrors(null);
                                  }

                                  if (Object.keys(priceData).length > 0) {
                                    const updatedPrice = {
                                      ...price,
                                      offerPrice: newOfferPrice,
                                    };

                                    // console.log(updatedPrice);
                                    const GSTPercentage = parseFloat(
                                      storeData?.GSTType.replace("%", "")
                                    );

                                    const FinalGST = 100 + GSTPercentage;

                                    const updatedTax = {
                                      ...taxonProducts.taxonProduct,
                                      taxonProduct:
                                        (updatedPrice.offerPrice *
                                          GSTPercentage) /
                                        FinalGST,
                                    };

                                    updatePriceForColorAndSize(updatedPrice);
                                    updateTaxforcolorandsize(updatedTax);
                                    const updatedSellerPayout =
                                      calculateSellerPayout(newOfferPrice, key);
                                    setData((prevData) => ({
                                      ...prevData,
                                      sellerPayout: {
                                        ...prevData.sellerPayout,
                                        [key]: updatedSellerPayout,
                                      },
                                    }));
                                  } else {
                                    const updatedMrp = data.mrp.map((item) => {
                                      const updatedItem = { ...item };
                                      const key1 = Object.keys(item);
                                      if (key1.includes(key)) {
                                        updatedItem[key] = {
                                          ...updatedItem[key],
                                          offerPrice: newOfferPrice,
                                        };
                                      }

                                      return updatedItem;
                                    });

                                    const updatedtax = data.taxonProduct.map(
                                      (item) => {
                                        const updatedItem = { ...item };
                                        const key1 = Object.keys(item);

                                        const GSTPercentage = parseFloat(
                                          storeData?.GSTType.replace("%", "")
                                        );
                                        const FinalGST = 100 + GSTPercentage;
                                        if (key1.includes(key)) {
                                          updatedItem[key].taxonProduct =
                                            (updatedMrp?.[0]?.[key].offerPrice *
                                              parseFloat(
                                                storeData?.GSTType.replace(
                                                  "%",
                                                  ""
                                                )
                                              )) /
                                            FinalGST;
                                        }
                                        return updatedItem;
                                      }
                                    );

                                    setData((prevData) => ({
                                      ...prevData,
                                      mrp: updatedMrp,
                                      taxonProduct: updatedtax,
                                    }));
                                  }
                                }}
                                error={offerPriceErrors === index}
                              />
                            </TableCell>

                            <TableCell
                              sx={{ borderRight: "1px solid #ddd " }}
                              align="center"
                            >
                              <Typography>
                                {taxonProducts &&
                                Object.keys(taxonProducts).length > 0
                                  ? Math.round(
                                      taxonProducts?.[key]?.taxonProduct * 100
                                    ) / 100
                                  : Math.round(
                                      (data?.taxonProduct?.[0]?.[key]
                                        ?.taxonProduct || 0) * 100
                                    ) / 100}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{ borderRight: "1px solid #ddd " }}
                              align="center"
                            >
                              <Typography>
                                {commission
                                  ? commission
                                  : data?.commission || 0}
                              </Typography>
                            </TableCell>
                            {storeData.ClosingFee === true &&
                              storeData.closingFeeType === "Differential" &&
                              storeData.closingFeeTypeDifferentialType ===
                                true && (
                                <TableCell
                                  sx={{ borderRight: "1px solid #ddd " }}
                                  align="center"
                                >
                                  {storeData?.closingFeeTypeDifferentialFieldValues?.map(
                                    (fee, index) => {
                                      // console.log(data?.mrp?.[0]?.[key]?.mrp);
                                      if (
                                        Object.keys(priceData).length > 0
                                          ? Number(
                                              priceData?.[key].offerPrice
                                            ) >= Number(fee.from) &&
                                            Number(
                                              priceData?.[key].offerPrice
                                            ) <= Number(fee.to)
                                          : Number(
                                              data.mrp?.[0]?.[key]?.offerPrice
                                            ) >= Number(fee.from) &&
                                            Number(
                                              data.mrp?.[0]?.[key]?.offerPrice
                                            ) <= Number(fee.to)
                                      ) {
                                        console.log(fee.from, fee.to);
                                        console.log(fee.value);
                                        DifferentialValueClosingFee = fee.value;

                                        return (
                                          <Typography key={index}>
                                            {DifferentialValueClosingFee}
                                          </Typography>
                                        );
                                      }
                                    }
                                  )}
                                </TableCell>
                              )}
                            {storeData.ClosingFee === true &&
                              storeData.closingFeeType === "Differential" &&
                              storeData.closingFeeTypeDifferentialType ===
                                false && (
                                <TableCell
                                  sx={{ borderRight: "1px solid #ddd " }}
                                  align="center"
                                >
                                  {storeData?.closingFeeTypeDifferentialFieldValues?.map(
                                    (fee, index) => {
                                      if (
                                        Object.keys(priceData).length > 0
                                          ? Number(priceData[key].offerPrice) >=
                                              Number(fee.from) &&
                                            Number(priceData[key].offerPrice) <=
                                              Number(fee.to)
                                          : Number(
                                              data.mrp?.[0]?.[key]?.offerPrice
                                            ) >= Number(fee.from) &&
                                            Number(
                                              data.mrp?.[0]?.[key]?.offerPrice
                                            ) <= Number(fee.to)
                                      ) {
                                        DifferentialValuePercentageClosingFee =
                                          Number(
                                            fee.value *
                                              (Object.keys(priceData).length > 0
                                                ? Number(
                                                    priceData[key].offerPrice
                                                  ) / 100
                                                : Number(
                                                    data.mrp?.[0]?.[key]
                                                      ?.offerPrice
                                                  ) / 100)
                                          );
                                        // console.log(value);

                                        return (
                                          <Typography key={index}>
                                            {
                                              DifferentialValuePercentageClosingFee
                                            }
                                          </Typography>
                                        );
                                      }
                                    }
                                  )}
                                </TableCell>
                              )}
                            {storeData.ClosingFee === true &&
                              storeData.closingFeeType === "Flat" && (
                                <TableCell
                                  sx={{ borderRight: "1px solid #ddd " }}
                                  align="center"
                                >
                                  <Typography>
                                    {FlatValueValueClosingFee}
                                  </Typography>
                                </TableCell>
                              )}

                            <TableCell
                              sx={{ borderRight: "1px solid #ddd " }}
                              align="center"
                            >
                              <Typography>{shippingCharges}</Typography>
                            </TableCell>
                            {storeData?.PaymentChargesForOnline === true &&
                              storeData?.PaymentChargesForOnlineType ===
                                "Differential" &&
                              storeData.PaymentChargesForOnlineTypeDifferentialType ===
                                true && (
                                <TableCell
                                  sx={{ borderRight: "1px solid #ddd " }}
                                  align="center"
                                >
                                  {storeData?.PaymentChargesForOnlineTypeDifferentialFieldValues?.map(
                                    (fee, index) => {
                                      // console.log(data?.mrp?.[0]?.[key]?.mrp);
                                      if (
                                        Object.keys(priceData).length > 0
                                          ? Number(priceData[key].offerPrice) >=
                                              Number(fee.from) &&
                                            Number(priceData[key].offerPrice) <=
                                              Number(fee.to)
                                          : Number(
                                              data.mrp?.[0]?.[key]?.offerPrice
                                            ) >= Number(fee.from) &&
                                            Number(
                                              data.mrp?.[0]?.[key]?.offerPrice
                                            ) <= Number(fee.to)
                                      ) {
                                        // console.log(fee.value);
                                        paymentProcessingDifferentialValue =
                                          fee.value;

                                        return (
                                          <Typography key={index}>
                                            {paymentProcessingDifferentialValue}
                                          </Typography>
                                        );
                                      }
                                    }
                                  )}
                                </TableCell>
                              )}
                            {storeData.PaymentChargesForOnline === true &&
                              storeData.PaymentChargesForOnlineType ===
                                "Differential" &&
                              storeData.PaymentChargesForOnlineTypeDifferentialType ===
                                false && (
                                <TableCell
                                  sx={{ borderRight: "1px solid #ddd " }}
                                  align="center"
                                >
                                  {storeData?.PaymentChargesForOnlineTypeDifferentialFieldValues?.map(
                                    (fee, index) => {
                                      if (
                                        Object.keys(priceData).length > 0
                                          ? Number(priceData[key].offerPrice) >=
                                              Number(fee.from) &&
                                            Number(priceData[key].offerPrice) <=
                                              Number(fee.to)
                                          : Number(
                                              data.mrp?.[0]?.[key]?.offerPrice
                                            ) >= Number(fee.from) &&
                                            Number(
                                              data.mrp?.[0]?.[key]?.offerPrice
                                            ) <= Number(fee.to)
                                      ) {
                                        DifferentialValuePercentagepaymentProcessing =
                                          fee.value *
                                          (Object.keys(priceData).length > 0
                                            ? priceData[key].offerPrice / 100
                                            : Number(
                                                data.mrp?.[0]?.[key]?.offerPrice
                                              ) / 100);
                                        // console.log(value);

                                        return (
                                          <Typography key={index}>
                                            {
                                              DifferentialValuePercentagepaymentProcessing
                                            }
                                          </Typography>
                                        );
                                      }
                                    }
                                  )}
                                </TableCell>
                              )}
                            {storeData?.PaymentChargesForOnline === true &&
                              storeData?.PaymentChargesForOnlineType ===
                                "Flat" && (
                                <TableCell
                                  sx={{ borderRight: "1px solid #ddd " }}
                                  align="center"
                                >
                                  <Typography>
                                    {FlatValueValuePaymentProcessing}
                                  </Typography>
                                </TableCell>
                              )}
                            <TableCell
                              sx={{ borderRight: "1px solid #ddd " }}
                              align="center"
                            >
                              <Typography>
                                {calculateGSt().toFixed(2)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{ borderRight: "1px solid #ddd " }}
                              align="center"
                            >
                              <Typography>
                                {calculateSellerPayout(
                                  price,
                                  key,
                                  GSTIncludingAllGSt()
                                ).toFixed(2)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{ borderRight: "1px solid #ddd " }}
                              align="center"
                            >
                              <Typography>
                                {data?.addstock?.[index] ?? ""}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{ borderRight: "1px solid #ddd " }}
                              align="center"
                            >
                              <TextField
                                size="small"
                                defaultValue={0}
                                onChange={(e) => {
                                  setData((prevData) => {
                                    const addstock = prevData?.addstock ?? [];
                                    const newAddstock = [...addstock];

                                    // Calculate the index based on colorIndex and sizeIndex
                                    const index =
                                      colorIndex * Sizes.length + sizeIndex;

                                    // Update the addstock value at the specific index
                                    if (index < addstock.length) {
                                      // If the entry already exists, update the value as a number
                                      newAddstock[index] = Number(
                                        e.target.value
                                      );
                                    } else {
                                      // If the entry doesn't exist, create a new entry as a number
                                      newAddstock.push(Number(e.target.value));
                                    }

                                    return {
                                      ...prevData,
                                      addstock: newAddstock,
                                    };
                                  });
                                }}
                              />
                            </TableCell>
                            <TableCell
                              sx={{ borderRight: "1px solid #ddd " }}
                              align="center"
                            >
                              <TextField
                                size="small"
                                value={removeStocks?.[index]}
                                error={stockremoveErrors === index}
                                onChange={(e) => {
                                  if (e.target.value > data.addstock[index]) {
                                    setstockremoveErrors(index);
                                    const updatedRemoveStocks = [
                                      ...removeStocks,
                                    ];
                                    updatedRemoveStocks[index] =
                                      e.target.value !== ""
                                        ? parseInt(e.target.value, 10)
                                        : 0;
                                    setRemoveStocks(updatedRemoveStocks);
                                  } else {
                                    setstockremoveErrors(null);
                                    const updatedRemoveStocks = [
                                      ...removeStocks,
                                    ];
                                    updatedRemoveStocks[index] =
                                      e.target.value !== ""
                                        ? parseInt(e.target.value, 10)
                                        : 0;
                                    setRemoveStocks(updatedRemoveStocks);
                                  }
                                }}
                              />
                            </TableCell>

                            <TableCell
                              sx={{ borderRight: "1px solid #ddd " }}
                              align="center"
                            >
                              <Checkbox
                                key={index}
                                checked={data.makeDefault.includes(index)}
                                onChange={() => {
                                  if (data.makeDefault.includes(index)) {
                                    setData((prevData) => ({
                                      ...prevData,
                                      makeDefault: prevData.makeDefault.filter(
                                        (item) => item !== index
                                      ),
                                    }));
                                  } else {
                                    setData((prevData) => ({
                                      ...prevData,
                                      makeDefault: [
                                        ...prevData.makeDefault,
                                        index,
                                      ],
                                    }));
                                  }
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </>
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={colors?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <Box sx={{ ml: 4, mt: 2, mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mr: 3,
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>NOTE : </Typography>
              <Button
                variant="contained"
                onClick={handleUpdateProductPrice}
                sx={{ background: "#0099cc", width: "200px" }}
                disableElevation
                disabled={Loading2}
              >
                {Loading2 ? <CircularProgress size={24} /> : "SUBMIT"}
              </Button>
            </Box>
            <Typography sx={{ fontSize: "15px", mt: 2 }}>
              * The above shipping charges are approximate and will be modified
              as per the customer location and package weight or volumetric
              weight whichever is higher at the time of order processing.{" "}
            </Typography>
            <Typography sx={{ fontSize: "15px", mt: 1 }}>
              * Seller payout may change as per actual.{" "}
            </Typography>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
