import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApprovePriceTable from "./ApprovePriceTable";
import Swal from "sweetalert2";
import { getSingleProductById } from "../../axios";

const ManagePriceAndStock = () => {
  const [mrp, setMrp] = React.useState(0);
  const [OfferPrice, setOfferPrice] = React.useState(0);
  const [priceData, setPriceData] = React.useState({});
  const [IgstValue, setIgStValue] = React.useState(18);
  const [CgstValue, setCgStValue] = React.useState(9);
  const [SgstValue, setSgStValue] = React.useState(9);
  const [IGST, setIGST] = React.useState(0);
  const [CGST, setCGST] = React.useState(0);
  const [SGST, setSGST] = React.useState(0);
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [taxonProducts, settaxonProduct] = useState({});
  const [mrpError, setMrpError] = useState("");
  const [offerPriceError, setOfferPriceError] = useState("");

  // let IGST;
  // let CGST;
  // let SGST;

  const handleSetPrice = () => {
    if (data) {
      if (parseInt(mrp) <= parseInt(OfferPrice)) {
        Swal.fire({
          position: "center",
          icon: "error",
          text: "Offer Price should be less than mrp",
          showConfirmButton: true,
        });
        return;
      }
      const newPriceData = {};
      const taxonProduct = {};
      const defaultColor = "Default";
      const defaultSize = "Default";

      if (!data.Colors || Object.keys(data.Colors).length === 0) {
        newPriceData[`${defaultColor}-${defaultSize}`] = {
          mrp: data.mrp,
          offerPrice: data.offerPrice,
        };

        taxonProduct[`${defaultColor}-${defaultSize}`] = {
          taxonProduct: (
            (data.offerPrice * IgstValue) /
            (100 + IgstValue)
          ).toFixed(2),
        };
      } else {
        Object.keys(data.Colors).forEach((color) => {
          if (
            !data ||
            !data.Sizes ||
            Object.keys(data.data.Sizes).length === 0
          ) {
            const colorAndSize = `${data.Colors[color]}-${defaultSize}`;
            newPriceData[colorAndSize] = {
              mrp: data.mrp,
              offerPrice: data.offerPrice,
            };
            taxonProduct[colorAndSize] = {
              taxonProduct: (
                (data.offerPrice * IgstValue) /
                (100 + IgstValue)
              ).toFixed(2),
            };
          } else {
            Object.keys(data.data.Sizes).forEach((size) => {
              const colorAndSize = `${data.Colors[color]}-${data.data.Sizes[size]}`;
              newPriceData[colorAndSize] = {
                mrp: data.mrp,
                offerPrice: data.offerPrice,
              };

              taxonProduct[colorAndSize] = {
                taxonProduct: (
                  (data.offerPrice * IgstValue) /
                  (100 + IgstValue)
                ).toFixed(2),
              };
            });
          }
        });
      }

      setIGST(((OfferPrice * IgstValue) / (100 + IgstValue)).toFixed(2));
      setCGST(((OfferPrice * IgstValue) / (100 + IgstValue) / 2).toFixed(2));
      setSGST(((OfferPrice * IgstValue) / (100 + IgstValue) / 2).toFixed(2));
      settaxonProduct(taxonProduct);
      setPriceData(newPriceData);
    }
  };

  // console.log(taxonProducts);

  // const handleSetPrice = () => {
  //   if (data) {
  //     const newPriceData = {};
  //     let index = 0;
  //     Object.keys(data.Colors).forEach((color) => {
  //       Object.keys(data?.data?.Sizes).forEach((size) => {
  //         const colorAndSize = `${data.Colors[color]}-${data?.data?.Sizes[size]}`;
  //         newPriceData[index] = {
  //           [colorAndSize]: {
  //             mrp: data.mrp,
  //             offerPrice: data.offerPrice,
  //           },
  //         };
  //         index++;
  //       });
  //     });

  //     console.log(newPriceData);
  //     setPriceData(newPriceData);
  //   }
  // };

  const getSingleListingData = async () => {
    setLoading(true);
    try {
      const res = await getSingleProductById(id);
      if (res.status === 200) {
        // console.log(res.data);
        setData(res.data);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          text: res.data.error,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleListingData();
  }, []);

  const handleMrp = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    setMrpError(""); // Clear any previous error message

    setData({ ...data, mrp: numericValue });
    setMrp(numericValue);
  };

  const handleOfferPrice = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");

    setData({ ...data, offerPrice: numericValue });
    setOfferPrice(numericValue);
  };

  return (
    <div style={{ marginTop: "80px" }}>
      {!loading ? (
        <Box>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              textAlign: "center",
              mt: 3,
            }}
          >
            Manage Product Price
          </Typography>
          <Typography
            sx={{ border: "2px solid #0099cc", width: "60px" }}
            style={{ margin: "auto" }}
          ></Typography>

          <Box sx={{ m: 5 }}>
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                  color: "gray",
                }}
              >
                Name :{" "}
              </Typography>
              <Typography>{data.Title}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                  color: "gray",
                }}
              >
                SKU Code :{" "}
              </Typography>
              <Typography>{data.SKUCode}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                  color: "gray",
                }}
              >
                Image :{" "}
              </Typography>
              <Box sx={{ width: "120px" }}>
                <img
                  src={data.MainImage}
                  alt="Image1"
                  width="100%"
                  height="100%"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                  color: "gray",
                }}
              >
                Set/Reset All Price :{" "}
              </Typography>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <TextField
                  placeholder="Enter MRP"
                  size="small"
                  defaultValue={0}
                  onChange={handleMrp}
                  sx={{ width: "140px" }}
                />
                <TextField
                  placeholder="Offer Price"
                  size="small"
                  defaultValue={0}
                  onChange={handleOfferPrice}
                  sx={{ width: "140px" }}
                />
                <Button
                  sx={{ background: "#0099cc" }}
                  variant="contained"
                  onClick={handleSetPrice}
                  disableElevation
                  disabled={mrp <= 0 || OfferPrice <= 0}
                >
                  Confirm
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                  color: "gray",
                }}
              >
                IGST :{" "}
              </Typography>
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <TextField
                  size="small"
                  value={IgstValue}
                  onChange={(e) => setIgStValue(e.target.value)}
                  sx={{ width: "100px" }}
                />
                <Typography> % - </Typography>
                <TextField
                  size="small"
                  sx={{ width: "100px" }}
                  value={IGST ? IGST : data.IGST}
                />
                <Typography>Amount</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                  color: "gray",
                }}
              >
                CGST :{" "}
              </Typography>
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <TextField
                  size="small"
                  value={CgstValue}
                  onChange={(e) => setCgStValue(e.target.value)}
                  sx={{ width: "100px" }}
                />
                <Typography> % - </Typography>
                <TextField
                  size="small"
                  value={CGST ? CGST : data.CGST}
                  sx={{ width: "100px" }}
                />
                <Typography>Amount</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "300px",
                  color: "gray",
                }}
              >
                SGST :{" "}
              </Typography>
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <TextField
                  size="small"
                  value={SgstValue}
                  onChange={(e) => setSgStValue(e.target.value)}
                  sx={{ width: "100px" }}
                />
                <Typography> % - </Typography>
                <TextField
                  size="small"
                  value={SGST ? SGST : data.SGST}
                  sx={{ width: "100px" }}
                />
                <Typography>Amount</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ m: 3, background: "#eee", p: 1.5, mb: 2 }}>
            <Typography sx={{ fontSize: "17px", fontWeight: "bold", ml: 1 }}>
              Approve Price
            </Typography>
          </Box>
          <ApprovePriceTable
            priceData={priceData}
            taxonProducts={taxonProducts}
            IGST={IGST}
            CGST={CGST}
            SGST={SGST}
            IgstValue={IgstValue}
            setPriceData={setPriceData}
            settaxonProduct={settaxonProduct}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default ManagePriceAndStock;
