import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import SellerQueryTable from "./SellerQueryTable";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getQueriesforSellerBYId } from "../../axios";
import { useEffect } from "react";

const SellerQuery = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [searchQuery, setSearchQuery] = useState("");
  const [Loading, setLoading] = useState(false);
  const handleGetAllSellerQueries = async () => {
    setLoading(true);
    try {
      const res = await getQueriesforSellerBYId(user._id);
      if (res) {
        setData(res);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetAllSellerQueries();
  }, []);

  const handleSearch = (e) => {
    const filtered = data.filter((item) => {
      if (item.orderNo.includes(searchQuery)) {
        return item;
      }
    });
    setData(filtered);
  };

  const handleClear = () => {
    setSearchQuery("");

    handleGetAllSellerQueries();
  };
  return (
    <div style={{ marginTop: "80px" }}>
      <Box>
        <Typography
          sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
        >
          Query List
        </Typography>
        <Typography
          sx={{
            border: "2px solid #0099cc",
            width: "60px",
            margin: "auto",
          }}
        ></Typography>
      </Box>
      <>
        {!Loading ? (
          <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Typography>Search by orderNo:</Typography>
                  <TextField
                    placeholder="Search by orderNo"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                </Box>
                <Typography sx={{ textAlign: "end" }}>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => navigate("/RaiseQuery")}
                  >
                    Raise Query
                  </Button>
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <SellerQueryTable data={data} />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </>
    </div>
  );
};

export default SellerQuery;
